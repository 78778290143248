import { isEmail, isEmpty, isNumber } from './validators';

/**
 * Composes validators array (returns first failed validator error)
 */
export const validators = (...validateFns) => (...args) =>
    validateFns.reduce((error, validator) => error || validator(...args), undefined);

/**
 * Composes validators array (returns all validation errors from all validator fns)
 */
export const validatorsAll = (...validateFns) => (...args) =>
    validateFns.reduce((errors, validator) => ({ ...errors, ...validator(...args) }), undefined);

export const maybeValidate = (validateFn, predicate) => (...args) => {
    let validate = predicate(...args);
    return validate ? validateFn(...args) : undefined;
};

export const required = (value) => (!isEmpty(value) ? undefined : 'Privaloma');

export const number = (value) =>
    isEmpty(value) || isNumber(value) ? undefined : 'Įveskite skaičių';

export const naturalNumber = (value) =>
    isEmpty(value) || /^\d+$/.test(value) ? undefined : 'Įveskite natūrinį skaičių';

export const minValue = (min) => (value) =>
    isEmpty(value) || !isNumber(value) || value >= min
        ? undefined
        : `Reikšmė turi būti didesnė arba lygi "${min}"`;

export const maxValue = (max) => (value) =>
    isEmpty(value) || !isNumber(value) || value <= max
        ? undefined
        : `Reikšmė turi būti mažesnė ar lygi "${max}"`;

export const minLength = (min) => (value) =>
    isEmpty(value) || typeof value !== 'string' || value.length >= min
        ? undefined
        : `Mažiausiai simbolių: ${min}`;

export const maxLength = (max) => (value) =>
    isEmpty(value) || typeof value !== 'string' || value.length <= max
        ? undefined
        : `Daugiausiai simbolių: ${max}`;

export const email = (value) =>
    isEmpty(value) || isEmail(value) ? undefined : 'Neteisingas el. pašto adreso formatas';

export const emails = (value) => {
    const emailsArray = value.split(/\s*;\s*/);
    let err = 0;
    for (let i = 0; i < emailsArray.length; i++) {
        if (!(isEmpty(emailsArray[i]) || isEmail(emailsArray[i]))) {
            err++;
        }
    }
    return err === 0 ? undefined : 'Neteisingas el. pašto adreso formatas';
};
export const range = (boundaries, inclusive = true) => (value) => {
    if (isEmpty(value) || !isNumber(value)) {
        return undefined;
    }

    const start = boundaries[0];
    const end = boundaries[1];
    if (
        !Array.isArray(boundaries) ||
        boundaries.length !== 2 ||
        isNaN(start) ||
        isNaN(end) ||
        start >= end
    ) {
        throw new Error('Invalid range params');
    }

    const msg = `Galiojančios reikšmės: [${start} - ${end}] ${
        inclusive ? '(imtinai)' : '(neimtinai)'
    }`;
    if (inclusive) {
        return value >= start && value <= end ? undefined : msg;
    } else {
        return value > start && value < end ? undefined : msg;
    }
};

export const matchesField = (otherFieldName, msg) => (value, allValues) =>
    value === allValues[otherFieldName]
        ? undefined
        : msg || `Reikšmė nesutampa su lauko '${otherFieldName}' reikšme`;

export const mandatoryArray = (value) =>
    Array.isArray(value) && value.length > 0 ? undefined : 'Privaloma';
