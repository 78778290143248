import React from 'react';
import Layout from '../layout';
import ClientsApiKeysTable from './components/ClientsApiKeysTable';

function ClientsApiKeys() {
    return (
        <Layout>
            <ClientsApiKeysTable />
        </Layout>
    );
}

export default ClientsApiKeys;
