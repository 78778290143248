import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import Radio from './Radio';

function RadioGroup(props) {
    const { name, items, label, row, radioProps, ...formControlProps } = props;
    const [error, setError] = React.useState(null);

    return (
        <FormControl error={!!error} {...formControlProps}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormGroup row={row}>
                {items.map(item => (
                    <Radio
                        key={item.value}
                        name={name}
                        setError={setError}
                        {...radioProps}
                        {...item}
                    />
                ))}
            </FormGroup>
            {error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
    );
}

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ).isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    row: PropTypes.bool,
    checkboxProps: PropTypes.object,
};

export default RadioGroup;
