import axios from 'axios';
import actionLogout from 'utils/actionLogout';
import { isJustRefreshed, isTokenExpired, onSuccessRequest, refreshToken } from 'utils/authToken';

const API_URI = process.env.REACT_APP_API;
const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT * 1000; // convert to milliseconds

axios.defaults.withCredentials = true;
const client = axios.create({
    baseURL: API_URI,
    timeout: API_TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

let initialPageLoad = true;

client.interceptors.request.use(async function (request) {
    if (request.url.split('?')?.[0]?.endsWith?.('/account/user/validate-reset-token')) {
        return request;
    }

    if (request.url.split('?')?.[0]?.endsWith?.('/account/user/reset-password')) {
        return request;
    }

    if (request.url.split('?')?.[0]?.endsWith?.('/account/user/forgot-password')) {
        return request;
    }

    if (request?.url?.endsWith?.("/auth/login")) {
        return request;
    }

    if (request?.url?.endsWith?.("/auth/refresh")) {
        return request;
    }

    if (request?.url?.endsWith?.("/auth/logout")) {
        return request;
    }

    const needTokenRefresh = isTokenExpired() || initialPageLoad;
    if (needTokenRefresh) {
        try {
            await refreshToken();
            initialPageLoad = false;
            return request;
        } catch (err) {
            console.error(err);
            const error = new Error("Failed to refresh access token before request");
            (error).isAxiosError = true;
            (error).response = {
                status: 401,
            };
            throw error;
        }
    }

    return request;
}, Promise.reject);

client.interceptors.response.use(
    function (response) {
        onSuccessRequest();
        return response;
    },
    async function (err) {
        if (err.isAxiosError && err.response && err.response.status === 401) {
            if (err.request?.responseURL?.endsWith?.("/auth/login")) {
                return Promise.reject(err);
            }

            if (err.request?.responseURL?.endsWith?.("/auth/refresh")) {
                return Promise.reject(err);
            }

            if (err.request?.responseURL?.endsWith?.("/auth/logout")) {
                return Promise.reject(err);
            }

            try {
                if (isJustRefreshed()) {
                    return Promise.reject(err);
                }

                const result = await refreshToken();
                if (result) {
                    return client.request(err.config);
                }
                // eslint-disable-next-line no-empty
            } catch (err) { }

            actionLogout();
            return Promise.reject(err);
        } else {
            return Promise.reject(err);
        }
    }
);

export { client };
