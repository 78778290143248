import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';

const ClientDbSaveConfirm = ({ resolve }) => {

    const handleConfirm = React.useCallback(() => {
        resolve(true);
    }, [resolve]);

    const handleCancel = React.useCallback(() => {
        resolve(false);
    }, [resolve]);

    return <Dialog open fullWidth maxWidth={"xs"} >
        <DialogContent>
            Dėmesio! Keičiate sisteminį duomenų bazės parametrą. Išsaugojus pakeitimus šiai duomenų bazei bus automatiškai atnaujintos visos procedūros
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Atšaukti</Button>
            <Button
                onClick={handleConfirm}
                variant="contained"
                disableElevation
            >Išsaugoti</Button>
        </DialogActions>
    </Dialog>
}

export default ClientDbSaveConfirm;