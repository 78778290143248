import React from 'react';
import { Avatar, Menu, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../auth/reducer';
import { getFullName } from '../../auth/selectors';
import Link from 'components/Link';
import { ROUTE_PROFILE } from '../../../app/routes';

function ProfileMenu() {
    // grab the first full name letters
    const fullName = useSelector(getFullName);
    const avatarContent = fullName
        ? fullName
              .split(' ')
              .map(name => name.charAt(0).toUpperCase())
              .join('')
        : '?';

    // Menu by default is triggered by anchor element
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = e => setAnchorEl(e.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    // Logout
    const dispatch = useDispatch();
    const logUserOut = React.useCallback(() => dispatch(logOut()), [dispatch]);

    return (
        <div>
            <Avatar onClick={openMenu}>{avatarContent}</Avatar>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem component={Link} to={ROUTE_PROFILE.path}>
                    Profilis
                </MenuItem>
                <MenuItem onClick={logUserOut}>Atsijungti</MenuItem>
            </Menu>
        </div>
    );
}

export default ProfileMenu;
