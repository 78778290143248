import React, { useState } from 'react';
import { getApiKeys } from '../../api/apikeys';
import DataTable, {
    CellBoolean,
    columnTypes,
    CellWithTooltip,
    ActionIcon,
    CellDate,
} from '../../../components/DataTable';
import ClientsApiKeyForm from './ClientsApiKeyForm';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../auth/selectors';

const columnsReg = [
    {
        ...columnTypes.role,
        accessor: 'clientName',
        Header: 'Klientas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbName',
        Header: 'DB vardas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbDescription',
        Header: 'Web adresas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'dealerName',
        Header: 'Atstovas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbType',
        Header: 'SQL tipas',
        width: '7%',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'prefix',
        Header: `API prefix'as`,
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        ...columnTypes.bool,
        accessor: 'isActive',
        Header: 'Aktyvus',
        width: '5%',
        Cell: (props) => (
            <CellBoolean
                value={!!props.cell.value}
                titleTrue="Aktyvus API raktas"
                titleFalse="Neaktyvus API raktas"
            />
        ),
    },
    {
        Cell: (props) => <CellDate value={props.cell.value} withoutTime />,
        disableFilters: true,
        accessor: 'validToDate',
        Header: 'Galioja iki',
    },
    {
        accessor: 'description',
        Header: `Paskirtis`,
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
];

const columnsAdmin = [
    {
        accessor: 'operatorName',
        Header: 'Operatorius',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        Cell: (props) => <CellDate value={props.cell.value} withoutTime />,
        disableFilters: true,
        accessor: 'updatedAt',
        Header: 'Atnaujinta',
    },
];

const columnsAction = [
    {
        ...columnTypes.actions,
        Cell: (props) => {
            const title = `Redaguoti`;
            return <ActionIcon title={title} onClick={(e) => props.openEditView(e, props.row)} />;
        },
    },
];

function ClientApiKeysTable() {
    const isUserAdmin = useSelector(isAdmin);
    const [columns, setColumns] = useState([...columnsReg, ...columnsAction]);

    React.useEffect(() => {
        if (isUserAdmin) {
            setColumns([...columnsReg, ...columnsAdmin, ...columnsAction]);
        }
    }, [isUserAdmin]);
    return (
        <DataTable
            title="Api raktai"
            columns={columns}
            loadData={getApiKeys}
            editView={ClientsApiKeyForm}
        />
    );
}

export default ClientApiKeysTable;
