import React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table } from '@material-ui/core';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import styles from './dataTable.module.css';

const tableProps = { size: 'small', className: styles.table };

function DataTable(props) {
    const { isLoading, pageSize, columns, getTableProps, headerGroups, rows, prepareRow } = props;

    return (
        <TableContainer>
            <Table {...getTableProps(tableProps)}>
                <TableHead headerGroups={headerGroups} />
                <TableBody
                    columns={columns}
                    rows={rows}
                    prepareRow={prepareRow}
                    isLoading={isLoading}
                    pageSize={pageSize}
                />
            </Table>
        </TableContainer>
    );
}

DataTable.propTypes = {
    getTableProps: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    headerGroups: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    prepareRow: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
};

//DataTable.whyDidYouRender = true;

export default React.memo(DataTable);
