import React from 'react';
import PropTypes from 'prop-types';
import { useDebounce, useDidUpdateEffect, useState } from 'hooks';
import { TextField, makeStyles } from '@material-ui/core';
import { OP_LIKE } from '../../../modules/api/utils/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 3,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 14,
        width: 'auto',
        padding: '4px 8px',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

function DefaultFilter(props) {
    const { operation, column, type } = props;
    const { filterValue, setFilter } = column;
    const [inputValue, setInputValue] = useState(filterValue);
    const debouncedInputValue = useDebounce(inputValue, 500);
    useDidUpdateEffect(() => {
        if (debouncedInputValue === undefined) {
            setFilter(undefined);
        } else {
            setFilter({
                value: debouncedInputValue,
                operation,
            });
        }
    }, [debouncedInputValue]);

    const handleChange = React.useCallback((e) => {
        setInputValue(e.target.value || undefined); // Set undefined to remove the filter entirely
    }, []);

    const inputClasses = useStyles();

    return (
        <TextField
            size="small"
            type={type}
            value={inputValue || ''}
            onChange={handleChange}
            InputProps={{
                disableUnderline: true,
                classes: inputClasses,
            }}
            fullWidth
        />
    );
}

DefaultFilter.defaultProps = {
    operation: OP_LIKE,
    type: 'search',
};

DefaultFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.any,
                operation: PropTypes.string,
            }),
        ]),
        setFilter: PropTypes.func.isRequired,
    }).isRequired,
    operation: PropTypes.string.isRequired,
};

export default DefaultFilter;
