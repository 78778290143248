import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxList, AlertDialog } from '../../../components/form';
import {
    Typography,
    makeStyles,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormState } from 'react-final-form';
import { useState, usePreviousState } from '../../../hooks';
import clsx from 'clsx';
import { getClient, getOperators } from '../../api/clients';
import useQuery from '../../../hooks/useQuery';
import { useNotifications } from '../../notifications';
import { matchSorter } from 'match-sorter';
import { Loading } from 'components/form';

const useStyles = makeStyles((theme) => ({
    textInMethods: {
        color: 'rgba(0,0,0,0.54)',
    },
    textOperators: {
        color: 'rgba(0,0,0,0.54)',
        fontSize: '0.7rem',
    },
    operatorBlock: {
        margin: `${theme.spacing(2)}px 0`,
        border: '1px solid rgba(0,0,0,0.54)',
        padding: '8px 0!important',
        width: '100%',
    },
    notActivated: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'red',
        },
    },
    activated: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: '#00b373',
        },
    },
}));

function MethodsList(props) {
    const { open, onClose, onConfirm, options, fieldIndex } = props;
    const { values } = useFormState();
    const classes = useStyles();
    const notifications = useNotifications();
    const [isOperator, setIsOperator] = useState(!!values.apiKeysList[fieldIndex].operatorId);
    const [operatorId, setOperatorId] = useState(values.apiKeysList[fieldIndex].operatorId);
    const [checkedItems, setCheckedItems] = useState([]);
    const prevOperatorId = usePreviousState(operatorId);
    const [operatorEmail, setOperatorEmail] = useState('');
    const [allowedMethods, setAllowedMethods] = useState(
        values.apiKeysList[fieldIndex].allowedMethods,
    );
    const isActive = !!values.apiKeysList[fieldIndex].isActive;
    const isNew = !!values.apiKeysList[fieldIndex].generated;
    const isRegenerated = values.apiKeysList[fieldIndex].changeApiKey;

    const confirmParams = React.useMemo(
        () => ({ operatorId: operatorId, checkedItems: checkedItems }),
        [operatorId, checkedItems],
    );

    const handleOperatorChange = (event) => {
        if (!event.target.checked) setOperatorId('');
        setIsOperator(event.target.checked);
    };

    const handleOperatorIdChange = (event, value) =>
        value ? setOperatorId(value.value) : setOperatorId('');

    const handleCheckBoxList = (items) => setCheckedItems(items);

    const { data: operators, isLoading, error } = useQuery(getOperators);

    const operatorOptions = React.useMemo(() => {
        if (isLoading || error || !operators || !operators.data) {
            return [];
        }

        return operators.data.map((operator) => ({
            label: operator.name,
            value: operator.id,
        }));
    }, [operators, isLoading, error]);

    React.useEffect(() => {
        if (operatorId && operators && operators.data && operators.data.length > 0) {
            const operator = operators.data.filter((item) => item.id === operatorId);
            const email = operator.length > 0 ? operator[0].email : '';
            if (email) setOperatorEmail(email);
        }
    }, [operators, operatorId]);

    React.useEffect(() => {
        if (
            operatorId === null ||
            operatorId === '' ||
            typeof operatorId === 'undefined' ||
            typeof prevOperatorId === 'undefined'
        )
            return;

        if (prevOperatorId !== operatorId) {
            getClient(operatorId)
                .then((data) => {
                    setAllowedMethods(data && data.defaultMethods ? data.defaultMethods : []);
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                });
        }
    }, [operatorId, prevOperatorId, notifications]);

    if (error) {
        notifications.showError(error.message);
    }

    const autocompleteValue = React.useMemo(() => {
        if (!operatorId) {
            return null;
        }

        return operatorOptions.find((i) => i.value === operatorId) || null;
    }, [operatorOptions, operatorId]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <AlertDialog
            title="Metodų sąrašas"
            open={open}
            onClose={onClose}
            onConfirmation={() => onConfirm(confirmParams)}
        >
            <Grid container spacing={2} direction="column" wrap="nowrap">
                <Grid
                    item
                    container
                    spacing={2}
                    direction="column"
                    wrap="nowrap"
                    className={classes.operatorBlock}
                >
                    <Grid
                        item
                        container
                        spacing={1}
                        direction="row"
                        wrap="nowrap"
                        alignItems="flex-end"
                    >
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                label="Leisti API raktą valdyti operatoriui"
                                control={
                                    <Checkbox
                                        size="medium"
                                        checked={isOperator}
                                        onChange={handleOperatorChange}
                                        disabled={!isNew || isRegenerated}
                                    />
                                }
                            />
                        </Grid>

                        {isOperator && (
                            <Grid item xs={12} sm={5}>
                                <Autocomplete
                                    autoSelect
                                    options={operatorOptions}
                                    filterOptions={(options, { inputValue }) => {
                                        return matchSorter(options, inputValue, {
                                            keys: ['label'],
                                            keepDiacritics: true,
                                        });
                                    }}
                                    getOptionLabel={(item) => {
                                        return (item && item.label) || '';
                                    }}
                                    value={autocompleteValue}
                                    onChange={handleOperatorIdChange}
                                    required
                                    disabled={!isNew || isRegenerated}
                                    renderInput={(params) => {
                                        return (
                                            <TextField {...params} label="Operatorius" fullWidth />
                                        );
                                    }}
                                />
                            </Grid>
                        )}

                        {isOperator && (
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Statusas"
                                    disabled
                                    value={isActive ? 'Patvirtintas' : 'Laukiama patvirtinimo'}
                                    className={clsx({
                                        [classes.notActivated]: !isActive,
                                        [classes.activated]: isActive,
                                    })}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {isOperator && (
                        <Grid item>
                            <Typography className={classes.textOperators}>
                                {'Operatorius turi aktyvuoti API raktą.'}
                                <br />
                                {`Operatorius apie naujai sukurtą API raktą bus informuotas el. paštu ${operatorEmail}, jeigu išsaugodami informaciją pasirinkiste siuntimą el. paštu.`}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <div>
                        <Typography className={classes.textInMethods}>
                            {'Pažymėkite metodus, kurie bus leidžiami pasirinktam Api raktui.'}
                        </Typography>
                        <CheckBoxList
                            data={options}
                            checkedBoxes={allowedMethods}
                            onChange={handleCheckBoxList}
                        />
                    </div>
                </Grid>
            </Grid>
        </AlertDialog>
    );
}

MethodsList.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    fieldIndex: PropTypes.number,
};

export default MethodsList;
