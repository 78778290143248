import React from 'react';
import Layout from '../layout';
import ProceduresUpdatesTable from './components/ProceduresUpdatesTable';

function ProceduresUpdates() {
    return (
        <Layout>
            <ProceduresUpdatesTable />
        </Layout>
    );
}

export default ProceduresUpdates;
