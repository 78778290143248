import React from 'react';
import { Tooltip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Route } from 'react-router-dom';
import * as routes from '../../../app/routes';
import Link from '../../../components/Link';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import StorageIcon from '@material-ui/icons/Storage';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Update from '@material-ui/icons/Update';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useSelector } from 'react-redux';
import {
    isSysAdmin,
    isDealer,
    isClient,
    isClientReadOnly,
    isDealerReadOnly,
    isOperator,
} from '../../auth/selectors';

function SideBarItems(props) {
    const { isDrawerOpen } = props;
    const isUserAdmin = useSelector(isSysAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);
    const isUserOperator = useSelector(isOperator);
    const isUserDealerReadOnly = useSelector(isDealerReadOnly);
    const isUserClientReadOnly = useSelector(isClientReadOnly);
    const items = [];

    if (isUserClient || isUserClientReadOnly) {
        items.push(
            ...[
                { title: 'Apžvalga', route: routes.ROUTE_DASHBOARD, icon: DashboardIcon },
                { title: 'Įmonės (DB)', route: routes.ROUTE_CLIENT_DBS, icon: BarChartIcon },
                { title: 'Vartotojai', route: routes.ROUTE_USERS, icon: PeopleIcon },
            ],
        );
    }

    if ((isUserAdmin || isUserDealer || isUserDealerReadOnly) && !isUserOperator) {
        items.push(
            ...[
                { title: 'Apžvalga', route: routes.ROUTE_DASHBOARD, icon: DashboardIcon },
                { title: 'Klientai', route: routes.ROUTE_CLIENTS, icon: ContactMailIcon },
                { title: 'Įmonės (DB)', route: routes.ROUTE_CLIENT_DBS, icon: BarChartIcon },
                { title: 'Vartotojai', route: routes.ROUTE_USERS, icon: PeopleIcon },
            ],
        );
    }

    if (isUserOperator) {
        items.push(
            ...[
                { title: 'Apžvalga', route: routes.ROUTE_DASHBOARD, icon: DashboardIcon },
                { title: 'API raktai', route: routes.ROUTE_APIKEYS, icon: VpnKeyIcon },
            ],
        );
    }

    if (isUserAdmin) {
        items.push(
            ...[
                { title: 'API raktai', route: routes.ROUTE_APIKEYS, icon: VpnKeyIcon },
                { title: 'Procedūros', route: routes.ROUTE_PROCEDURES, icon: StorageIcon },
                {
                    title: 'Proc. atnaujinimai',
                    route: routes.ROUTE_PROCEDURES_UPDATES,
                    icon: Update,
                },
                { title: 'Pranešimai', route: routes.ROUTE_MESSAGES, icon: FeedbackIcon },
            ],
        );
    }

    return (
        <List>
            {items.map((item) => (
                <Route exact={!!item.route.exact} path={item.route.path} key={item.route.path}>
                    {({ match }) => (
                        <Tooltip title={isDrawerOpen ? '' : item.title}>
                            <ListItem
                                key={item.title}
                                component={Link}
                                to={item.route.path}
                                selected={!!match}
                                button
                            >
                                <ListItemIcon>
                                    <item.icon />
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        </Tooltip>
                    )}
                </Route>
            ))}
        </List>
    );
}

export default React.memo(SideBarItems);
