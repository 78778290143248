import React from 'react';
import PropTypes from 'prop-types';
import { TableHead as MuiTableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { getHeaderProps } from '../utils';
import styles from '../dataTable.module.css';

function TableHead(props) {
    const { headerGroups } = props;

    return (
        <MuiTableHead>
            {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                        return (
                            <TableCell {...column.getHeaderProps(getHeaderProps(column))}>
                                {column.canSort ? (
                                    <TableSortLabel
                                        {...column.getSortByToggleProps({
                                            title: getSortByTitle(column),
                                            active: column.isSorted,
                                            direction: column.isSortedDesc ? 'desc' : 'asc',
                                        })}
                                    >
                                        {column.render('Header')}
                                    </TableSortLabel>
                                ) : (
                                    column.render('Header')
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
            {headerGroups.map((headerGroup) => {
                return (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                            return (
                                <TableCell
                                    {...column.getHeaderProps({ className: styles.cellHeader })}
                                >
                                    {column.canFilter ? column.render('Filter') : null}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </MuiTableHead>
    );
}

const getSortByTitle = (column) => {
    if (column.isSorted) {
        return column.isSortedDesc ? 'Rūšiuoti didėjančia tvarka' : 'Rūšiuoti mažėjančia tvarka';
    }

    return `Rūšiuoti "${column.Header}" stulpelį`;
};

TableHead.propTypes = {
    headerGroups: PropTypes.arrayOf(
        PropTypes.shape({
            getHeaderGroupProps: PropTypes.func.isRequired,
            headers: PropTypes.arrayOf(
                PropTypes.shape({
                    getHeaderProps: PropTypes.func.isRequired,
                    getSortByToggleProps: PropTypes.func.isRequired,
                    sortedIndex: PropTypes.number.isRequired,
                    sortedDesc: PropTypes.bool,
                    canSort: PropTypes.bool.isRequired,
                    canFilter: PropTypes.bool.isRequired,
                }),
            ).isRequired,
        }),
    ).isRequired,
};

export default TableHead;
