import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import clipboardCopy from 'clipboard-copy';

const style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
};

const CellWithTooltip = (props) => {
    const { value = ' ', copyOnDblClick } = props;

    return (
        <div>
            <Tooltip title={!value ? ' ' : value}>
                <Typography
                    variant="inherit"
                    style={style}
                    onDoubleClick={copyOnDblClick ? () => clipboardCopy(value) : null}
                >
                    {value}
                </Typography>
            </Tooltip>
        </div>
    );
};

CellWithTooltip.propTypes = {
    value: PropTypes.string,
    copyOnDblClick: PropTypes.bool,
};

export default React.memo(CellWithTooltip);
