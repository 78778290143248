import { createSelector } from 'reselect';

const moduleName = 'clients';

/**
 * State (example):
 {
    isFetching: false,
    error: null,
    items: {
        1: {
            "id": 1,
            "code": "LTD001",
            "name": "dealer",
            "description": null,
            "email": "dealer@dealer.lt",
            "ksCode": "KSLTD001",
            "isDealer": true,
            "dealerId": 1,
            "createdAt": "2019-09-26T15:38:00.852+0000",
            "updatedAt": "2019-09-26T15:38:00.852+0000",
            "blockedAt": null,
            "isActive": true
        },
        ...
    },
    order: [ 1, ... ],
  }
 */
const getClientsState = state => state[moduleName];
export const isClientsFetching = state => getClientsState(state).isFetching;
export const getClients = createSelector(getClientsState, clientsState => {
    const { items, order } = clientsState;
    return order.map(id => items[id]);
});
export const getActiveDealers = createSelector(getClients, clients =>
    clients.filter(client => client.isDealer && client.isActive),
);
export const getClient = (state, id) => getClientsState(state)[id];

export default moduleName;
