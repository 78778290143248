import { Checkbox, FormControlLabel, Link, Paper, Typography } from '@material-ui/core';
import { ROUTE_PASSWORD } from 'app/routes';
import LoadingPage from 'components/LoadingPage';
import { FORM_ERROR } from 'final-form';
import { parse } from 'qs';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../../components/Logo';
import { SubmitButton, TextField } from '../../../components/form';
import { useState } from '../../../hooks';
import { matchesField, minLength, required, validators } from '../../../utils/validate';
import { resetPassword, validateResetPasswordToken } from '../../api/users';
import { NetworkError } from '../../api/utils/errors';
import { validatePswd } from '../../users/components/formUtils';
import useLoginStyles from './useLoginStyles';

const initialValues = { password: '', repeatPassword: '' };
const subscription = { submitError: true, submitting: true };

function ChangePasswordForm() {
    const classes = useLoginStyles();
    const location = useLocation();
    const [showError, setShowError] = useState({ show: false, message: '' });
    const [forcedSubmitError, setForcedSubmitError] = useState(null);
    const token = parse(location.search, { ignoreQueryPrefix: true }).resetToken;
    const app = parse(location.search, { ignoreQueryPrefix: true }).app;
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(!!token);


    const history = useHistory();

    React.useEffect(() => {
        !token
            ? setShowError({ show: true, message: 'Puslapis nerastas...' })
            : setShowError({ show: false, message: '' });
    }, [token]);

    const linkTo = React.useMemo(() => {
        let link = { to: '/login', name: 'Grįžti į MANO Rivile' };
        if (app && app.toUpperCase() === 'MSCAN') {
            link = { to: process.env.REACT_APP_MSCAN_LINK, name: 'Grįžti į Rivile MSCAN' };
        } else if (app && app.toUpperCase() === 'MGAMA') {
            link = { to: process.env.REACT_APP_MGAMA_LINK, name: 'Grįžti į Rivile MGAMA' };
        }

        return link;
    }, [app]);

    const onSubmit = React.useCallback(
        (values) => {
            const params = { ...values, resetToken: token };
            return resetPassword(params)
                .then(() => {
                    setShowError({
                        show: true,
                        message: 'Slaptažodis sėkmingai pakeistas!',
                    });
                })
                .catch((e) => {
                    let msg = '';
                    if (e instanceof NetworkError) {
                        msg = 'Nenumatyta klaida. Pabandykite dar kartą.';
                    } else {
                        msg =
                            (e.response &&
                                e.response.data?.message + '. ' + e.response.data?.debugMessage) ||
                            e.message;
                    }

                    return { [FORM_ERROR]: msg };
                });
        },
        [token],
    );

    const handleShowClick = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    const handleTokenValidation = React.useCallback(async () => {
        try {
            const tokenIsValid = await validateResetPasswordToken(token);
            if (tokenIsValid) {
                setLoading(false);
            } else {
                history.replace(ROUTE_PASSWORD.path, { error: 'Nuorodos galiojimas pasibaigęs' });
            }
        } catch (e) {
            let msg = '';
            if (e instanceof NetworkError) {
                msg = 'Nenumatyta klaida. Pabandykite dar kartą.';
            } else {
                msg =
                    (e.response &&
                        e.response.data?.message + '. ' + e.response.data?.debugMessage) ||
                    e.message;
            }
            setForcedSubmitError(msg);
            setLoading(false);
        }
    }, [history, token]);

    React.useEffect(() => {
        if (token) {
            handleTokenValidation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <LoadingPage />
    }

    return (
        <div className={classes.main}>
            <Paper className={classes.paper}>
                <Logo width="110" />
                {showError.show ? (
                    <div className={classes.link}>
                        <Typography align="center" className={classes.message} color="primary">
                            {showError.message}
                        </Typography>
                        <Link href={linkTo.to} underline="always" color="secondary">
                            {linkTo.name}
                        </Link>
                    </div>
                ) : (
                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        subscription={subscription}
                    >
                        {({ handleSubmit, submitError, submitting }) => {
                            let error = null;

                            if (forcedSubmitError) {
                                error = forcedSubmitError;
                            }

                            if (submitError) {
                                error = submitError;
                            }

                            return (
                                <form className={classes.form} onSubmit={handleSubmit} method="post">
                                    {(error) && (
                                        <Typography color="error" paragraph>
                                            {error}
                                        </Typography>
                                    )}
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        label="Slaptažodis"
                                        validate={validators(required, minLength(12), validatePswd)}
                                        autoComplete="new-password"
                                        required
                                        fullWidth
                                        autoFocus
                                        readOnly={false}
                                    />
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        name="repeatPassword"
                                        label="Pakartokite slaptažodį"
                                        validate={matchesField(
                                            'password',
                                            'Slaptažodis ir jo pakartojimas nesutampa',
                                        )}
                                        autoComplete="new-password"
                                        required
                                        fullWidth
                                        readOnly={false}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPassword}
                                                onChange={handleShowClick}
                                            />
                                        }
                                        label="Rodyti slaptažodį"
                                    />

                                    <SubmitButton className={classes.submit} fullWidth readOnly={false}>
                                        {submitting ? 'Siunčiama...' : 'Patvirtinti'}
                                    </SubmitButton>
                                </form>
                            )
                        }}
                    </Form>
                )}
            </Paper>
        </div>
    );
}

export default ChangePasswordForm;
