import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import {
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    makeStyles,
    Box,
} from '@material-ui/core';
import { Checkbox, Select, TextField } from '../../../components/form';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { minValue, naturalNumber, required, validators } from '../../../utils/validate';
import AddIcon from '@material-ui/icons/Add';
import { useFormState } from 'react-final-form';
import { isReadOnly } from '../../auth/selectors';
import { useSelector } from 'react-redux';

const paramTypeOptions = ['numeric', 'int', 'datetime', 'text', 'varchar', 'char'].map((i) => ({
    label: i,
    value: i,
}));

const useStyles = makeStyles((theme) => ({
    removeIcon: {
        color: theme.palette.error.main,
    },
    paramButtons: {
        '& > *': { margin: theme.spacing(1) },
    },
}));

function ProcedureParamsForm({ fieldName }) {
    const { fields } = useFieldArray(fieldName);
    const { values } = useFormState();
    const sqlCode = values.mysqlCode;
    const isUserReadOnly = useSelector(isReadOnly);
    const classes = useStyles();

    const addParamListHandler = () => {
        const paramListStartsAt = sqlCode.toLowerCase().indexOf('@');
        const paramListEndsAt = sqlCode.toLowerCase().search(/\s+as+[\s\n\r]/);

        if (paramListStartsAt < 0 || paramListEndsAt < 0) return '';

        const paramsList = sqlCode
            .substr(paramListStartsAt + 1, paramListEndsAt - paramListStartsAt - 1)
            .split('@');

        for (let i = 0; i < paramsList.length; i++) {
            const paramMatch = paramsList[i].split(/[\s\n\t]+/);
            let dataType =
                typeof paramMatch[1] !== 'undefined'
                    ? paramMatch[1].split(/[(=,]+/)[0].toLowerCase()
                    : '';

            if (dataType === 'datetime') dataType = 'varchar';
            if (dataType === 'bigint') dataType = 'int';

            fields.push({
                canPass: true,
                defaultValue: null,
                paramOrder: i + 1,
                name: paramMatch[0],
                type: dataType,
            });
        }
    };

    const addParamButton = (
        <Box mb={2} className={classes.paramButtons}>
            <Button
                size="small"
                variant="contained"
                color="default"
                startIcon={<AddIcon />}
                onClick={() => fields.push({ canPass: true, paramOrder: fields.length + 1 })}
            >
                Pridėti parametrą
            </Button>

            <Button
                size="small"
                variant="contained"
                color="default"
                startIcon={<AddIcon />}
                onClick={addParamListHandler}
                disabled={fields.length > 0 ? true : false}
            >
                Generuoti parametrų sąrašą
            </Button>
        </Box>
    );

    if (!fields.length) {
        return addParamButton;
    }

    return (
        <React.Fragment>
            {!isUserReadOnly && addParamButton}

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Parameter</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Default</TableCell>
                            <TableCell>Can pass</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((name, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <TextField name={`${name}.name`} validate={required} />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        name={`${name}.type`}
                                        items={paramTypeOptions}
                                        validate={required}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name={`${name}.paramOrder`}
                                        validate={validators(required, naturalNumber, minValue(1))}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField name={`${name}.defaultValue`} />
                                </TableCell>
                                <TableCell>
                                    <Checkbox name={`${name}.canPass`} />
                                </TableCell>

                                <TableCell>
                                    <IconButton
                                        onClick={() => fields.remove(index)}
                                        className={classes.removeIcon}
                                        disabled={isUserReadOnly}
                                    >
                                        <DeleteIcon color="inherit" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

ProcedureParamsForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default React.memo(ProcedureParamsForm);
