import { client } from './utils';
import {
    defaultQuery,
    getResponseData,
    formatListResponse,
    uri,
    formatQuery,
    OP_EQ,
} from './utils/helpers';

export const getClient = (id) => client.get(uri`/account/client/${id}`).then(getResponseData);

export const getClients = (query = defaultQuery) =>
    client.post('/account/client/query', query).then(formatListResponse);

export const getActiveDealers = () =>
    getClients(
        formatQuery({
            filters: [
                {
                    id: 'isDealer',
                    value: {
                        operation: OP_EQ,
                        value: true,
                    },
                },
                {
                    id: 'isActive',
                    value: {
                        operation: OP_EQ,
                        value: true,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const getOperators = () =>
    getClients(
        formatQuery({
            filters: [
                {
                    id: 'isOperator',
                    value: {
                        operation: OP_EQ,
                        value: true,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const createClient = (data) => client.post('/account/client', data).then(getResponseData);

export const updateClient = (id, data) =>
    client.put(uri`/account/client/${id}`, data).then(getResponseData);
