import { makeStyles } from '@material-ui/core';

const useLoginStyles = makeStyles((theme) => {
    let spacing = theme.spacing;
    return {
        main: {
            width: 'auto',
            display: 'block', // Fix IE 11 issue.
            marginLeft: spacing(3),
            marginRight: spacing(3),
            [theme.breakpoints.up(400 + spacing(6))]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: spacing(3),
        },
        submit: {
            marginTop: spacing(3),
        },
        password: {
            paddingTop: spacing(3),
            display: 'block',
        },
        message: {
            margin: `${spacing(3)}px 0 ${spacing(3)}px`,
        },
        link: {
            textAlign: 'center',
        },
    };
});

export default useLoginStyles;
