import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import {
    Box,
    Button,
    IconButton,
    makeStyles,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField as MuiTextField,
    Tooltip,
    Badge,
} from '@material-ui/core';
import { DatePicker, TextField, Checkbox } from '../../../components/form';
import { generateApiKey } from '../../api/apikeys';
import { getMethodList } from '../../api/methodlist';
import { useState } from '../../../hooks';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Autorenew';
import ListIcon from '@material-ui/icons/List';
import Alert from '@material-ui/lab/Alert';
import { required } from '../../../utils/validate';
import AlertDialog from '../../../components/form/AlertDialog';
import { useNotifications } from '../../notifications';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { isAdmin, isReadOnly } from '../../auth/selectors';
import useQuery from '../../../hooks/useQuery';
import { Loading } from 'components/form';
import MethodsList from './MethodsList';
import { getOperators } from '../../api/clients';

const useStyles = makeStyles((theme) => ({
    copyTextarea: {
        paddingTop: 10,
        fontSize: 13,
        fontFamily: 'monospace',
    },
    removeIcon: {
        color: theme.palette.error.main,
    },
}));

function ApiKeysForm({ fieldName, isEdit, clientConnections }) {
    const { fields } = useFieldArray(fieldName);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openListDialog, setOpenListDialog] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(undefined);
    const classes = useStyles();
    const notifications = useNotifications();
    const { values } = useFormState();
    const connectionId = values.clientConnectionId;
    const [updated, setUpdated] = useState([]);
    const [operatorsEmails, setOperatorEmails] = useState([]);

    const isUserAdmin = useSelector(isAdmin);
    const isUserReadOnly = useSelector(isReadOnly);

    const email = React.useMemo(() => {
        const currentConn = clientConnections
            ? clientConnections.data.find((conn) => conn.id === connectionId)
            : [];
        return currentConn && currentConn.client ? currentConn.client.email : '';
    }, [connectionId, clientConnections]);

    const { data, isLoading, error } = useQuery(getMethodList);

    const methodOptions = React.useMemo(
        () =>
            data && data.data
                ? data.data.map((value) => ({
                      value: value.name,
                      label: value.name + ' - ' + value.description,
                  }))
                : [],
        [data],
    );

    const totalMethods = methodOptions ? methodOptions.length : 0;

    // const { data: operatorsList, isLoading: opLoading, error: opError } = useQuery(getOperators);

    React.useEffect(
        () => {
            getOperators()
                .then((data) => {
                    setOperatorEmails(
                        data && data.data
                            ? data.data.map((value) => ({ value: value.id, email: value.email }))
                            : [],
                    );
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                });
        }, // eslint-disable-next-line
        [],
    );

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        notifications.showError(error.message);
        return;
    }

    const addButton = (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            onClick={async () => {
                try {
                    setIsGenerating(true);
                    const apiKey = await generateApiKey();
                    apiKey.generated = true; // special marker to know how to render this below
                    apiKey.validToDate = '';
                    apiKey.allowedMethods = data
                        ? data.data
                              .filter((value) => value.autoPrescribe)
                              .map((value) => value.name)
                        : [];
                    fields.push(apiKey);
                    setIsGenerating(false);
                    setShowAlert(true);
                } catch (e) {
                    notifications.showError(e.message);
                }
            }}
            disabled={isGenerating}
        >
            Sukurti naują API raktą
        </Button>
    );

    if (!fields.length) {
        return <Box my={2}>{!isUserReadOnly && addButton}</Box>;
    }

    const handleEdit = (index) => {
        setFieldIndex(index);
        setOpenDialog(true);
    };

    const handleMethodList = (index) => {
        setFieldIndex(index);
        setOpenListDialog(true);
    };

    const handleAlertClose = () => {
        if (openDialog) setOpenDialog(false);
        if (openListDialog) setOpenListDialog(false);
    };

    const regenerateApiKey = async () => {
        setOpenDialog(false);
        const updatedField = { ...fields.value[fieldIndex] };

        setIsGenerating(true);
        try {
            const regApiKey = await generateApiKey();
            setIsGenerating(false);

            const newApiKey = regApiKey.apiKey.split('.')[1];
            updatedField.apiKey = updatedField.prefix + '.' + newApiKey;
            updatedField.generated = true;
            updatedField.changeApiKey = true;

            fields.update(fieldIndex, updatedField);
            setShowAlert(true);
        } catch (e) {
            notifications.showError(e.message);
        }
    };

    const setPermittedMethods = (params) => {
        setOpenListDialog(false);
        const updatedField = { ...fields.value[fieldIndex] };
        updatedField.allowedMethods = params.checkedItems;
        updatedField.operatorId = params.operatorId;
        updatedField.permissionsChanged = true;
        fields.update(fieldIndex, updatedField);

        const newUpdated = [...updated];
        const currentIndex = newUpdated.indexOf(fieldIndex);

        if (currentIndex === -1) {
            newUpdated.push(fieldIndex);
        }

        setUpdated(newUpdated);
    };

    return (
        <Box my={2}>
            <Box mb={2}>{!isUserReadOnly && addButton}</Box>

            {showAlert && (
                <Alert severity="info">
                    API raktas rodomas tik vieną kartą, uždarius formą jo pamatyti neįmanoma
                </Alert>
            )}

            {openDialog && (
                <AlertDialog
                    title="Ar tikrai sugeneruoti Api raktą iš naujo?"
                    open={openDialog}
                    onClose={handleAlertClose}
                    onConfirmation={regenerateApiKey}
                />
            )}

            {openListDialog && (
                <MethodsList
                    open={openListDialog}
                    onClose={handleAlertClose}
                    onConfirm={setPermittedMethods}
                    options={methodOptions}
                    fieldIndex={fieldIndex}
                />
            )}

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>API raktas</TableCell>
                            <TableCell>Galioja iki</TableCell>
                            <TableCell>Paskirtis</TableCell>
                            <TableCell>Siųsti el.paštu</TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((name, index) => {
                            const {
                                prefix,
                                apiKey,
                                generated,
                                allowedMethods,
                                operatorId,
                            } = fields.value[index];
                            let opEmail = '';

                            if (operatorId && operatorsEmails.length > 0) {
                                const operator = operatorsEmails.filter(
                                    (op) => op.value === operatorId,
                                );
                                opEmail = operator.length > 0 ? operator[0].email : '';
                            }

                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" width="55%">
                                        {!isEdit || generated ? (
                                            <TextField name={`${name}.apiKey`} fullWidth disabled />
                                        ) : (
                                            <MuiTextField
                                                value={prefix + '.' + apiKey.replace(/./g, '*')}
                                                fullWidth
                                                disabled
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell width="15%">
                                        <DatePicker
                                            name={`${name}.validToDate`}
                                            format="YYYY.MM.DD"
                                            variant="dialog"
                                            autoOk
                                            clearable
                                        />
                                    </TableCell>
                                    <TableCell width="20%">
                                        <TextField
                                            name={`${name}.description`}
                                            fullWidth
                                            validate={required}
                                        />
                                    </TableCell>

                                    <Tooltip
                                        arrow
                                        placement="right"
                                        title={`El.paštu: ${opEmail !== '' ? opEmail : email}`}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                name={`${name}.sendEmail`}
                                                disabled={!(!isEdit || generated)}
                                            />
                                        </TableCell>
                                    </Tooltip>

                                    {isUserAdmin && (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => fields.remove(index)}
                                                className={classes.removeIcon}
                                            >
                                                <DeleteIcon color="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    )}

                                    {!isUserReadOnly && (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleEdit(index)}
                                                disabled={isGenerating}
                                            >
                                                <EditIcon color="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    )}

                                    <Tooltip
                                        arrow
                                        placement="left"
                                        title={`Pažymėti metodai: ${allowedMethods.length} / ${totalMethods}`}
                                    >
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleMethodList(index)}
                                                disabled={isGenerating}
                                            >
                                                <Badge
                                                    color="secondary"
                                                    variant="dot"
                                                    badgeContent=" "
                                                    invisible={
                                                        updated.indexOf(index) === -1 ? true : false
                                                    }
                                                >
                                                    <ListIcon color="inherit" />
                                                </Badge>
                                            </IconButton>
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

ApiKeysForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
};

export default React.memo(ApiKeysForm);
