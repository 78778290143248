import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import {
    Box,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    makeStyles,
} from '@material-ui/core';
import { Select, TextField } from '../../../components/form';
import { isReadOnly, isAdmin, isDealer, isClient } from '../../auth/selectors';
import { useSelector } from 'react-redux';
import { required } from '../../../utils/validate';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { getApplications } from '../../api/application';
import { getDbAppLicencesByClient } from '../../api/clientDbAppLicense';
import { getAllDbByClientId } from '../../api/clientdbs';
import useQuery from '../../../hooks/useQuery';
import AddIcon from '@material-ui/icons/Add';
import { useNotifications } from '../../notifications';
import { useFormState } from 'react-final-form';
import { useState } from '../../../hooks';
import { OnChange } from 'react-final-form-listeners';

const useStyles = makeStyles((theme) => ({
    removeIcon: {
        color: theme.palette.error.main,
    },
}));

function UserAppForm({ fieldName }) {
    const { fields } = useFieldArray(fieldName);
    const isEmpty = Object.entries(fields.value).length > 0;
    const isUserReadOnly = useSelector(isReadOnly);
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);
    const classes = useStyles();
    const notifications = useNotifications();
    const { values } = useFormState();
    const clientId = values.clientId ? values.clientId : '';
    const [licenses, setLicenses] = useState([]);
    const [newApp, setNewApp] = useState({});
    const [data, setData] = useState();

    React.useEffect(() => {
        if (!(clientId > 0)) {
            return;
        }

        getAllDbByClientId(clientId)
            .then((data) => setData(data))
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [clientId, notifications]);

    const initialDbOptions = React.useMemo(
        () =>
            !data && fields.value && fields.value.length > 0
                ? fields.value.map((db) => ({
                      label: JSON.stringify(db.clientDbId),
                      value: db.clientDbId,
                  }))
                : [],
        [data, fields],
    );

    const dbOptions = React.useMemo(
        () =>
            data && data.data
                ? data.data.map((db) => ({
                      label: db.name,
                      value: db.id,
                  }))
                : [],
        [data],
    );

    React.useEffect(() => {
        if (!(clientId > 0)) {
            return;
        }

        getDbAppLicencesByClient(clientId)
            .then((data) => setLicenses(data.data))
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [clientId, notifications]);

    React.useEffect(
        () => {
            const fieldsArray = [...fields.value];
            for (let i = 0; i < fieldsArray.length; i++) {
                const applicationName = fields.value[i].applicationName;
                const clientDbId = fields.value[i].clientDbId;
                const licenseArray = licenses.filter(
                    (item) =>
                        item.applicationName === applicationName && item.clientDbId === clientDbId,
                );
                const licenseCount = licenseArray.length > 0 ? licenseArray[0].licenseCount : 0;
                const usersCount = licenseArray.length > 0 ? licenseArray[0].usersCount : 0;
                const newValue = { ...fields.value[i], licenseCount, usersCount };
                fields.update(i, newValue);
            }
        }, // eslint-disable-next-line
        [licenses, isEmpty, newApp],
    );

    const { data: apps, error } = useQuery(getApplications);
    const appsOptions = React.useMemo(
        () =>
            apps
                ? apps.data.map((app) => ({
                      label: app.name,
                      value: app.name,
                  }))
                : [],
        [apps],
    );

    const initialAppsOptions = React.useMemo(
        () =>
            !apps && fields.value && fields.value.length > 0
                ? fields.value.map((db) => ({
                      label: db.applicationName,
                      value: db.applicationName,
                  }))
                : [],
        [apps, fields],
    );

    if (error) {
        if (error) notifications.showError(error.message);
        return;
    }

    const addUserButton = (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            onClick={() =>
                fields.push({
                    isNew: true,
                    licenseCount: 0,
                    usersCount: 0,
                    rivUser: values.email.split('@')[0].substring(0, 12).toUpperCase(),
                })
            }
        >
            Pridėti teisę dirbti su aplikacija
        </Button>
    );

    if (!fields.length) {
        return (
            <Box mb={2}>
                {!isUserReadOnly && (isUserAdmin || isUserDealer || isUserClient) && addUserButton}
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box mb={2}>
                {!isUserReadOnly && (isUserAdmin || isUserDealer || isUserClient) && addUserButton}
            </Box>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Duomenų bazė</TableCell>
                            <TableCell>Vartotojo vardas Rivilėje</TableCell>
                            <TableCell>Aplikacija</TableCell>
                            <TableCell>Licencijos</TableCell>
                            <TableCell>Panaudota</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((name, index) => {
                            const { isNew, applicationName, clientDbId } = fields.value[index];

                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" style={{ width: '30%' }}>
                                        <Select
                                            name={`${name}.clientDbId`}
                                            items={!data ? initialDbOptions : dbOptions}
                                            validate={required}
                                            disabled={!isNew}
                                        />

                                        <OnChange name={`${name}.clientDbId`}>
                                            {(value) => {
                                                if (isNew)
                                                    setNewApp({
                                                        clientDbId: value,
                                                        applicationName,
                                                    });
                                            }}
                                        </OnChange>
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        <TextField
                                            name={`${name}.rivUser`}
                                            validate={required}
                                            disabled={!isUserAdmin && !isNew}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        <Select
                                            name={`${name}.applicationName`}
                                            items={!apps ? initialAppsOptions : appsOptions}
                                            validate={required}
                                            disabled={!isNew}
                                        />

                                        <OnChange name={`${name}.applicationName`}>
                                            {(value) => {
                                                if (isNew)
                                                    setNewApp({
                                                        clientDbId,
                                                        applicationName: value,
                                                    });
                                            }}
                                        </OnChange>
                                    </TableCell>

                                    <TableCell style={{ width: '10%' }}>
                                        <TextField name={`${name}.licenseCount`} disabled />
                                    </TableCell>

                                    <TableCell style={{ width: '10%' }}>
                                        <TextField name={`${name}.usersCount`} disabled />
                                    </TableCell>

                                    <TableCell style={{ width: '10%' }}>
                                        <IconButton
                                            onClick={() => fields.remove(index)}
                                            className={classes.removeIcon}
                                            disabled={isUserReadOnly}
                                        >
                                            <DeleteIcon color="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

UserAppForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default React.memo(UserAppForm);
