import { Grid } from '@material-ui/core';
import { FormButtons, Loading, Switch, TextField } from 'components/form';
import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import actionLogout from 'utils/actionLogout';
import { useState } from '../../../hooks';
import {
    email,
    matchesField,
    maybeValidate,
    minLength,
    required,
    validators,
} from '../../../utils/validate';
import { getUser, updateUser } from '../../api/users';
import { getUserId } from '../../auth/selectors';
import { useNotifications } from '../../notifications';
import { formatSubmitValues, validatePswd } from './formUtils';

const formSubscription = { values: true };

function UserProfileForm() {
    const userId = useSelector(getUserId);
    const [user, setUser] = useState({});
    let isLoading = React.useRef(false);
    const notifications = useNotifications();

    React.useEffect(() => {
        if (!userId) {
            return;
        }

        isLoading.current = true;

        getUser(userId)
            .then((data) => {
                isLoading.current = false;
                setUser(data);
            })
            .catch((e) => {
                const msg = e.response ? e.response.data.message : e.message;
                notifications.showError(msg);
            });
    }, [userId, notifications]);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            clientId: user && user.client ? user.client.id : '',
            email: user.email || '',
            login: user.login || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            role: user.role,
            oldPassword: '',
            password: '',
            repeatPassword: '',
            showPasswordFields: false,
        }),
        [user],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (values) => {
            const userData = formatSubmitValues(values);
            return updateUser(userId, userData)
                .then(() => {
                    notifications.showSuccess(`Profilio duomenys atnaujinti.`);
                    if (values.password) {
                        actionLogout();
                    }
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                });
        },
        [notifications, userId],
    );

    if (isLoading.current) {
        return <Loading />;
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            subscription={formSubscription}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid spacing={1} container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="email"
                                label="El. paštas"
                                validate={validators(required, email)}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="login"
                                label="Vartotojo vardas"
                                validate={validators(required, minLength(2))}
                                disabled
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                label="Vardas"
                                validate={validators(required, minLength(2))}
                                readOnly={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="lastName"
                                label="Pavardė"
                                validate={validators(required, minLength(2))}
                                readOnly={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Switch
                                name="showPasswordFields"
                                label="Keisti slaptažodį?"
                                color="secondary"
                                readOnly={false}
                            />
                        </Grid>

                        <Grid
                            xs={12}
                            item
                            style={{ display: values.showPasswordFields ? 'block' : 'none' }}
                        >
                            <Grid spacing={1} container>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="oldPassword"
                                        type="password"
                                        label="Senas slaptažodis"
                                        validate={validators(
                                            maybeValidate(
                                                required,
                                                () => values.showPasswordFields,
                                            ),
                                        )}
                                        autoComplete="current-password"
                                        required={values.showPasswordFields}
                                        key={values.showPasswordFields ? 1 : 0}
                                        readOnly={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="password"
                                        type="password"
                                        label="Slaptažodis"
                                        validate={validators(
                                            maybeValidate(
                                                required,
                                                () => values.showPasswordFields,
                                            ),
                                            minLength(12),
                                            validatePswd,
                                        )}
                                        autoComplete="new-password"
                                        required={values.showPasswordFields}
                                        key={values.showPasswordFields ? 1 : 0}
                                        readOnly={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="repeatPassword"
                                        type="password"
                                        label="Pakartokite slaptažodį"
                                        validate={matchesField(
                                            'password',
                                            'Slaptažodis ir jo pakartojimas nesutampa',
                                        )}
                                        autoComplete="new-password"
                                        required={values.showPasswordFields}
                                        readOnly={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <FormButtons readOnly={false} />
                </form>
            )}
        />
    );
}

export default UserProfileForm;
