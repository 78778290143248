import React from 'react';
import Layout from '../layout';
import TelemetryTable from './components/telemetryTable';

function Dashboard() {
    return (
        <Layout>
            <TelemetryTable />
        </Layout>
    );
}

export default Dashboard;
