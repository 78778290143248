import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';

const DateCell = (props) => {
    const { value, locale, withoutTime } = props;
    if (!value) {
        return '-';
    }
    const date = value instanceof Date ? value : new Date(value);
    return (
        <Tooltip
            title={
                withoutTime
                    ? date.toLocaleString(locale).substring(0, 10)
                    : date.toLocaleString(locale)
            }
        >
            <Typography variant="inherit">
                {withoutTime
                    ? date.toLocaleString(locale).substring(0, 10)
                    : date.toLocaleString(locale)}
            </Typography>
        </Tooltip>
    );
};

DateCell.defaultProps = {
    locale: 'lt-LT',
};

DateCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    locale: PropTypes.string.isRequired,
    withoutTime: PropTypes.bool,
};
export default React.memo(DateCell);
