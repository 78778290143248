import { client } from './utils';
import { defaultQuery, getResponseData, formatListResponse, uri } from './utils/helpers';

export const getMessage = id => client.get(uri`/account/messages/${id}`).then(getResponseData);

export const getMessages = (query = defaultQuery) =>
    client.post('/account/messages/query', query).then(formatListResponse);

export const createMessage = data => client.post('/account/messages', data).then(getResponseData);

export const updateMessage = (id, data) =>
    client.put(uri`/account/messages/${id}`, data).then(getResponseData);

export const deleteMessage = id =>
    client.delete(uri`/account/messages/${id}`).then(getResponseData);
