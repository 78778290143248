import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    withStyles,
    Toolbar,
    Paper,
    Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import SideBarItems from './components/SideBarItems';
import Logo from 'components/Logo';
import ProfileMenu from './components/ProfileMenu';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import styles from './layout.styles';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useSelector } from 'react-redux';
import { getFullName } from '../auth/selectors';

function Layout(props) {
    const { children, classes } = props;
    const title = useSelector(getFullName);

    // drawer state
    const [open, setOpen] = useLocalStorage('isDrawerOpen', true);
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    return (
        <div className={classes.root}>
            <AppBar position="absolute" className={cn(classes.appBar, open && classes.appBarShift)}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={cn(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    <ErrorBoundary>
                        <ProfileMenu />
                    </ErrorBoundary>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: cn(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Logo width="60" style={{ marginLeft: '8px' }} />
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <SideBarItems isDrawerOpen={open} />
            </Drawer>
            <div className={classes.content}>
                <Paper className={classes.paper}>
                    <ErrorBoundary>{children}</ErrorBoundary>
                </Paper>
            </div>
        </div>
    );
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
};

export default withStyles(styles)(Layout);
