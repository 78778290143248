import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import {
    Box,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    makeStyles,
} from '@material-ui/core';
import { Select, TextField } from '../../../components/form';
import { isReadOnly, isAdmin, isDealer, isClient } from '../../auth/selectors';
import { useSelector } from 'react-redux';
import { required } from '../../../utils/validate';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { getUsers } from '../../api/users';
import { getApplications } from '../../api/application';
import AddIcon from '@material-ui/icons/Add';
import { useNotifications } from '../../notifications';
import { OnChange } from 'react-final-form-listeners';
import { useForm } from 'react-final-form';
import { useState } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    removeIcon: {
        color: theme.palette.error.main,
    },
}));

function CientDbAppUsersForm({ fieldName, clientId }) {
    const { fields } = useFieldArray(fieldName);
    const isUserReadOnly = useSelector(isReadOnly);
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);
    const classes = useStyles();
    const notifications = useNotifications();
    const form = useForm();
    const [data, setData] = useState();
    const [apps, setApps] = useState();

    React.useEffect(() => {
        getUsers()
            .then((data) => setData(data))
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [notifications]);

    const usersOptions = React.useMemo(
        () =>
            data && data.data && clientId
                ? data.data
                      .filter((user) => user.client && user.client.id === clientId)
                      .map((user) => ({
                          label:
                              (user.login ? user.login.toUpperCase() : '') +
                              ': ' +
                              user.firstName +
                              ' ' +
                              user.lastName,
                          value: user.id,
                      }))
                : [],
        [data, clientId],
    );

    const generateRivUser = (newValue, index) => {
        const newUser = data && data.data ? data.data.filter((user) => user.id === newValue) : [];

        if (newUser.length > 0) {
            const email = newUser && newUser[0].email ? newUser[0].email : '';
            const newRivUser = email.split('@')[0].substring(0, 12).toUpperCase();

            if (email) form.change(`clientDbUserApplications[${index}].rivUser`, newRivUser);
        }
    };

    React.useEffect(() => {
        getApplications()
            .then((data) => setApps(data))
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [notifications]);

    const appsOptions = React.useMemo(
        () =>
            apps
                ? apps.data.map((app) => ({
                      label: app.name,
                      value: app.name,
                  }))
                : [],
        [apps],
    );

    const addUserButton = (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            onClick={() => fields.push({ isNew: true })}
        >
            Pridėti vartotoją darbui su aplikacija
        </Button>
    );

    if (!fields.length) {
        return (
            <Box mb={2}>
                {!isUserReadOnly && (isUserAdmin || isUserDealer || isUserClient) && addUserButton}
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box mb={2}>
                {!isUserReadOnly && (isUserAdmin || isUserDealer || isUserClient) && addUserButton}
            </Box>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Vartotojas</TableCell>
                            <TableCell>Vartotojo vardas Rivilėje</TableCell>
                            <TableCell>Aplikacija</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((name, index) => {
                            const { isNew } = fields.value[index];
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" style={{ width: '50%' }}>
                                        <Select
                                            name={`${name}.userId`}
                                            items={usersOptions}
                                            validate={required}
                                            disabled={!isNew}
                                        />

                                        <OnChange name={`${name}.userId`}>
                                            {(value) => generateRivUser(value, index)}
                                        </OnChange>
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        <TextField
                                            name={`${name}.rivUser`}
                                            validate={required}
                                            disabled={!isUserAdmin && !isNew}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        <Select
                                            name={`${name}.applicationName`}
                                            items={appsOptions}
                                            validate={required}
                                            disabled={!isNew}
                                        />
                                    </TableCell>

                                    <TableCell style={{ width: '10%' }}>
                                        <IconButton
                                            onClick={() => fields.remove(index)}
                                            className={classes.removeIcon}
                                            disabled={isUserReadOnly}
                                        >
                                            <DeleteIcon color="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

CientDbAppUsersForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default React.memo(CientDbAppUsersForm);
