import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { useField } from 'react-final-form';
import { showError } from './formUtils';
import { matchSorter } from 'match-sorter';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

function filterOptions(options, { inputValue }) {
    return matchSorter(options, inputValue, {
        keys: ['label'],
        keepDiacritics: true,
    });
}

function Autocomplete(props) {
    const {
        name,
        validate,
        label,
        items,
        required,
        disabled: userDisabled,
        fullWidth = true,
        helperText: userHelperText,
        readOnly: readOnlyProp,
        ...rest
    } = props;

    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    const { input, meta } = useField(name, { validate });
    const autocompleteValue = React.useMemo(() => {
        if (!input.value) {
            return null;
        }

        return items.find((i) => i.value === input.value) || null;
    }, [items, input.value]);

    const disabled = meta.submitting || userDisabled;
    const isError = showError(meta);
    const helperText = isError ? meta.error : userHelperText;

    return (
        <MuiAutocomplete
            autoSelect
            disabled={disabled || readOnly}
            options={items}
            filterOptions={filterOptions}
            getOptionLabel={(item) => {
                return (item && item.label) || '';
            }}
            value={autocompleteValue}
            onChange={(e, value) => {
                input.onChange(value ? value.value : '');
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        required={required}
                        disabled={meta.submitting || disabled}
                        error={isError}
                        helperText={helperText}
                        fullWidth={fullWidth}
                    />
                );
            }}
            {...rest}
        />
    );
}

Autocomplete.defaultProps = {
    clearText: 'Išvalyti',
    closeText: 'Uždaryti',
    noOptionsText: 'Nėra įrašų',
    loadingText: 'Kraunasi...',
    openText: 'Atidaryti',
};

Autocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ),
};

export default React.memo(Autocomplete);
