import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const defaultIcon = <EditIcon fontSize="small" />;

function IconAction(props) {
    const { title, icon = defaultIcon, ...rest } = props;
    return (
        <Tooltip title={title}>
            <IconButton aria-label={title} size="small" {...rest}>
                {icon}
            </IconButton>
        </Tooltip>
    );
}

IconAction.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
};

export default IconAction;
