import React from 'react';
import { useFormState } from 'react-final-form';
import { useQuery, useState } from '../../../hooks';
import { useNotifications } from '../../notifications';
import { getMethodList } from '../../api/methodlist';
import {
    Checkbox,
    MenuItem,
    ListItemText,
    Select,
    makeStyles,
    InputLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { mandatoryArray } from '../../../utils/validate';
import { showError } from '../../../components/form/formUtils';

const useStyles = makeStyles(() => ({
    textInSelect: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
}));

function MethodsSelect() {
    const { values } = useFormState();
    const [selected, setSelected] = useState([]);
    const classes = useStyles();
    const [validationError, setValidationError] = React.useState(null);
    const { data, error } = useQuery(getMethodList);

    React.useEffect(() => setSelected(values.defaultMethods ? values.defaultMethods : []), [
        values.defaultMethods,
    ]);

    React.useEffect(() => {
        if (values && values.defaultMethods.length === 0 && data && data.data)
            setSelected(
                data.data.filter((value) => value.autoPrescribe).map((value) => value.name),
            );
    }, [values, data]);

    const methodOptions = React.useMemo(
        () =>
            data && data.data
                ? data.data.map((value) => ({
                      value: value.name,
                      label: value.name + ' - ' + value.description,
                  }))
                : [],
        [data],
    );

    const isAllSelected = methodOptions.length > 0 && selected.length === methodOptions.length;
    const total = React.useMemo(() => (data && data.data ? data.data.length : 0), [data]);

    const notifications = useNotifications();
    if (error) {
        notifications.showError(error.message);
    }

    const handleSelectChange = (func) => (event) => {
        let selectedMethods = [];

        if (event.target.value.includes('all') && selected.length !== methodOptions.length) {
            selectedMethods = methodOptions.map((method) => method.value);
        } else if (event.target.value.includes('all')) {
            selectedMethods = [];
        } else {
            selectedMethods = event.target.value;
        }

        event.target.value = selectedMethods;
        func(event);
    };

    return (
        values.isOperator && (
            <FormControl fullWidth required error={!!validationError}>
                <InputLabel id="rekomenduojami-metodai">Rekomenduojami metodai</InputLabel>
                <Field
                    name="defaultMethods"
                    validate={mandatoryArray}
                    render={({ meta, input }) => {
                        const { error } = meta;
                        setValidationError(showError(meta) ? error : null);

                        return (
                            <Select
                                labelId="rekomenduojami-metodai"
                                multiple
                                renderValue={(selectedValues) =>
                                    `Pasirinkta: ${selectedValues.length} / ${total}`
                                }
                                {...input}
                                onChange={handleSelectChange(input.onChange)}
                                value={selected}
                                fullWidth
                            >
                                {methodOptions.length > 0 && (
                                    <MenuItem key="all" value="all">
                                        <Checkbox checked={isAllSelected} />
                                        <ListItemText>
                                            <div className={classes.textInSelect}>
                                                <div>Žymėti visus</div>
                                                <div>{`Pasirinkta: ${selected.length} / ${total}`}</div>
                                            </div>
                                        </ListItemText>
                                    </MenuItem>
                                )}

                                {methodOptions.map((method) => (
                                    <MenuItem key={method.value} value={method.value}>
                                        <Checkbox checked={selected.indexOf(method.value) > -1} />
                                        <ListItemText primary={method.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        );
                    }}
                />
                {validationError && <FormHelperText>{validationError}</FormHelperText>}
            </FormControl>
        )
    );
}

export default React.memo(MethodsSelect);
