import React from 'react';
import Layout from '../layout';
import MessagesTable from './components/MessagesTable';

function Messages() {
    return (
        <Layout>
            <MessagesTable />
        </Layout>
    );
}

export default Messages;
