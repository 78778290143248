import React from 'react';
import PropTypes from 'prop-types';
import {
    Select as MuiSelect,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { showError } from './formUtils';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

// TODO support / fix multiple cass
function Select(props) {
    const {
        name,
        label,
        items,
        children,

        native,
        displayEmpty = false,
        fullWidth = true,
        multiple,
        disabled: userDisabled,
        required,
        helperText,
        renderValue,

        formControlProps: userFormControlProps,
        selectProps: userSelectProps = {},
        readOnly: readOnlyProp,
        menuItemProps,
        ...fieldProps
    } = props;

    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    if (!items && !children) {
        throw new Error('"children" or "items" prop required');
    }

    const [disabled, setDisabled] = React.useState(userDisabled);
    const [error, setError] = React.useState(null);
    const [formValue, setFormValue] = React.useState(null);

    const formControlProps = {
        ...userFormControlProps,
        required,
        disabled,
        error: !!error,
        fullWidth,
    };

    return (
        <FormControl {...formControlProps}>
            {label && (
                <InputLabel
                    shrink={displayEmpty || (formValue != null && formValue !== '')}
                    htmlFor={name}
                >
                    {label}
                </InputLabel>
            )}
            <Field
                name={name}
                render={({ meta, input }) => {
                    const { submitting, error } = meta;
                    const isUserDisabled = userDisabled === undefined ? submitting : userDisabled;
                    const isDisabled = readOnly ? readOnly : isUserDisabled;
                    setDisabled(isDisabled);
                    setError(showError(meta) ? error : null);
                    setFormValue(input.value);

                    const inputProps = { ...userSelectProps.inputProps, ...input };
                    const selectProps = {
                        ...userSelectProps,
                        inputProps,
                        native,
                        multiple,
                        displayEmpty,
                        renderValue,
                    };

                    return (
                        <MuiSelect {...selectProps}>
                            {renderItems(items, children, native, menuItemProps)}
                        </MuiSelect>
                    );
                }}
                {...fieldProps}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
            {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

function renderItems(items, children, native, menuItemProps) {
    if (children) {
        return children;
    }

    if (native) {
        return items.map(({ value, label, ...itemProps }) => (
            <option key={value} value={value} {...itemProps}>
                {label}
            </option>
        ));
    }

    return items.map(({ value, label, ...itemProps }) => (
        <MenuItem key={value} value={value} {...menuItemProps} {...itemProps}>
            {label}
        </MenuItem>
    ));
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ),
    label: PropTypes.string,
    displayEmpty: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiple: PropTypes.bool,
    native: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    formControlProps: PropTypes.object,
    menuItemProps: PropTypes.object,
};

export default React.memo(Select);
