export const ROUTE_LOGIN = { path: '/login' };
export const ROUTE_DASHBOARD = { path: '/', exact: true };
export const ROUTE_CLIENTS = { path: '/clients' };
export const ROUTE_CLIENT_DBS = { path: '/clientdbs' };
export const ROUTE_CONNECTIONS = { path: '/connections' };
export const ROUTE_COMPANIES = { path: '/companies' };
export const ROUTE_USERS = { path: '/users' };
export const ROUTE_PROFILE = { path: '/profile' };
export const ROUTE_PROCEDURES = { path: '/procedures' };
export const ROUTE_PROCEDURES_UPDATES = { path: '/proceduresupdates' };
export const ROUTE_MESSAGES = { path: '/messages' };
export const ROUTE_PASSWORD = { path: '/forgetpassword' };
export const ROUTE_RESET_PSW = { path: '/user/resetpassword' };
export const ROUTE_APIKEYS = { path: '/apikeys' };
