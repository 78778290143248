import React from 'react';
import Layout from '../layout';
import ClientDbsTable from './components/ClientDbsTable';

function ClientDbs() {
    return (
        <Layout>
            <ClientDbsTable />
        </Layout>
    );
}

export default ClientDbs;
