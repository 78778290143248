import React from 'react';
import Layout from './Layout';
import { Typography } from '@material-ui/core';

function Page404() {
    return (
        <Layout>
            <Typography variant="h2">Puslapis nerastas</Typography>
        </Layout>
    );
}

Page404.propTypes = {};

export default Page404;
