const JUNC_AND = 'AND';
const JUNC_OR = 'OR';

// =, !=, >, >=, <, <=, in, like
export const OP_EQ = '='; // eslint-disable-line no-unused-vars
export const OP_NOT_EQ = '!='; // eslint-disable-line no-unused-vars
export const OP_GT = '>'; // eslint-disable-line no-unused-vars
export const OP_GTE = '>='; // eslint-disable-line no-unused-vars
export const OP_LT = '<'; // eslint-disable-line no-unused-vars
export const OP_LTE = '<='; // eslint-disable-line no-unused-vars
export const OP_IN = 'in'; // eslint-disable-line no-unused-vars
export const OP_LIKE = 'like';

export const defaultQuery = {
    page: 0,
    pageSize: Number.MAX_SAFE_INTEGER,
};

export function makeJunction(filters = [], operation = OP_EQ, junction = JUNC_AND) {
    if (!filters.length) {
        return null;
    }

    return {
        junction,
        entities: filters.map((filter) => {
            const { id: field, value } = filter;
            if (typeof value === 'object') {
                return {
                    field,
                    operation,
                    ...value,
                };
            }
            return {
                field,
                operation,
                value,
            };
        }),
    };
}

/**
 * Use this template literal tag for creating url paths. Every substitution
 * is automatically encoded with `encodeUriComponent` function.
 *
 * @example
 *  // Rewrite this
 *  const urlPath = `path/${encodeURIComponent(deviceId)}/${encodeURIComponent(serverName)}`
 *  // into this
 *  const urlPath = uri`path/${deviceId}/${serverName}`
 *
 * @param {string[]} literals
 * @param {string[] | number[]} substitutions
 * @return {string}
 */
export function uri(literals, ...substitutions) {
    // eslint-disable-line import/prefer-default-export
    let result = '';
    // run the loop only for the substitution count.
    for (let i = 0; i < substitutions.length; i += 1) {
        result += literals[i];
        result += encodeURIComponent(substitutions[i]);
    }
    // add the last literal.
    result += literals[literals.length - 1];
    return result;
}

export function formatQuery({
    filters = [],
    sortBy = [],
    searchAll = [],
    page = 0,
    pageSize = 10,
} = {}) {
    const query = { page, pageSize };

    // column filters
    const columnFilters = makeJunction(filters, OP_LIKE, JUNC_AND);

    // search field filters
    const searchFieldFilters = makeJunction(searchAll, OP_LIKE, JUNC_OR);

    // combine column and search field filters
    if (columnFilters || searchFieldFilters) {
        query.filter = {};
        if (columnFilters && searchFieldFilters) {
            query.filter = {
                junction: JUNC_AND,
                entities: [columnFilters, searchFieldFilters],
            };
        } else if (columnFilters) {
            query.filter = columnFilters;
        } else {
            query.filter = searchFieldFilters;
        }
    }

    if (sortBy.length) {
        query.sort = sortBy.map((sort) => ({
            field: sort.id,
            direction: sort.desc ? 'DESC' : 'ASC',
        }));
    }

    return query;
}

export const getResponseData = (response) => response.data;

export function formatListResponse(response) {
    const { content: data = [], totalElements: itemCount = 0 } = response.data;
    return { data, itemCount };
}

export const getZippedFile = (response) => {
    const today = new Date().toJSON().replace(/-|:|[.]/gi, '');
    const fileName = 'procedures_' + today;
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
