import React from 'react';
import PropTypes from 'prop-types';
import { CancelButton, ResetButton, SubmitButton } from './index';
import { DialogActions } from '@material-ui/core';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

function FormButtons(props) {
    const { onCancel, readOnly: readOnlyProp } = props;
    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    return (
        <DialogActions>
            {!readOnly && <SubmitButton />}
            {!readOnly && <ResetButton />}
            {onCancel && <CancelButton onClick={onCancel} />}
        </DialogActions>
    );
}

FormButtons.propTypes = {
    onCancel: PropTypes.func,
};

export default React.memo(FormButtons);
