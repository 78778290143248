export const userUniquinessValidation = values => {
    if (!Array.isArray(values.clientDbUserApplications)) return undefined;
    const errors = {};
    const permitsArray = values.clientDbUserApplications;

    for (let i = 0; i < permitsArray.length; i++) {
        for (let j = 0; j < permitsArray.length; j++) {
            if (i !== j) {
                if (
                    permitsArray[i].userId === permitsArray[j].userId &&
                    permitsArray[i].applicationName === permitsArray[j].applicationName
                ) {
                    if (
                        values.clientDbUserApplications &&
                        values.clientDbUserApplications[i] &&
                        values.clientDbUserApplications[i].userId
                    ) {
                        const lastInserted = i > j ? i : j;
                        errors.clientDbUserApplications = [];

                        // Eerror Array is filled with undefined values
                        for (let k = 0; k < lastInserted; k++)
                            errors.clientDbUserApplications.push(undefined);

                        errors.clientDbUserApplications.splice(lastInserted, 0, {
                            userId: 'Toks įrašas jau egzistuoja',
                        });
                    }

                    return errors;
                }
            }
        }
    }
    return undefined;
};

export const appLicencesValidation = values => {
    if (!Array.isArray(values.clientDbAppLicense)) return undefined;
    const errors = {};
    const permitsArray = values.clientDbAppLicense;

    for (let i = 0; i < permitsArray.length; i++) {
        for (let j = 0; j < permitsArray.length; j++) {
            if (i !== j) {
                if (permitsArray[i].applicationName === permitsArray[j].applicationName) {
                    if (
                        values.clientDbAppLicense &&
                        values.clientDbAppLicense[i] &&
                        values.clientDbAppLicense[i].applicationName
                    ) {
                        const lastInserted = i > j ? i : j;
                        errors.clientDbAppLicense = [];

                        // Eerror Array is filled with undefined values
                        for (let k = 0; k < lastInserted; k++)
                            errors.clientDbAppLicense.push(undefined);

                        errors.clientDbAppLicense.splice(lastInserted, 0, {
                            applicationName: 'Toks įrašas jau egzistuoja',
                        });
                    }

                    return errors;
                }
            }
        }
    }
    return undefined;
};

export const usersQuantityByLicencesValidation = values => {
    if (!Array.isArray(values.clientDbUserApplications)) return undefined;
    const errors = { clientDbUserApplications: [], clientDbAppLicense: [] };
    let showError = false;
    const permitsArray = values.clientDbUserApplications;
    const licenseCount = values.clientDbAppLicense;
    const usedLicenses = {};

    //Dublicates in array have to be counted
    permitsArray.forEach(row => {
        usedLicenses[row.applicationName] = (usedLicenses[row.applicationName] || 0) + 1;
    });

    if (licenseCount.length === 0 && Object.entries(usedLicenses).length !== 0) {
        showError = true;
        errors.clientDbUserApplications.push({ userId: 'Nepakanka licencijų' });
    }

    licenseCount.forEach(app => {
        if (
            usedLicenses[app.applicationName] > 0 &&
            app.licenseCount - usedLicenses[app.applicationName] < 0
        ) {
            showError = true;
            errors.clientDbUserApplications.push({ userId: 'Viršytas licencijų kiekis' });
            errors.clientDbAppLicense.push({ applicationName: 'Viršytas licencijų kiekis' });
        } else {
            errors.clientDbUserApplications.push(undefined);
        }
    });

    return showError ? errors : undefined;
};
