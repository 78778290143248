import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormView } from '../../../components/DataTable';
import { useNotifications } from '../../notifications';
import { createProcedure, updateProcedure, getProcedure } from '../../api/procedures';
import { Grid, makeStyles, Tab, Tabs, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
    FormButtons,
    Loading,
    Autocomplete,
    TextField,
    WhenFieldChanges,
    Checkbox,
} from '../../../components/form';
import { maybeValidate, required } from '../../../utils/validate';
import useQuery from '../../../hooks/useQuery';
import { getClients } from '../../api/clients';
import { getAllDbByClientId } from '../../api/clientdbs';
import ProcedureParamsForm from './ProcedureParamsForm';
import ClientDbsSelect from './ClientDbsSelect';
import { useState } from '../../../hooks';
import { OnChange } from 'react-final-form-listeners';
import { trimWhitespaces } from '../../../components/form/formUtils';
import ReactDiffViewer from 'react-diff-viewer';
import AlertDialog from '../../../components/form/AlertDialog';

const useStyles = makeStyles((theme) => ({
    codeEditor: {
        fontFamily: 'monospace',
    },
    tabContainer: {
        border: '1px solid #e9e9e9',
        padding: theme.spacing(2),
        overflowX: 'auto',
    },
    spinner: {
        marginLeft: theme.spacing(1),
    },
    warningText: {
        color: theme.palette.error.main,
    },
    successText: {
        color: theme.palette.success.main,
    },
}));

const useTabItemStyles = makeStyles({
    root: {
        border: '1px solid #e9e9e9',
        '&:not(:first-of-type)': {
            marginLeft: -1,
        },
        background: '#f7f7f7',
        opacity: 1,
    },
    selected: {
        borderBottomWidth: 0,
        background: '#ffffff',
        '& $wrapper': {
            opacity: 1,
        },
    },
    wrapper: {
        opacity: 0.7,
    },
});

const subscription = {};

const formatDate = (value) => value && new Date(value).toLocaleString('lt-LT');

function ProcedureForm(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const procedureId = values.id; // if we have ID then it's an edit form
    const isSybaseCode = !!values.sybaseCode;
    const isMysqlCode = !!values.mysqlCode;
    const notifications = useNotifications();
    const [tab, setTab] = useState(0);
    const [sqlCodeNow, setSqlCodeNow] = useState(values.mysqlCode);
    const [sybaseCodeNow, setSybaseSqlCodeNow] = useState(values.sybaseCode);
    const classes = useStyles();
    const tabClasses = useTabItemStyles();
    const [isClientDbsLoading, setIsClientDbsLoading] = useState(false);
    const [clientId, setClientId] = useState(values.clientId || '');
    const [clientDbsOptions, setClientDbsOptions] = useState([]);
    const [openDialog, setOpenDialog] = useState({ show: false, data: {}, ignore: false });

    const diffViewerStyle = {
        tableLayout: 'fixed',
        padding: '10px',
    };

    const [procParamsList, setProcParamsList] = useState([]);
    React.useEffect(() => {
        if (!procedureId) {
            return;
        }

        getProcedure(procedureId).then((data) => {
            const procParamsList = (data.procParamsList || []).sort((a, b) => {
                if (+a.paramOrder === +b.paramOrder) {
                    return a.id - b.id;
                }
                return a.paramOrder - b.paramOrder;
            });
            setProcParamsList(procParamsList);
        });
    }, [procedureId]);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            name: values.name || '',
            description: values.description || '',
            version: values.version || '0', // TODO deprecated
            clientId: values.clientId || '',
            clientDbId: values.clientDbId || '',
            createdAt: values.createdAt || '',
            updatedAt: values.updatedAt || '',
            mysqlCode: values.mysqlCode || '',
            sybaseCode: values.sybaseCode || '',
            isActive: values.isActive || (procedureId ? false : true), //Some old procedures have null value
            procParamsList,
        }),
        [values, procParamsList, procedureId],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (formValues) => {
            if (!openDialog.ignore) {
                const findValues = formValues.sybaseCode.search(/varchar\(max\)|varbinary\(max\)/i);

                if (findValues > -1) {
                    setOpenDialog({ show: true, data: formValues, ignore: true });
                    onError();
                    return null;
                }
            }

            const apiCall = procedureId
                ? updateProcedure(procedureId, formValues)
                : createProcedure(formValues);

            return apiCall
                .then((procedure) => {
                    const successMsg = procedureId
                        ? `Procedūra '${procedure.name}' atnaujinta sėkmingai.`
                        : `Procedūra '${procedure.name}' sukurta sėkmingai.`;
                    notifications.showSuccess(successMsg);
                    onSuccess(procedure);
                    onClose();
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, onError, onSuccess, onClose, procedureId, openDialog],
    );

    const { data: clients, isLoading: isClientsLoading } = useQuery(getClients);
    const clientOptions = React.useMemo(
        () =>
            clients && clients.data
                ? clients.data.map((client) => ({
                      label: client.name,
                      value: client.id,
                  }))
                : [],
        [clients],
    );

    React.useEffect(() => {
        if (clientId) {
            setIsClientDbsLoading(true);

            getAllDbByClientId(clientId)
                .then((clientDbs) => {
                    const dbsOptions =
                        clientDbs && clientDbs.data
                            ? clientDbs.data.map((db) => ({
                                  label: db.name,
                                  value: db.id,
                              }))
                            : [];

                    setClientDbsOptions(dbsOptions);
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                })
                .finally(() => setIsClientDbsLoading(false));
        }
    }, [notifications, onError, clientId]);

    const handleAlertClose = React.useCallback(() => {
        setOpenDialog({ ...openDialog, show: false, ignore: true });
        onSubmit(openDialog.data);
    }, [openDialog, onSubmit]);

    const updateSybCode = React.useCallback(() => {
        const data = openDialog.data;
        setOpenDialog({ ...openDialog, show: false, ignore: true });

        const newSybaseCode = data.sybaseCode
            .replace(/varchar\(max\)/gi, 'long varchar')
            .replace(/varbinary\(max\)/gi, 'long varbinary');
        const newValues = { ...data, sybaseCode: newSybaseCode };
        onSubmit(newValues);
    }, [openDialog, onSubmit]);

    const compareCodes = React.useCallback(() => {
        if (!sqlCodeNow && !sybaseCodeNow) return 1;
        const newSqlCode = sqlCodeNow
            .replace(/varchar\(max\)/gi, 'long varchar')
            .replace(/varbinary\(max\)/gi, 'long varbinary');
        if (sybaseCodeNow === sqlCodeNow) return 1;
        if (newSqlCode === sybaseCodeNow) return 2;

        return 3;
    }, [sybaseCodeNow, sqlCodeNow]);

    if (isClientsLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {isClientDbsLoading && <CircularProgress size={20} className={classes.spinner} />}

            {openDialog.show && (
                <AlertDialog
                    title="Kintamieji Sybase kode!"
                    description={
                        <>
                            Sybase kode rastos{' '}
                            <span className={classes.warningText}>varchar(max)</span> ir{' '}
                            <span className={classes.warningText}>varbinary(max)</span> reikšmės. Ar
                            pakeisti atitinkamai į{' '}
                            <span className={classes.successText}>long varchar</span> ir{' '}
                            <span className={classes.successText}>long varbinary</span>?
                        </>
                    }
                    open={openDialog.show}
                    onClose={handleAlertClose}
                    onConfirmation={updateSybCode}
                />
            )}

            <FormView
                title={procedureId ? 'Procedūros redagavimas' : 'Procedūros įvedimas'}
                onClose={onClose}
                fullScreen
                {...formViewProps}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    subscription={subscription}
                    mutators={{ ...arrayMutators }}
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid spacing={2} container>
                                    <Grid
                                        spacing={1}
                                        container
                                        item
                                        xs={12}
                                        sm={8}
                                        alignItems="flex-end"
                                    >
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="name"
                                                label="Pavadinimas"
                                                validate={required}
                                                required
                                                parse={trimWhitespaces}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="description"
                                                label="Aprašymas"
                                                validate={required}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                name="clientId"
                                                label="Klientas"
                                                items={clientOptions}
                                                disabled={!!procedureId}
                                            />

                                            <WhenFieldChanges
                                                field="clientId"
                                                set="clientDbId"
                                                to={''}
                                            />

                                            <OnChange name="clientId">
                                                {(value) => {
                                                    setClientId(value);
                                                }}
                                            </OnChange>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <ClientDbsSelect
                                                clientDbsOptions={clientDbsOptions}
                                                disabled={!!procedureId}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Checkbox name="isActive" label="Aktyvi" />
                                        </Grid>
                                    </Grid>

                                    {procedureId && (
                                        <Grid item xs={12} sm={4}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="updatedAt"
                                                        label="Koreguota"
                                                        format={formatDate}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="createdAt"
                                                        label="Sukurta"
                                                        format={formatDate}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        {compareCodes() === 1 ? (
                                            <Alert severity="success">
                                                SQL ir Sybase kodai sutampa
                                            </Alert>
                                        ) : compareCodes() === 2 ? (
                                            <Alert severity="info">
                                                SQL ir Sybase kodai nesutampa tik dėl varchar ir
                                                varbinary aprašymo
                                            </Alert>
                                        ) : (
                                            <Alert severity="warning">
                                                SQL ir Sybase kodai NEsutampa
                                            </Alert>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} container>
                                        <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                                            <Tab label="SQL kodas" classes={tabClasses} />
                                            <Tab label="SYB kodas" classes={tabClasses} />
                                            <Tab label="Parametrai" classes={tabClasses} />
                                            <Tab
                                                label="SQL ir SYB palyginimas"
                                                classes={tabClasses}
                                            />
                                        </Tabs>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: tab === 0 ? 'block' : 'none' }}
                                        >
                                            <div className={classes.tabContainer}>
                                                <TextField
                                                    name="mysqlCode"
                                                    placeholder="Įveskite SQL kodą"
                                                    InputProps={{
                                                        className: classes.codeEditor,
                                                        disableUnderline: true,
                                                    }}
                                                    validate={maybeValidate(
                                                        required,
                                                        () => procedureId && isMysqlCode,
                                                    )}
                                                    required={!!procedureId}
                                                    rows={25}
                                                    multiline
                                                />

                                                <OnChange name="mysqlCode">
                                                    {(value) => {
                                                        setSqlCodeNow(value);
                                                    }}
                                                </OnChange>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: tab === 1 ? 'block' : 'none' }}
                                        >
                                            <div className={classes.tabContainer}>
                                                <TextField
                                                    name="sybaseCode"
                                                    placeholder="Įveskite SYB kodą"
                                                    InputProps={{
                                                        className: classes.codeEditor,
                                                        disableUnderline: true,
                                                    }}
                                                    validate={maybeValidate(
                                                        required,
                                                        () => procedureId && isSybaseCode,
                                                    )}
                                                    required={!!procedureId}
                                                    rows={25}
                                                    multiline
                                                />

                                                <OnChange name="sybaseCode">
                                                    {(value) => {
                                                        setSybaseSqlCodeNow(value);
                                                    }}
                                                </OnChange>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: tab === 2 ? 'block' : 'none' }}
                                        >
                                            <div className={classes.tabContainer}>
                                                <ProcedureParamsForm fieldName="procParamsList" />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: tab === 3 ? 'block' : 'none' }}
                                    >
                                        <div className={classes.tabContainer}>
                                            {tab === 3 && (
                                                <ReactDiffViewer
                                                    oldValue={sqlCodeNow}
                                                    newValue={sybaseCodeNow}
                                                    splitView={true}
                                                    codeFoldMessageRenderer={(number) =>
                                                        `Išskleisti ${number} eilutes...`
                                                    }
                                                    leftTitle="SQL kodas"
                                                    rightTitle="SYB kodas"
                                                    style={diffViewerStyle}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <FormButtons onCancel={onClose} />
                            </form>
                        );
                    }}
                />
            </FormView>
        </React.Fragment>
    );
}

ProcedureForm.defaultProps = {
    values: {},
};

ProcedureForm.propTypes = {
    values: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        version: PropTypes.string,
        hasParams: PropTypes.bool,
        mysqlCode: PropTypes.string,
        sybaseCode: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        clientId: PropTypes.number,
        clientDbId: PropTypes.number,
        procedureParamsCount: PropTypes.number,
        procParamsList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                procedureId: PropTypes.number,
                type: PropTypes.oneOf(['numeric', 'int', 'datetime', 'text', 'varchar', 'char']),
                paramOrder: PropTypes.number,
                defaultValue: PropTypes.any,
                canPass: PropTypes.bool,
                createdAt: PropTypes.string,
                updatedAt: PropTypes.string,
            }),
        ),
    }),
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default React.memo(ProcedureForm);
