import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch, FormControlLabel } from '@material-ui/core';
import { Field } from 'react-final-form';
import { showError } from './formUtils';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

function Switch(props) {
    const {
        label,
        labelPlacement,
        disabled,
        setError,
        formControlLabelClasses,
        readOnly: readOnlyProp,
        ...fieldProps
    } = props;

    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    return (
        <Field
            type="checkbox"
            render={({ input, meta, ...switchProps }) => {
                const { submitting, error } = meta;
                setError(showError(meta) ? error : null);

                return (
                    <FormControlLabel
                        label={label}
                        labelPlacement={labelPlacement}
                        disabled={submitting || disabled || readOnly}
                        control={<MuiSwitch {...switchProps} {...input} />}
                        classes={formControlLabelClasses}
                    />
                );
            }}
            {...fieldProps}
        />
    );
}

Switch.defaultProps = {
    labelPlacement: 'end',
    setError: () => {},
};

Switch.propTypes = {
    label: PropTypes.string.isRequired,
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    validate: PropTypes.func,
    setError: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    formControlLabelClasses: PropTypes.object,
};

export default React.memo(Switch);
