import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    loadingIndicator: {
        marginRight: 20,
    },
}));

function Loading(props) {
    const classes = useStyles();
    const { text = <div>Kraunama..</div>, className, ...rest } = props;

    return (
        <div className={cn(classes.loadingContainer, className)} {...rest}>
            <CircularProgress className={classes.loadingIndicator} />
            {text}
        </div>
    );
}

Loading.propTypes = {
    text: PropTypes.element,
};

export default Loading;
