import store from 'app/store';
import { logOutSuccess } from 'modules/auth/reducer';
import { deleteTokenExpirationAndRememberDate } from './authToken';

const actionLogout = () => {
    deleteTokenExpirationAndRememberDate();
    store.dispatch(logOutSuccess());
    window.location.href = '/login';
};

export default actionLogout;
