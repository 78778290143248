import React from 'react';
import PropTypes from 'prop-types';
import ActionIcon from './ActionIcon'
import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    removeIcon: {
        color: theme.palette.error.main,
    },
}));


function IconDelete(props) {
    var deleteIcon = <DeleteIcon fontSize="small"/>;
    const classes = useStyles();

    return <ActionIcon className={classes.removeIcon} icon={deleteIcon} {...props}/>;
}

IconDelete.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
};

export default IconDelete;
