import { createAction, createReducer } from '@reduxjs/toolkit';
import { deleteTokenExpirationAndRememberDate, saveTokenExpirationDate } from 'utils/authToken';
import * as AuthApi from '../api/auth';
import moduleName from './selectors';

/**
 * Action types
 */
const LOGIN_SUCCESS = `${moduleName}/LOGIN_SUCCESS`;
const LOGIN_ERROR = `${moduleName}/LOGIN_ERROR`;
const LOGOUT_SUCCESS = `${moduleName}/LOGOUT_SUCCESS`;
const LOGOUT_ERROR = `${moduleName}/LOGOUT_ERROR`;
const EXTEND_SUCCESS = `${moduleName}/EXTEND_SUCCESS`;
const EXTEND_ERROR = `${moduleName}/EXTEND_ERROR`;
const REFRESH_COMPONENT = `${moduleName}/REFRESH_COMPONENT`;

/**
 * Actions
 */
const logInSuccess = createAction(LOGIN_SUCCESS);
const logInError = createAction(LOGIN_ERROR);
const logOutSuccess = createAction(LOGOUT_SUCCESS);
const logOutError = createAction(LOGOUT_ERROR);
const extendSuccess = createAction(EXTEND_SUCCESS);
const extendError = createAction(EXTEND_ERROR);
const refreshComponent = createAction(REFRESH_COMPONENT);

// function getUserRole(authorities) {
//     if (Array.isArray(authorities) && authorities.length) {
//         const hasRole = (role) => authorities.findIndex((e) => e.indexOf(role) > -1) > -1;

//         if (hasRole(ROLE_ADMIN_READONLY)) {
//             return ROLE_ADMIN_READONLY;
//         }
//         if (hasRole(ROLE_ADMIN)) {
//             return ROLE_ADMIN;
//         }
//         if (hasRole(ROLE_DEALER_READONLY)) {
//             return ROLE_DEALER_READONLY;
//         }
//         if (hasRole(ROLE_DEALER)) {
//             return ROLE_DEALER;
//         }
//         if (hasRole(ROLE_CLIENT_READONLY)) {
//             return ROLE_CLIENT_READONLY;
//         }
//         if (hasRole(ROLE_CLIENT)) {
//             return ROLE_CLIENT;
//         }
//     }
//     return ROLE_USER;
// }

export const logIn = (username, password, device = navigator.userAgent) => (dispatch) => {
    return AuthApi.login(username, password, device)
        .then(async (response) => {
            const tokenExpirationDate = response.headers['x-token-expires'];
            saveTokenExpirationDate(
                new Date(tokenExpirationDate).toISOString(),
            );

            const userProfileResponse = await AuthApi.profile();

            dispatch(logInSuccess(userProfileResponse.data));
        })
        .catch((error) => {
            dispatch(logInError(error.message));
            throw error;
        });
};

export const logOut = () => (dispatch) => {
    deleteTokenExpirationAndRememberDate();
    AuthApi.logout()
        .then(() => {
            dispatch(logOutSuccess());
        })
        .catch((err) => {
            console.error(err);
            dispatch(logOutSuccess());
        });
}

export const refreshData = (count) => (dispatch) => {
    dispatch(refreshComponent(count));
};

/**
 * Initial state
 */
const initialState = {
    username: undefined,
    device: undefined,
    timestamp: 0, // indicates time when session was created or renewed,
    error: undefined,
    refresh: 0,
};

/**
 * Reducer
 */
export default createReducer(initialState, {
    [logInSuccess]: (state, action) => ({ ...initialState, ...action.payload }),
    [logInError]: (state, action) => ({ ...initialState, error: action.payload }),
    [logOutSuccess]: () => initialState,
    [logOutError]: (state, action) => ({ ...initialState, error: action.payload }),
    [refreshComponent]: (state, action) => {
        state.refresh = action.payload;
    },
    [extendSuccess]: (state, action) => {
        state.timestamp = action.payload;
        state.loginTime = action.payload;
    },
    [extendError]: (state, action) => {
        state.error = action.payload;
    },
});

export { logOutSuccess }