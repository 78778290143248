import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { useNotifications } from '../../notifications';
import { deleteUser } from 'modules/api/users';
import React from 'react';

const useStyles = makeStyles(theme => ({
    warningText: {
        color: theme.palette.error.main,
    },
}));

const UserDeleteConfirm = (props) => {
    const { values, onError, onSuccess, onClose } = props;
    const notifications = useNotifications();
    const classes = useStyles();

    const handleConfirm = React.useCallback(() => {
        return deleteUser(values.id)
            .then(() => {
                onSuccess(values);
                onClose();
            })
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
                onError(reason);
            }); 
    }, [onClose, onError, onSuccess, values, notifications]);

    const handleCancel = React.useCallback(() => {
       onClose();
    }, [onClose]);

    return <Dialog open fullWidth maxWidth={"xs"} >
        <DialogContent>
            <p>Dėmesio! Trinate vartotoją {values.email}. Šis veiksmas yra negrįžtamas.</p>
            {values.role.includes('ADMIN') && <p className={classes.warningText}>Šis vartotojas yra administratorius. Įsitikinkite, kad kliento paskyra turi bent vieną kitą administratorių. Jeigu trinamas vartotojas yra naudojamas GAMA ir MGAMA integracijoje, prieš trinant, integracijoje nurodykite kito administratoriaus prisijungimus.</p>}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Atšaukti</Button>
            <Button
                onClick={handleConfirm}
                variant="contained"
                disableElevation
            >Ištrinti</Button>
        </DialogActions>
    </Dialog>;
}

export default UserDeleteConfirm;