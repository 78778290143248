import {
    Box, Button, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { Select, TextField } from '../../../components/form';
import useQuery from '../../../hooks/useQuery';
import { naturalNumber, required, validators } from '../../../utils/validate';
import { getApplications } from '../../api/application';
import { deleteClientDbAppLicense } from '../../api/clientDbAppLicense';
import { isAdmin, isDealer, isReadOnly } from '../../auth/selectors';
import { useNotifications } from '../../notifications';

const useStyles = makeStyles(theme => ({
    removeIcon: {
        color: theme.palette.error.main,
    },
}));

function CientDbAppLicencesForm({ fieldName }) {
    const { fields } = useFieldArray(fieldName);
    const isUserReadOnly = useSelector(isReadOnly);
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const classes = useStyles();
    const notifications = useNotifications();

    const { data: apps, error: errorApp } = useQuery(getApplications);
    const appsOptions = React.useMemo(
        () =>
            apps
                ? apps.data.map(app => ({
                    label: app.name,
                    value: app.name,
                }))
                : [],
        [apps],
    );

    const initialAppsOptions = React.useMemo(() => (
        !apps && fields.value && fields.value.length > 0 ?
            fields.value.map(db => ({ label: db.applicationName, value: db.applicationName }))
            : []
    ), [apps, fields])


    if (errorApp) {
        notifications.showError(errorApp.message);
        return;
    }

    const deleteLicense = (index, isNew) => {
        if (isNew) {
            fields.remove(index);
        } else {
            const deleteRecord = fields.value[index];
            const licenseId = deleteRecord ? deleteRecord.id : 0;

            deleteClientDbAppLicense(licenseId)
                .then(() => fields.remove(index))
                .catch(reason => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                });
        }
    };

    const addLicenseButton = (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            onClick={() => fields.push({ isNew: true })}
        >
            Aprašyti licencijų skaičių
        </Button>
    );

    if (!fields.length) {
        return (
            <Box mb={2}>{!isUserReadOnly && (isUserAdmin || isUserDealer) && addLicenseButton}</Box>
        );
    }

    return (
        <React.Fragment>
            <Box mb={2}>{!isUserReadOnly && (isUserAdmin || isUserDealer) && addLicenseButton}</Box>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Aplikacija</TableCell>
                            <TableCell>Planas</TableCell>
                            <TableCell>Maks. licencijų skaičius</TableCell>
                            <TableCell>Panaudota</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((name, index) => {
                            const { isNew } = fields.value[index];

                            let plannedCount = parseInt(fields.value[index].plannedCount);
                            plannedCount = isNaN(plannedCount) ? 0 : plannedCount;

                            let licenseCount = parseInt(fields.value[index].licenseCount);
                            licenseCount = isNaN(licenseCount) ? 0 : licenseCount;

                            let showLicenseWarning = licenseCount < plannedCount;

                            return (
                                <TableRow key={index}>
                                    <TableCell style={{ width: '30%' }}>
                                        <Select
                                            name={`${name}.applicationName`}
                                            items={!apps ? initialAppsOptions : appsOptions}
                                            validate={required}
                                            disabled={!isNew}
                                        />
                                    </TableCell>

                                    <TableCell style={{ width: '20%' }}>
                                        <TextField
                                            name={`${name}.plannedCount`}
                                            validate={validators(naturalNumber, required)}
                                            disabled={!(isUserAdmin || isUserDealer)}
                                        />
                                    </TableCell>

                                    <TableCell style={{ width: '20%' }}>
                                        <TextField
                                            name={`${name}.licenseCount`}
                                            validate={validators(naturalNumber, required)}
                                            disabled={!(isUserAdmin || isUserDealer)}
                                            helperText={showLicenseWarning ? 'Dėmesio: licencijų skaičius mažesnis nei planas' : ''}
                                            helperWarning={showLicenseWarning}
                                        />
                                    </TableCell>

                                    <TableCell style={{ width: '20%' }}>
                                        <TextField name={`${name}.usersCount`} disabled />
                                    </TableCell>

                                    <TableCell style={{ width: '10%' }}>
                                        {(isUserAdmin || isUserDealer) && (
                                            <IconButton
                                                onClick={() => deleteLicense(index, isNew)}
                                                className={classes.removeIcon}
                                            >
                                                <DeleteIcon color="inherit" />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

CientDbAppLicencesForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default React.memo(CientDbAppLicencesForm);
