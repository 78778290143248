import React from 'react';
import PropTypes from 'prop-types';
import { TableBody as MuiTableBody, TableCell, TableRow as MuiTableRow } from '@material-ui/core';
import TableRow from './TableRow';

function TableBody(props) {
    const { columns, rows, prepareRow, isLoading, pageSize, ...other } = props;
    const visibleColumnsCount = React.useMemo(() => columns.filter(col => col.isVisible).length, [
        columns,
    ]);

    return (
        <MuiTableBody {...other}>
            {rows.length > 0 ? (
                rows.map(row => {
                    if (row) {
                        prepareRow(row);
                        return (
                            <TableRow
                                {...row.getRowProps()}
                                row={row}
                                hover={true}
                                selected={row.isSelected}
                                visibleColumnsCount={visibleColumnsCount}
                            />
                        );
                    }

                    return <TableRow visibleColumnsCount={visibleColumnsCount} />;
                })
            ) : (
                <MuiTableRow>
                    <TableCell
                        colSpan={visibleColumnsCount}
                        align="center"
                        style={{ height: pageSize * 39 }}
                    >
                        {isLoading ? 'Vykdoma užklausa..' : 'Įrašų nerasta'}
                    </TableCell>
                </MuiTableRow>
            )}
        </MuiTableBody>
    );
}

TableBody.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    prepareRow: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
};

export default TableBody;
