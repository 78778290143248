import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { FormButtons, TextField } from '../../../components/form';
import { minLength, naturalNumber, required, validators } from '../../../utils/validate';

const subscription = {};

function MessageForm(props) {
    const { initialValues, onSubmit, onClose } = props;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            subscription={subscription}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid spacing={1} container>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="code"
                                    label="Kodas"
                                    validate={validators(required, naturalNumber, minLength(2))} // TODO validator to test existing
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="messageLt"
                                    label="Aprašymas LT"
                                    validate={validators(required, minLength(4))}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="messageEn"
                                    label="Aprašymas EN"
                                    validate={validators(required, minLength(4))}
                                    required
                                />
                            </Grid>
                        </Grid>

                        <FormButtons onCancel={onClose} />
                    </form>
                );
            }}
        />
    );
}

MessageForm.propTypes = {
    initialValues: PropTypes.shape({
        code: PropTypes.string,
        messageLt: PropTypes.string,
        messageEn: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default React.memo(MessageForm);
