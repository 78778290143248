import { client } from './utils';
import {
    defaultQuery,
    getResponseData,
    formatListResponse,
    uri,
    getZippedFile,
} from './utils/helpers';

export const getProcedure = (id) => client.get(uri`/account/procedure/${id}`).then(getResponseData);

export const getProcedures = (query = defaultQuery) =>
    client.post('/account/procedure/query', query).then(formatListResponse);

export const createProcedure = (data) =>
    client.post('/account/procedure', data).then(getResponseData);

export const updateProcedure = (id, data) =>
    client.put(uri`/account/procedure/${id}`, data).then(getResponseData);

export const deleteProcedure = (id) =>
    client.delete(uri`/account/procedure/${id}`).then(getResponseData);

export const exportProcedures = (query = defaultQuery) =>
    client
        .post('/account/procedure/download-sources', query, { responseType: 'arraybuffer' })
        .then(getZippedFile);
