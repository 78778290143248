import React from 'react';

export function usePreviousState(state) {
    const ref = React.useRef();

    React.useEffect(() => {
        ref.current = state;
    });

    return ref.current;
}

export default usePreviousState;
