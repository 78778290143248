import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Slide, useMediaQuery } from '@material-ui/core';

function Notifications(props) {
    const isMobile = useMediaQuery('(max-width:500px)');

    return (
        <SnackbarProvider
            dense={isMobile}
            maxSnack={3}
            TransitionComponent={Transition}
            {...props}
        />
    );
}

function Transition(props) {
    return <Slide {...props} direction="up" />;
}

export default Notifications;
