/**
 * Redux module name which is used as a namespace for action types (action constants) and as a store state slice key
 */
const moduleName = 'auth';

export const ROLE_ADMIN = 'SYSTEM_ADMIN';
export const ROLE_DEALER = 'DEALER_ADMIN';
export const ROLE_CLIENT = 'CLIENT_ADMIN';
export const ROLE_ADMIN_READONLY = 'SYSTEM_ADMIN_READONLY';
export const ROLE_DEALER_READONLY = 'DEALER_ADMIN_READONLY';
export const ROLE_CLIENT_READONLY = 'CLIENT_ADMIN_READONLY';
export const ROLE_USER = 'USER';

/**
 * State (example):
 {
    id: 2,
    login: 'admin',
    role: 'SYSTEM_ADMIN',
    fullName: 'administratorius pavardenis',
    email: 'admin@rivile.lt',
    clientId: 1,
    loginTime: 1572181724516,
    expirationTime: 1572268124
    device: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.120 Safari/537.36',
  }
 */

export const getUser = (state) => {
    return state[moduleName];
}
export const isLoggedIn = (state) => {
    return !!state.auth.userId;
};

export const getUserId = (state) => getUser(state).userId;
// export const getLogin = (state) => getUser(state).login;

export const getRole = (state) => getUser(state).role;
export const isAdmin = (state) => getRole(state) === ROLE_ADMIN;
export const isDealer = (state) => getRole(state) === ROLE_DEALER;
export const isClient = (state) => getRole(state) === ROLE_CLIENT;
export const isDealerReadOnly = (state) => getRole(state) === ROLE_DEALER_READONLY;
export const isClientReadOnly = (state) => getRole(state) === ROLE_CLIENT_READONLY;
export const isSysAdmin = (state) =>
    getRole(state) === ROLE_ADMIN_READONLY || getRole(state) === ROLE_ADMIN;
export const isReadOnly = (state) =>
    !(isAdmin(state) || isDealer(state) || isClient(state) || isUser(state));
export const isUser = (state) => getRole(state) === ROLE_USER;

export const getFullName = (state) => getUser(state).fullName;
export const getEmail = (state) => getUser(state).fullName;
export const getClientId = (state) => getUser(state).clientId;
// export const getLoginTime = (state) => getUser(state).loginTime;
// export const getExpirationTime = (state) => getUser(state).expirationTime;
// export const getDevice = (state) => getUser(state).device;
export const getRefresh = (state) => getUser(state).refresh;
export const isOperator = (state) => getUser(state).isOperator;

export default moduleName;
