import React from 'react';

export function useState(initialState) {
    const didMountRef = React.useRef(false); // holds a flag indicating if a component is mounted
    const [state, setState] = React.useState(initialState);

    const safeSetState = React.useCallback((...args) => {
        // if component is unmounted - do not call setState to avoid memory leaks
        if (!didMountRef.current) {
            return;
        }
        return setState(...args);
    }, []);

    React.useLayoutEffect(() => {
        didMountRef.current = true; // mark component as mounted
        return () => (didMountRef.current = false); // on component unmount set ref flag to false
    }, []);

    return React.useMemo(() => [state, safeSetState], [state, safeSetState]);
}

export default useState;
