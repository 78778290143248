import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ErrorFallback from './ErrorFallback';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        this.props.onError && this.props.onError(error, errorInfo);
    }

    render() {
        const { fallbackComponent: FallbackComponent, children } = this.props;

        if (this.state.error) {
            return <FallbackComponent {...this.state} />;
        }

        return children;
    }
}

ErrorBoundary.defaultProps = {
    fallbackComponent: ErrorFallback,
};

ErrorBoundary.propTypes = {
    fallbackComponent: PropTypes.func.isRequired,
    onError: PropTypes.func,
    children: PropTypes.node,
};

export default ErrorBoundary;
