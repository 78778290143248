import { client } from './utils';
import {
    defaultQuery,
    getResponseData,
    formatListResponse,
    uri,
    formatQuery,
    OP_EQ,
} from './utils/helpers';

export const getClientDbAppLicenses = (query = defaultQuery) =>
    client.post('/account/client-db-app-license/query', query).then(formatListResponse);

export const getDbAppLicence = clientDbId =>
    getClientDbAppLicenses(
        formatQuery({
            filters: [
                {
                    id: 'clientDbId',
                    value: {
                        operation: OP_EQ,
                        value: clientDbId,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const getDbAppLicencesByClient = clientId =>
    getClientDbAppLicenses(
        formatQuery({
            filters: [
                {
                    id: 'clientId',
                    value: {
                        operation: OP_EQ,
                        value: clientId,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const createClientDbAppLicense = data =>
    client.post('/account/client-db-app-license', data).then(getResponseData);

export const updateClientDbAppLicense = (id, data) =>
    client.put(uri`/account/client-db-app-license/${id}`, data).then(getResponseData);

export const deleteClientDbAppLicense = id =>
    client.delete(uri`/account/client-db-app-license/${id}`).then(getResponseData);
