import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '../../../components/form';
import { maybeValidate, required } from '../../../utils/validate';
import Hidden from '../../../components/Hidden';
import { useFormState } from 'react-final-form';
import useQuery from '../../../hooks/useQuery';
import { getActiveDealers } from '../../api/clients';
import { useNotifications } from '../../notifications';

function DealerSelect({ clientId }) {
    const { values } = useFormState();

    const { data: activeDealers, isLoading, error } = useQuery(getActiveDealers);
    const dealerOptions = React.useMemo(() => {
        if (isLoading || error || !activeDealers || !activeDealers.data) {
            return [];
        }

        return activeDealers.data
            .filter(dealer => dealer.id !== clientId)
            .map(dealer => ({
                label: dealer.name,
                value: dealer.id,
            }));
    }, [activeDealers, clientId, isLoading, error]);

    const notifications = useNotifications();
    if (error) {
        notifications.showError(error.message);
    }

    return (
        <Hidden isHidden={values.isDealer}>
            <Autocomplete
                label="Atstovas"
                name="dealerId"
                items={dealerOptions}
                validate={maybeValidate(required, () => !values.isDealer)}
                required
            />
        </Hidden>
    );
}

DealerSelect.propTypes = {
    clientId: PropTypes.number,
};

export default React.memo(DealerSelect);
