import { isEmpty } from '../../../utils/validators';
import {
    ROLE_ADMIN,
    ROLE_CLIENT,
    ROLE_DEALER,
    ROLE_ADMIN_READONLY,
    ROLE_CLIENT_READONLY,
    ROLE_DEALER_READONLY,
    ROLE_USER,
} from '../../auth/selectors';

export function getClientOptions(clients) {
    const clientOptions = clients.map(client => ({
        value: client.id,
        label: client.name,
    }));
    clientOptions.unshift({
        value: '',
        label: 'Pasirinkite..',
    });
    return clientOptions;
}

export const roleOptionsDealer = [
    { value: '', label: 'Pasirinkite..' },
    { value: ROLE_USER, label: ROLE_USER },
    { value: ROLE_CLIENT_READONLY, label: ROLE_CLIENT_READONLY },
    { value: ROLE_CLIENT, label: ROLE_CLIENT },
    { value: ROLE_DEALER_READONLY, label: ROLE_DEALER_READONLY },
    { value: ROLE_DEALER, label: ROLE_DEALER },
];

export const roleOptionsClient = [
    { value: '', label: 'Pasirinkite..' },
    { value: ROLE_USER, label: ROLE_USER },
];

export const roleOptions = [
    ...roleOptionsDealer,
    { value: ROLE_ADMIN_READONLY, label: ROLE_ADMIN_READONLY },
    { value: ROLE_ADMIN, label: ROLE_ADMIN },
];

export function validatePswd(value) {
    const regExp = /^(?=.*[a-zA-Z])(?=.*\d).{12,}$/;
    if (isEmpty(value) || value.match(regExp)) {
        return undefined;
    }
    return 'Slaptažodis turi būti bent 12 simbolių ilgio ir turėti bent vieną raidę ir skaičių';
}

export function formatSubmitValues(values) {
    delete values.showPasswordFields;
    ['oldPassword', 'password', 'repeatPassword'].forEach(passwordField => {
        if (!values[passwordField]) {
            delete values[passwordField];
        }
    });
    return values;
}

export const dbUniquinessValidation = values => {
    if (!Array.isArray(values.clientDbUserApplications)) return undefined;
    const errors = {};
    const permitsArray = values.clientDbUserApplications;

    for (let i = 0; i < permitsArray.length; i++) {
        for (let j = 0; j < permitsArray.length; j++) {
            if (i !== j) {
                if (
                    permitsArray[i].clientDbId === permitsArray[j].clientDbId &&
                    permitsArray[i].applicationName === permitsArray[j].applicationName
                ) {
                    if (
                        values.clientDbUserApplications &&
                        values.clientDbUserApplications[i] &&
                        values.clientDbUserApplications[i].clientDbId
                    ) {
                        const lastInserted = i > j ? i : j;
                        errors.clientDbUserApplications = [];

                        // Eerror Array is filled with undefined values
                        for (let k = 0; k < lastInserted; k++)
                            errors.clientDbUserApplications.push(undefined);

                        errors.clientDbUserApplications.splice(lastInserted, 0, {
                            clientDbId: 'Toks įrašas jau egzistuoja',
                        });
                    }

                    return errors;
                }
            }
        }
    }
    return undefined;
};

export const freeLicencesValidation = values => {
    if (!Array.isArray(values.clientDbUserApplications)) return undefined;
    const errors = { clientDbUserApplications: [] };
    let isError = false;
    const permitsArray = values.clientDbUserApplications;

    for (let i = 0; i < permitsArray.length; i++) {
        if (
            permitsArray[i].isNew &&
            permitsArray[i].clientDbId &&
            permitsArray[i].applicationName &&
            permitsArray[i].licenseCount <= permitsArray[i].usersCount
        ) {
            errors.clientDbUserApplications.push({ clientDbId: 'Nepakanka licencijų' });
            isError = true;
        } else {
            errors.clientDbUserApplications.push(undefined);
        }
    }
    return isError ? errors : undefined;
};
