import { isDevEnv } from '../../utils/env';

const STORE_STORAGE_KEY = 'store';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(STORE_STORAGE_KEY);
        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        if (isDevEnv()) {
            throw err;
        }
        return undefined;
    }
};

const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(STORE_STORAGE_KEY, serializedState);
    } catch (err) {
        if (isDevEnv()) {
            throw err;
        }
    }
};

export { loadState, saveState };
