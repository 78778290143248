import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getTokenExpirationDate } from 'utils/authToken';
import PrivateRoute from '../components/PrivateRoute';
import AuthPage, { ChangePasswordForm, ForgetPasswordForm } from '../modules/auth';
import { isClient, isLoggedIn, isOperator, isSysAdmin, isUser } from '../modules/auth/selectors';
import ClientDbs from '../modules/clientdbs';
import Clients from '../modules/clients';
import ClientsApiKeys from '../modules/clientsApiKeys';
import Dashboard from '../modules/dashboard';
import Page404 from '../modules/layout/Page404';
import Messages from '../modules/messages';
import Procedures from '../modules/procedures';
import ProceduresUpdates from '../modules/proceduresUpdates';
import Users from '../modules/users';
import Profile from '../modules/users/Profile';
import {
    ROUTE_APIKEYS,
    ROUTE_CLIENTS,
    ROUTE_CLIENT_DBS,
    ROUTE_DASHBOARD,
    ROUTE_LOGIN,
    ROUTE_MESSAGES,
    ROUTE_PASSWORD,
    ROUTE_PROCEDURES,
    ROUTE_PROCEDURES_UPDATES,
    ROUTE_PROFILE,
    ROUTE_RESET_PSW,
    ROUTE_USERS,
} from './routes';

function AppRoutes() {
    const loginPath = ROUTE_LOGIN.path;
    const expCookie = getTokenExpirationDate();
    const isUserLoggedIn = useSelector(isLoggedIn) && !!expCookie;
    const isUserAdmin = useSelector(isSysAdmin);
    const isAppUser = useSelector(isUser);
    const isUserClient = useSelector(isClient);
    const isUserOperator = useSelector(isOperator);

    const firstPage = isAppUser ? ROUTE_PROFILE.path : ROUTE_DASHBOARD.path;

    return (
        <Switch>
            <PrivateRoute
                {...ROUTE_LOGIN}
                isAllowed={!isUserLoggedIn}
                redirectTo={firstPage}
                component={AuthPage}
            />
            <PrivateRoute
                {...ROUTE_PASSWORD}
                isAllowed={!isUserLoggedIn}
                redirectTo={firstPage}
                component={ForgetPasswordForm}
            />
            <PrivateRoute
                {...ROUTE_RESET_PSW}
                isAllowed={!isUserLoggedIn}
                redirectTo={firstPage}
                component={ChangePasswordForm}
            />
            <PrivateRoute
                {...ROUTE_DASHBOARD}
                isAllowed={isUserLoggedIn && !isAppUser}
                redirectTo={loginPath}
                component={Dashboard}
            />
            <PrivateRoute
                {...ROUTE_CLIENTS}
                isAllowed={isUserLoggedIn && !isAppUser && !isUserClient && !isUserOperator}
                redirectTo={loginPath}
                component={Clients}
            />
            <PrivateRoute
                {...ROUTE_CLIENT_DBS}
                isAllowed={isUserLoggedIn && !isAppUser && !isUserOperator}
                redirectTo={loginPath}
                component={ClientDbs}
            />
            <PrivateRoute
                {...ROUTE_APIKEYS}
                isAllowed={isUserLoggedIn && (isUserAdmin || isUserOperator)}
                redirectTo={loginPath}
                component={ClientsApiKeys}
            />
            <PrivateRoute
                {...ROUTE_USERS}
                isAllowed={isUserLoggedIn && !isAppUser}
                redirectTo={loginPath}
                component={Users}
            />
            <PrivateRoute
                {...ROUTE_PROFILE}
                isAllowed={isUserLoggedIn}
                redirectTo={loginPath}
                component={Profile}
            />
            <PrivateRoute
                {...ROUTE_MESSAGES}
                isAllowed={isUserLoggedIn && isUserAdmin}
                redirectTo={loginPath}
                component={Messages}
            />
            <PrivateRoute
                {...ROUTE_PROCEDURES}
                isAllowed={isUserLoggedIn && isUserAdmin}
                redirectTo={loginPath}
                component={Procedures}
            />
            <PrivateRoute
                {...ROUTE_PROCEDURES_UPDATES}
                isAllowed={isUserLoggedIn && isUserAdmin}
                redirectTo={loginPath}
                component={ProceduresUpdates}
            />
            <Route component={Page404} />
        </Switch>
    );
}

export default React.memo(AppRoutes);
