import React from 'react';
import PropTypes from 'prop-types';
import { FormView } from '../../../components/DataTable';
import MessageForm from './MessageForm';
import { useNotifications } from '../../notifications';
import { createMessage } from '../../api/messages';

function MessageFormCreate(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const notifications = useNotifications();

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            code: '',
            messageLt: '',
            messageEn: '',
        }),
        [],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        values => {
            return createMessage(values)
                .then(createdMessageData => {
                    notifications.showSuccess(`Pranešimas '${values.code}' sukurtas sėkmingai.`);
                    onSuccess(createdMessageData);
                    onClose();
                })
                .catch(reason => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, onError, onSuccess, onClose],
    );

    return (
        <FormView title="Naujas pranešimas" onClose={onClose} {...formViewProps}>
            <MessageForm initialValues={initialValues} onSubmit={onSubmit} onClose={onClose} />
        </FormView>
    );
}

MessageFormCreate.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default React.memo(MessageFormCreate);
