import { client } from './utils';
import {
    defaultQuery,
    getResponseData,
    formatListResponse,
    uri,
    formatQuery,
    OP_EQ,
} from './utils/helpers';

export const getClientConnection = id =>
    client.get(uri`/account/client-connection/${id}`).then(getResponseData);

export const getClientConnections = (query = defaultQuery) =>
    client.post('/account/client-connection/query', query).then(formatListResponse);

export const getActiveClientConnections = () =>
    getClientConnections(
        formatQuery({
            filters: [
                {
                    id: 'isActive',
                    value: {
                        operation: OP_EQ,
                        value: true,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const createClientConnection = data =>
    client.post('/account/client-connection', data).then(getResponseData);

export const updateClientConnection = (id, data) =>
    client.put(uri`/account/client-connection/${id}`, data).then(getResponseData);

export const deleteClientConnection = id =>
    client.delete(uri`/account/client-connection/${id}`).then(getResponseData);

export const generateConnection = () =>
    client.get('/account/client-connection/generate').then(getResponseData);
