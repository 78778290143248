import React from 'react';
import DataTable, {
    columnTypes,
    CellWithTooltip,
    SelectColumnFilter,
} from '../../../components/DataTable';
import { getNotUpdatedProcedures } from '../../api/proceduresUpdates';

const columns = [
    {
        ...columnTypes.role,
        accessor: 'procedureName',
        Header: 'Pavadinimas',
        isSearchable: true,
        width: '15%',
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        ...columnTypes.date,
        accessor: 'updatedAt',
        Header: 'Paskutinio sinchronizavimo laikas',
        width: '15%',
    },
    {
        accessor: 'clientName',
        Header: 'Klientas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbName',
        Header: 'Duomenų bazė',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbHealthStatus',
        Header: 'DB statusas',
        isSearchable: true,
    },
    {
        accessor: 'errorMessage',
        Header: 'Klaidos pranešimas',
        Filter: SelectColumnFilter,
        isSearchable: true,
        width: '20%',
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} copyOnDblClick />;
        },
    },
];

function ProceduresUpdatesTable() {
    return (
        <DataTable
            title="Neatnaujintų procedūrų sąrašas"
            columns={columns}
            loadData={getNotUpdatedProcedures}
        />
    );
}

export default ProceduresUpdatesTable;
