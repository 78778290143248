import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { TextField, SubmitButton } from '../../../components/form';
import { required } from '../../../utils/validate';
import { NetworkError } from '../../api/utils/errors';
import { logIn, logOutSuccess } from '../reducer';
import useLoginStyles from './useLoginStyles';
import { useState } from '../../../hooks';
import { Redirect } from 'react-router-dom';
import * as routes from '../../../app/routes';
import store from 'app/store';

const initialValues = { email: '', password: '' };
const subscription = { submitError: true, submitting: true };

function LoginForm() {
    const classes = useLoginStyles();
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);

    const onSubmit = React.useCallback(
        (values) => {
            const doLoginRequest = () => dispatch(logIn(values.email, values.password));
            return doLoginRequest().catch((e) => {
                const msg =
                    e instanceof NetworkError
                        ? 'Nenumatyta klaida. Pabandykite dar kartą.'
                        : 'Neteisingi prisijungimo duomenys.';
                return { [FORM_ERROR]: msg };
            });
        },
        [dispatch],
    );

    const handlePasswordClick = () => {
        setRedirect(true);
    };

    useEffect(() => {
        store.dispatch(logOutSuccess());
    }, [])

    return (
        <div>
            {redirect && <Redirect to={routes.ROUTE_PASSWORD.path} />}

            <Form initialValues={initialValues} onSubmit={onSubmit} subscription={subscription}>
                {({ handleSubmit, submitError, submitting }) => (
                    <form className={classes.form} onSubmit={handleSubmit} method="post">
                        {submitError && (
                            <Typography color="error" paragraph>
                                {submitError}
                            </Typography>
                        )}
                        <TextField
                            type="text"
                            name="email"
                            label="El. pašto adresas"
                            validate={required}
                            autoComplete="username"
                            required
                            fullWidth
                            autoFocus
                            readOnly={false}
                        />
                        <TextField
                            type="password"
                            name="password"
                            label="Slaptažodis"
                            validate={required}
                            autoComplete="current-password"
                            margin="normal"
                            required
                            fullWidth
                            readOnly={false}
                        />
                        <SubmitButton className={classes.submit} fullWidth readOnly={false}>
                            {submitting ? 'Jungiamasi...' : 'Prisijungti'}
                        </SubmitButton>
                    </form>
                )}
            </Form>
            <Link onClick={handlePasswordClick} TypographyClasses={{ root: classes.password }}>
                Pamiršote slaptažodį?
            </Link>
        </div>
    );
}

export default LoginForm;
