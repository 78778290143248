import { createAction, createReducer } from '@reduxjs/toolkit';
import moduleName from './selectors';
import { getClients } from '../api/clients';

const FETCH_START = `${moduleName}/FETCH_START`;
const FETCH_SUCCESS = `${moduleName}/FETCH_SUCCESS`;
const FETCH_ERROR = `${moduleName}/FETCH_ERROR`;

const fetchStart = createAction(FETCH_START);
const fetchSuccess = createAction(FETCH_SUCCESS);
const fetchError = createAction(FETCH_ERROR);

export const fetchClients = () => dispatch => {
    dispatch(fetchStart());

    return getClients()
        .then(clients => dispatch(fetchSuccess(clients.data)))
        .catch(e => dispatch(fetchError(e)));
};

/**
 * Initial state
 */
const initialState = {
    items: {},
    order: [],
    isFetching: false,
    error: null,
};

/**
 * Reducer
 */
export default createReducer(initialState, {
    [fetchStart]: state => {
        state.isFetching = true;
        state.error = null;
    },
    [fetchSuccess]: (state, action) => {
        const clientsData = action.payload;
        state.isFetching = false;
        state.items = clientsData.reduce((items, client) => {
            items[client.id] = client;
            return items;
        }, {});
        state.order = clientsData.map(client => client.id);
    },
    [fetchError]: (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
    },
});
