import React from 'react';
import PropTypes from 'prop-types';
import { FormView } from '../../../components/DataTable';
import MessageForm from './MessageForm';
import { useNotifications } from '../../notifications';
import { updateMessage } from '../../api/messages';

function MessageFormEdit(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const messageId = values.id;
    const notifications = useNotifications();

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            code: values.code || '',
            messageLt: values.messageLt || '',
            messageEn: values.messageEn || '',
        }),
        [values],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        values => {
            return updateMessage(messageId, values)
                .then(updatedMessageData => {
                    notifications.showSuccess(`Pranešimas '${values.code}' duomenys atnaujinti.`);
                    onSuccess(updatedMessageData);
                    onClose();
                })
                .catch(reason => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, messageId, onError, onSuccess, onClose],
    );

    return (
        <FormView title="Pranešimo duomenys" onClose={onClose} {...formViewProps}>
            <MessageForm initialValues={initialValues} onSubmit={onSubmit} onClose={onClose} />
        </FormView>
    );
}

MessageFormEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    values: PropTypes.shape({
        code: PropTypes.number,
        messageLt: PropTypes.string,
        messageEn: PropTypes.string,
    }),
};

export default React.memo(MessageFormEdit);
