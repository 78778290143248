import React from 'react';
import PropTypes from 'prop-types';
import IconDone from '@material-ui/icons/Done';
import IconRemove from '@material-ui/icons/Remove';
import { Tooltip } from '@material-ui/core';
import IconMore from '@material-ui/icons/MoreHoriz';

const iconDone = <IconDone fontSize={'small'} />;
const iconRemove = <IconRemove fontSize={'small'} />;
const iconMore = <IconMore fontSize={'small'} />;
const style = { textAlign: 'center' };

const CellBoolean = (props) => {
    const { titleTrue, titleFalse, value, codes } = props;
    const icon = React.useMemo(() => {
        if (value) {
            return iconDone;
        }

        if (!value && codes && codes.mysqlCode) {
            const newMysqlCode = codes.mysqlCode
                .replace(/varchar\(max\)/gi, 'long varchar')
                .replace(/varbinary\(max\)/gi, 'long varbinary');

            if (newMysqlCode === codes.sybaseCode) {
                return iconMore;
            } else {
                return iconRemove;
            }
        }

        return iconRemove;
    }, [codes, value]);

    const title =
        icon === iconMore
            ? 'Nesutampa tik varchar ir varbinary aprašymas'
            : icon === iconDone
            ? titleTrue
            : titleFalse;

    return (
        <div style={style}>
            {titleFalse && titleTrue ? <Tooltip title={title}>{icon}</Tooltip> : icon}
        </div>
    );
};

CellBoolean.propTypes = {
    value: PropTypes.bool.isRequired,
    titleTrue: PropTypes.string,
    titleFalse: PropTypes.string,
};

export default React.memo(CellBoolean);
