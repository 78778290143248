import React from 'react';
import { Button } from '@material-ui/core';
import { useFormState } from 'react-final-form';

function CancelButton(props) {
    const { submitting } = useFormState({
        subscription: { submitting: true },
    });

    return <Button variant="contained" type="button" disabled={submitting} {...props} />;
}

CancelButton.defaultProps = {
    children: 'Uždaryti',
    disableElevation: true,
};

export default React.memo(CancelButton);
