import { CircularProgress, makeStyles, Paper } from "@material-ui/core";
import useLoginStyles from "modules/auth/components/useLoginStyles";
import Logo from "./Logo";
import React from 'react';

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    }
}));

const LoadingPage = () => {
    const classes = useLoginStyles();
    const progressClasses = useStyles();


    return <div className={classes.main}>
        <Paper className={classes.paper}>
            <Logo width="110" />
            <CircularProgress className={progressClasses.progressContainer} />
        </Paper>
    </div>
}

export default LoadingPage;