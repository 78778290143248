import React from 'react';
import PropTypes from 'prop-types';
import { useDidUpdateEffect, useState } from 'hooks';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { OP_NOT_EQ } from '../../../modules/api/utils/helpers';
import Grid from '@material-ui/core/Grid';
import DefaultFilter from './DefaultFilter';

function ZeroesAndNumberFilter(props) {
    const { column } = props;
    const { setFilter } = column;
    const [checked, setChecked] = useState(true);

    useDidUpdateEffect(() => {
        if (checked) {
            setFilter(undefined);
        } else {
            setFilter({
                value: 0,
                operation: OP_NOT_EQ,
            });
        }
    }, [checked]);

    const handleChange = React.useCallback(
        e => {
            const prevState = checked;
            setChecked(!prevState);
        },
        [checked],
    );

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={7}>
                <DefaultFilter column={props.column} operation="=" type="number" />
            </Grid>
            <Grid item xs={5}>
                <FormControlLabel
                    control={
                        <Checkbox name="showZeroes" checked={checked} onChange={handleChange} />
                    }
                    label="Rodyti nulinius kiekius"
                />
            </Grid>
        </Grid>
    );
}

ZeroesAndNumberFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.any,
                operation: PropTypes.string,
            }),
        ]),
        setFilter: PropTypes.func.isRequired,
    }).isRequired,
};

export default ZeroesAndNumberFilter;
