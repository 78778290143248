import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

function DateTimePicker(props) {
    const {
        name,
        validate,
        disabled,
        variant,
        cancelLabel,
        clearLabel,
        okLabel,
        readOnly: readOnlyProp,
        ...rest
    } = props;
    const isDialog = variant === 'dialog';
    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    return (
        <Field
            name={name}
            validate={validate}
            render={({
                meta: { submitting, error, touched },
                input: { onBlur, value, ...inputProps },
            }) => {
                const onChange = date => {
                    Date.parse(date)
                        ? inputProps.onChange(date.toISOString())
                        : inputProps.onChange(null);
                };
                const isDisabled = disabled || submitting;
                return (
                    <MuiDateTimePicker
                        {...inputProps}
                        {...rest}
                        disabled={isDisabled || readOnly}
                        ampm={false}
                        value={value ? new Date(value) : null}
                        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
                        error={error && touched}
                        onChange={onChange}
                        cancelLabel={isDialog ? cancelLabel : undefined}
                        clearLabel={isDialog ? clearLabel : undefined}
                        okLabel={isDialog ? okLabel : undefined}
                    />
                );
            }}
        />
    );
}

DateTimePicker.defaultProps = {
    variant: 'dialog',
    invalidDateMessage: 'Negalimas datos formatas',
    invalidLabel: 'nežinoma',
    maxDateMessage: 'Data neturėtų būti vėlesnė nei maksimali',
    minDateMessage: 'Data neturėtų būti ankstesnė nei minimali',
    cancelLabel: 'Atšaukti',
    clearLabel: 'Išvalyti',
    okLabel: 'Gerai',
    todayLabel: 'Šiandien',
};

DateTimePicker.propTypes = {
    variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
};

export default React.memo(DateTimePicker);
