import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

// The use of React.forwardRef will no longer be required for react-router-dom v6.
// See https://github.com/ReactTraining/react-router/issues/6056
const RouterLinkForwardRef = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
));

export default React.forwardRef((props, ref) => (
    <MuiLink ref={ref} component={RouterLinkForwardRef} {...props} />
));
