import { client } from './utils';
import {
    defaultQuery,
    getResponseData,
    formatListResponse,
    uri,
    formatQuery,
    OP_EQ,
} from './utils/helpers';

export const getClientDb = id => client.get(uri`/account/client-db/${id}`).then(getResponseData);

export const getClientDbs = (query = defaultQuery) =>
    client.post('/account/client-db/query', query).then(formatListResponse);

export const getAllDbByClientId = clientId =>
    getClientDbs(
        formatQuery({
            filters: [
                {
                    id: 'clientId',
                    value: {
                        operation: OP_EQ,
                        value: clientId,
                    },
                },
            ],
            pageSize: Number.MAX_SAFE_INTEGER,
        }),
    );

export const createClientDb = data => client.post('/account/client-db', data).then(getResponseData);

export const updateClientDb = (id, data) =>
    client.put(uri`/account/client-db/${id}`, data).then(getResponseData);

export const deleteClientDb = id =>
    client.delete(uri`/account/client-db/${id}`).then(getResponseData);

export const pingClientDb = id =>
    client.get(uri`/account/client-db/ping/${id}`).then(getResponseData);

export const updateProc = id =>
    client.get(uri`/account/client-db/update-procedures/${id}`).then(getResponseData);
