import React from 'react';
import { Paper } from '@material-ui/core';
import Logo from '../../../components/Logo';
import LoginForm from './LoginForm';
import useLoginStyles from './useLoginStyles';

function AuthView() {
    const classes = useLoginStyles();

    return (
        <div className={classes.main}>
            <Paper className={classes.paper}>
                <Logo width="100" />
                <LoginForm />
            </Paper>
        </div>
    );
}

export default React.memo(AuthView);
