import React, { useState } from 'react';
import { getTelemetry } from '../../api/telemetry';
import DataTable, {
    columnTypes,
    ZeroesAndNumberFilter,
    CellWithTooltip,
} from '../../../components/DataTable';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../auth/selectors';

const columns1 = [
    {
        ...columnTypes.role,
        accessor: 'month',
        Header: 'Mėnuo',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientCode',
        Header: 'Kliento kodas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientName',
        Header: 'Kliento pavadinimas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbName',
        Header: 'Duomenų bazė',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'companyName',
        Header: 'DB įmonė',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'operatorName',
        Header: 'Operatorius',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
];

const columns2 = [
    {
        accessor: 'apiPrefix',
        Header: 'API prefiksas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'apiDescription',
        Header: 'API paskirtis',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'count',
        Header: 'Užklausų kiekis',
        Filter: ZeroesAndNumberFilter,
    },
    {
        accessor: 'opbCount',
        Header: 'OPB Užklausos',
        Filter: ZeroesAndNumberFilter,
    },
];

const columnsAdmin = [
    {
        accessor: 'dealerName',
        Header: 'Atstovas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
];

export default function TelemetryTable() {
    const isUserAdmin = useSelector(isAdmin);
    const [columns, setColumns] = useState([...columns1, ...columns2]);

    React.useEffect(() => {
        if (isUserAdmin) {
            setColumns([...columns1, ...columnsAdmin, ...columns2]);
        }
    }, [isUserAdmin]);

    return <DataTable title="Apžvalga" columns={columns} loadData={getTelemetry} />;
}
