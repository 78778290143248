import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { isReadOnly } from '../../modules/auth/selectors';
import { showError } from './formUtils';

const useStyles = makeStyles((theme) => ({
    helperText: {
        color: '#bb7b05',
        whiteSpace: 'nowrap'
    }
}));

function TextField(props) {
    const {
        disabled,
        helperText: userHelperText,
        helperWarning,
        readOnly: readOnlyProp,
        uppercase,
        ...rest
    } = props;
    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    const classes = useStyles();

    return (
        <Field
            render={({ input, meta, ...textFieldProps }) => {
                const isError = showError(meta);
                const helperText = isError ? meta.error : userHelperText;

                return (
                    <MuiTextField
                        {...textFieldProps}
                        {...input}
                        disabled={meta.submitting || disabled || readOnly}
                        error={isError}
                        helperText={helperText}
                        inputProps={uppercase && { style: { textTransform: 'uppercase' } }}
                        FormHelperTextProps={{
                            className: helperWarning ? classes.helperText : undefined
                        }}
                    />
                );
            }}
            {...rest}
        />
    );
}

TextField.defaultProps = {
    fullWidth: true,
};

TextField.propTypes = {
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
};

export default React.memo(TextField);
