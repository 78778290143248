import { useQuery as useReactQuery } from 'react-query';
import { isDevEnv } from '../utils/env';
import stringify from 'fast-json-stable-stringify';

export function useQuery(functionTuple, options) {
    const [queryFn] = Array.isArray(functionTuple) ? functionTuple : [functionTuple];
    return useReactQuery(functionTuple, queryFn, {
        ...options,
        queryKeySerializerFn: queryKeySerializer,
        cacheTime: 500,
    });
}

export default useQuery;

// A map to keep track of function pointers
const queryFnMap = new Map();

export function queryKeySerializer(queryKey) {
    if (!queryKey) {
        if (isDevEnv()) {
            throw new Error('Missing queryKey');
        }
        return [];
    }

    let queryFn = queryKey;
    let args;
    if (Array.isArray(queryKey)) {
        [queryFn, args] = queryKey;
    }

    let queryGroupId = queryFnMap.get(queryFn);
    if (!queryGroupId) {
        queryGroupId = Date.now();
        queryFnMap.set(queryFn, queryGroupId);
    }

    const argsHash = stringify(args);
    const queryHash = `${queryGroupId}_${argsHash}`;

    return [queryHash, queryGroupId, argsHash, args];
}
