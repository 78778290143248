import React from 'react';
import { Checkbox } from '@material-ui/core';

const title = 'Pažymėti visas eilutes';
const inputProps = { 'aria-label': title };

function HeaderSelectAll(props) {
    const {
        getToggleAllRowsSelectedProps,
        isAllRowsSelected,
        state: [{ selectedRowIds }],
    } = props;

    const indeterminate = selectedRowIds.length > 0 && !isAllRowsSelected;
    const checkboxProps = React.useMemo(
        () => ({
            title,
            inputProps,
            indeterminate,
        }),
        [indeterminate],
    );
    return <Checkbox {...getToggleAllRowsSelectedProps(checkboxProps)} />;
}

export default HeaderSelectAll;
