import { client } from './utils';
import { defaultQuery, formatListResponse, OP_EQ } from './utils/helpers';

export const getProceduresUpdatesInfo = (query = defaultQuery) =>
    client.post('/account/client-db-procedures/query', query).then(formatListResponse);

export const getNotUpdatedProcedures = (query) => {
    const newQuery = JSON.parse(JSON.stringify(query));

    const extraFilter = {
        junction: 'AND',
        entities: [
            { field: 'isClientdbProcedureUpdated', operation: OP_EQ, value: false },
            { field: 'procedureIsActive', operation: OP_EQ, value: true },
            { field: 'clientDbIsActive', operation: OP_EQ, value: true },
            { field: 'clientIsActive', operation: OP_EQ, value: true },
        ],
    };

    if (newQuery.filter) extraFilter.entities.push({ ...newQuery.filter });

    return getProceduresUpdatesInfo({ ...newQuery, filter: extraFilter });
};
