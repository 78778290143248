import React from 'react';
import DataTable, {
    columnTypes,
    ActionIcon,
    NumberFilter,
    CellWithTooltip,
} from '../../../components/DataTable';
import { getMessages } from '../../api/messages';
import MessageFormCreate from './MessageFormCreate';
import MessageFormEdit from './MessageFormEdit';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../auth/selectors';

const columns = [
    {
        ...columnTypes.role,
        accessor: 'code',
        Header: 'Kodas',
        Filter: NumberFilter,
        width: 150,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'messageLt',
        Header: 'Aprašymas LT',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'messageEn',
        Header: 'Aprašymas EN',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        ...columnTypes.actions,
        Cell: (props) => {
            const { code, messageLt } = props.row.values;
            const title = `Redaguoti "${code} ${messageLt}" duomenis`;
            return <ActionIcon title={title} onClick={(e) => props.openEditView(e, props.row)} />;
        },
    },
];

function MessagesTable() {
    const isUserAdmin = useSelector(isAdmin);

    const actions = React.useMemo(() => {
        if (isUserAdmin) {
            return [
                {
                    icon: AddBoxOutlined,
                    title: 'Sukurti naują pranešimą',
                    onClick: (e, table) => table.openCreateView(),
                },
            ];
        }
    }, [isUserAdmin]);

    return (
        <DataTable
            title="Sistemos pranešimai"
            columns={columns}
            loadData={getMessages}
            createView={MessageFormCreate}
            editView={MessageFormEdit}
            actions={actions}
        />
    );
}

export default MessagesTable;
