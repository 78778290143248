import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import { Box, Button, Grid, Tooltip, IconButton } from '@material-ui/core';
import { required } from '../../../utils/validate';
import { Checkbox, TextField } from '../../../components/form';
import { generateConnection } from '../../api/clientconnections';
import { useState } from '../../../hooks';
import AddIcon from '@material-ui/icons/Add';
import { isReadOnly } from '../../auth/selectors';
import { useSelector } from 'react-redux';
import clipboardCopy from 'clipboard-copy';
import FileCopyIcon from '@material-ui/icons/FileCopy';

function ClientConnectionsForm({ fieldName }) {
    const { fields } = useFieldArray(fieldName);
    const [generating, setIsGenerating] = useState(false);
    const isUserReadOnly = useSelector(isReadOnly);

    const addButton = !isUserReadOnly && (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            onClick={async () => {
                setIsGenerating(true);
                const connection = await generateConnection();
                connection.isActive = true; // active by default
                fields.push(connection);
                setIsGenerating(false);
            }}
            disabled={generating}
        >
            {generating ? 'Kuriama nauja jungtis...' : 'Sukurti naują jungtį'}
        </Button>
    );

    if (!fields.length) {
        return <Box my={2}>{addButton}</Box>;
    }

    return (
        <Box my={2}>
            <Box mb={2}>{addButton}</Box>

            {fields.map((name, index) => {
                const connection = fields.value[index];
                let properties = ``;
                properties += `broker.username=${connection.mqUser}\n`;
                properties += `broker.password=${connection.mqPassword}`;

                return (
                    <Grid spacing={2} alignItems="center" container key={index}>
                        <Grid xs={6} sm={4} item>
                            <TextField
                                name={`${name}.name`}
                                label="Jungtis"
                                validate={required}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={3} item>
                            <Checkbox name={`${name}.isActive`} label="Aktyvi" />
                        </Grid>
                        <Grid xs={6} sm={4} item>
                            <Checkbox name={`${name}.sendEmail`} label="Siųsti el.paštu" />
                        </Grid>
                        <Grid xs={6} sm={1} item>
                            <Tooltip title={'kopijuoti'}>
                                <IconButton onClick={() => clipboardCopy(properties)}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
}

ClientConnectionsForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default React.memo(ClientConnectionsForm);
