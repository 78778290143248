import React from 'react';
import Layout from '../layout';
import ClientsTable from './components/ClientsTable';

function Clients() {
    return (
        <Layout>
            <ClientsTable />
        </Layout>
    );
}

export default Clients;
