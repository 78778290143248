import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { FormView } from '../../../components/DataTable';
import { useSelector } from 'react-redux';
import { isAdmin, isSysAdmin, isDealer } from '../../auth/selectors';
import { Checkbox, FormButtons, TextField } from 'components/form';
import { Form } from 'react-final-form';
import ClientConnectionsForm from './ClientConnectionsForm';
import { emails, required } from '../../../utils/validate';
import { createClient, getClient, updateClient } from '../../api/clients';
import { useNotifications } from '../../notifications';
import arrayMutators from 'final-form-arrays';
import { useState } from '../../../hooks';
import DealerSelect from './DealerSelect';
import MethodsSelect from './MethodsSelect';

const useStyles = makeStyles((theme) => ({
    noLabelControl: {
        marginTop: theme.spacing(1),
    },
}));

const formSubscription = {};

function ClientsForm(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const classes = useStyles();
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserSysAdmin = useSelector(isSysAdmin);
    const clientId = values.id;
    const notifications = useNotifications();
    const [clientConnectionsList, setClientConnectionsList] = useState([]);
    const [defaultMethods, setDefaultMethods] = useState([]);

    React.useEffect(() => {
        if (!clientId) {
            return;
        }

        getClient(clientId).then((data) => {
            setClientConnectionsList(data.clientConnectionsList || []);
            setDefaultMethods(data.defaultMethods || []);
        });
    }, [clientId]);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            name: values.name || '', // company name
            code: values.code || '', // company code
            ksCode: values.ksCode || '', // TODO for now should be equal to 'code'
            email: values.email || '',
            description: values.description || '',
            dealerId: values.dealerId || '', // TODO if dealer creating client, then backend need to create client with dealerId = user.clientId
            isDealer: values.isDealer || false,
            isActive: typeof values.isActive === 'undefined' ? true : values.isActive,
            clientConnectionsList: clientConnectionsList || [],
            defaultMethods: defaultMethods || [],
            isOperator: values.isOperator || false,
        }),
        [values, clientConnectionsList, defaultMethods],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (values) => {
            if (values.isDealer) {
                delete values.dealerId;
            }

            const apiCall = clientId ? updateClient(clientId, values) : createClient(values);
            return apiCall
                .then((clientData) => {
                    const msg = clientId
                        ? `Klientas '${values.name}' atnaujintas.`
                        : `Klientas '${values.name}' sukurtas sėkmingai.`;
                    notifications.showSuccess(msg);
                    onSuccess(clientData);
                    onClose();
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, clientId, onError, onSuccess, onClose],
    );

    return (
        <FormView
            title={clientId ? 'Koreguojamas klientas' : 'Naujas klientas'}
            onClose={onClose}
            {...formViewProps}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={formSubscription}
                mutators={{ ...arrayMutators }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid spacing={1} container>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="name"
                                    label="Pavadinimas"
                                    validate={required}
                                    required
                                    disabled={!(isUserAdmin || isUserDealer)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="code"
                                    label="Įmonės kodas"
                                    validate={required}
                                    required
                                    disabled={!(isUserAdmin || isUserDealer)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField name="description" label="Aprašymas" multiline />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="ksCode"
                                    label="Kliento kodas Rivilėje"
                                    validate={required}
                                    required
                                    disabled={!(isUserAdmin || isUserDealer)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="email"
                                    label="El. paštas (bus siunčiama prisijungimų informacija bei naujienlaiškiai)"
                                    validate={emails}
                                    autoComplete="email"
                                    required
                                />
                            </Grid>

                            {isUserSysAdmin && (
                                <Grid item xs={12} sm={6}>
                                    <Checkbox
                                        name="isDealer"
                                        label="Klientas yra atstovas"
                                        formControlLabelClasses={{
                                            root: classes.noLabelControl,
                                        }}
                                    />
                                </Grid>
                            )}

                            {(isUserSysAdmin || isUserDealer) && (
                                <Grid item xs={12} sm={6}>
                                    <DealerSelect clientId={clientId} />
                                </Grid>
                            )}

                            {isUserSysAdmin && (
                                <Grid item xs={12} sm={6}>
                                    <Checkbox
                                        name="isOperator"
                                        label="Klientas yra operatorius"
                                        formControlLabelClasses={{
                                            root: classes.noLabelControl,
                                        }}
                                    />
                                </Grid>
                            )}

                            {isUserSysAdmin && (
                                <Grid item xs={12} sm={6}>
                                    <MethodsSelect />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                                <Checkbox
                                    name="isActive"
                                    label="Aktyvus"
                                    disabled={!(isUserAdmin || isUserDealer)}
                                    formControlLabelClasses={{
                                        root: classes.noLabelControl,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <ClientConnectionsForm fieldName="clientConnectionsList" />

                        <FormButtons onCancel={onClose} />
                    </form>
                )}
            />
        </FormView>
    );
}

ClientsForm.defaultProps = {
    values: {},
};

ClientsForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    values: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        ksCode: PropTypes.string,
        email: PropTypes.string,
        description: PropTypes.string,
        dealerId: PropTypes.number,
        isDealer: PropTypes.bool,
        isActive: PropTypes.bool,
        clientConnectionsList: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
                connectionKey: PropTypes.string,
                isActive: PropTypes.bool,
                clientId: PropTypes.number,
                mqUser: PropTypes.string,
                mqPassword: PropTypes.string,
                vhost: PropTypes.string,
                exchange: PropTypes.string,
                id: PropTypes.number,
            }),
        ),
    }),
};

export default React.memo(ClientsForm);
