import React from 'react';
import { Button } from '@material-ui/core';
import { useFormState } from 'react-final-form';

function SubmitButton(props) {
    const { pristine, submitting } = useFormState({
        subscription: { pristine: true, submitting: true },
    });

    return (
        <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitting || pristine}
            {...props}
        />
    );
}

SubmitButton.defaultProps = {
    children: 'Išsaugoti',
    disableElevation: true,
};

export default React.memo(SubmitButton);
