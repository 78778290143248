import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    makeStyles,
    IconButton,
    TextField as MuiTextField,
    Button,
    Box,
} from '@material-ui/core';
import { FormView } from '../../../components/DataTable';
import { FormButtons, TextField, DatePicker, AlertDialog } from 'components/form';
import { Form } from 'react-final-form';
import { useNotifications } from '../../notifications';
import { Autorenew, Power, PowerOff } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { useState } from '../../../hooks';
import { activateApiKey, updateApiKey, generateApiKey } from '../../api/apikeys';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../auth/selectors';

const useStyles = makeStyles((theme) => ({
    noLabelControl: {
        marginTop: theme.spacing(1),
    },
    notActivated: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'red',
        },
    },
    activated: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: '#00b373',
        },
    },
}));

const formSubscription = {};

function ClientsApiKeyForm(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const apiKeyId = values.id;
    const classes = useStyles();
    const notifications = useNotifications();
    const [isActive, setIsActive] = useState(values.isActive);
    const [generating, setIsGenerating] = useState(false);
    const [activating, setIsActivating] = useState(false);
    const [regeneratedKey, setRegeneratedKey] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const isUserAdmin = useSelector(isAdmin);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            clientName: values.clientName || '', // company name
            clientCode: values.clientCode || '', // company code
            dealerName: values.dealerName || '',
            clientDbId: values.clientDbId || 0,
            clientDbName: values.clientDbName || '',
            clientDbDescription: values.clientDbDescription || '',
            prefix: values.prefix || '',
            description: values.description || '',
            validToDate: values.validToDate || '',
            isActive: isActive,
            changeApiKey: false,
            sendEmail: false,
            apiKey: values.prefix + '.' + '*'.repeat(30), //this value is required but does not make sense without changeApiKey set to true
            operatorId: values.operatorId,
        }),
        [values, isActive],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (values) => {
            const params = {
                clientDbId: values.clientDbId,
                prefix: values.prefix,
                validToDate: values.validToDate,
                description: values.description,
                changeApiKey: regeneratedKey,
                sendEmail: values.sendEmail,
                apiKey: values.apiKey,
                operatorId: values.operatorId,
            };

            return updateApiKey(apiKeyId, params)
                .then((apiKeyData) => {
                    const msg = `API raktas '${values.prefix}' atnaujintas.`;
                    notifications.showSuccess(msg);

                    onSuccess(apiKeyData);
                    onClose();
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, onError, onSuccess, onClose, apiKeyId, regeneratedKey],
    );

    const handleApiKeyActivation = React.useCallback(async () => {
        setIsActivating(true);

        try {
            const data = await activateApiKey(apiKeyId, !isActive);

            if (data) setIsActive(data.isActive);
            const msg = `API raktas '${data.prefix}' ${
                data.isActive ? 'aktyvuotas' : 'deaktyvuotas'
            }.`;
            notifications.showSuccess(msg);
            onSuccess({ ...values, isActive: data.isActive });
        } catch (e) {
            const msg = e.response ? e.response.data.message : e.message;
            notifications.showError(msg);
        } finally {
            setIsActivating(false);
        }
    }, [isActive, apiKeyId, notifications, onSuccess, values]);

    const addButton = (
        <Button
            size="small"
            variant="contained"
            color="default"
            startIcon={isActive ? <PowerOff /> : <Power />}
            onClick={handleApiKeyActivation}
            disabled={activating}
        >
            {activating ? 'Aktyvuojama...' : isActive ? 'Deaktyvuoti raktą' : 'Aktyvuoti raktą'}
        </Button>
    );

    const regenerateApiKey = async (args, state, utils) => {
        setIsGenerating(true);
        try {
            const regApiKey = await generateApiKey();
            const newApiKey = regApiKey.apiKey.split('.')[1];

            const apiKeyField = state.fields['apiKey'];
            apiKeyField.change(state.formState.values.prefix + '.' + newApiKey);

            setRegeneratedKey(true);
        } catch (e) {
            notifications.showError(e.message);
        } finally {
            setIsGenerating(false);
            setOpenDialog(false);
        }
    };

    const handleAlertClose = () => setOpenDialog(false);

    const handleRegenerate = () => {
        setOpenDialog(true);
    };

    return (
        <FormView
            title={'Koreguojamas API raktas'}
            onClose={onClose}
            maxWidth="md"
            {...formViewProps}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                mutators={{ regenerateApiKey }}
                subscription={formSubscription}
                render={({ handleSubmit, form }) => {
                    if (!window.setFormValue) window.setFormValue = form.mutators.setValue;

                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid spacing={1} container>
                                <Grid item xs={12} sm={6}>
                                    <TextField name="clientCode" label="Įmonės kodas" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="clientName"
                                        label="Įmonės pavadinimas"
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField name="clientDbName" label="DB vardas" disabled />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="clientDbDescription"
                                        label="Web adresas"
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField name="dealerName" label="Atstovas" disabled />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Box mb={2}>{addButton}</Box>
                                </Grid>

                                {regeneratedKey && (
                                    <Grid item xs={12} sm={12}>
                                        <Alert severity="info">
                                            API raktas rodomas tik vieną kartą, uždarius formą jo
                                            pamatyti neįmanoma
                                        </Alert>
                                    </Grid>
                                )}

                                <Grid item container spacing={1} direction="row">
                                    <Grid item xs={12} sm={4}>
                                        <TextField name="apiKey" label="API raktas" disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <DatePicker
                                            name="validToDate"
                                            label="Galioja iki"
                                            format="YYYY.MM.DD"
                                            variant="dialog"
                                            autoOk
                                            clearable
                                            disabled={!isUserAdmin}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            name="description"
                                            label="Paskirtis"
                                            required
                                            disabled={!isUserAdmin}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <MuiTextField
                                            label="Statusas"
                                            disabled
                                            value={
                                                isActive ? 'Patvirtintas' : 'Laukiama patvirtinimo'
                                            }
                                            className={clsx({
                                                [classes.notActivated]: !isActive,
                                                [classes.activated]: isActive,
                                            })}
                                        />
                                    </Grid>

                                    {openDialog && (
                                        <AlertDialog
                                            title="Ar tikrai sugeneruoti Api raktą iš naujo?"
                                            open={openDialog}
                                            onClose={handleAlertClose}
                                            onConfirmation={form.mutators.regenerateApiKey}
                                        />
                                    )}

                                    <Grid item xs={12} sm={1}>
                                        <IconButton
                                            onClick={handleRegenerate}
                                            disabled={generating}
                                        >
                                            <Autorenew color="inherit" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <FormButtons onCancel={onClose} />
                        </form>
                    );
                }}
            />
        </FormView>
    );
}

ClientsApiKeyForm.defaultProps = {
    values: {},
};

ClientsApiKeyForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    values: PropTypes.shape({
        clientName: PropTypes.string,
        clientCode: PropTypes.string,
        dealerName: PropTypes.string,
        clientDbName: PropTypes.string,
        clientDbDescription: PropTypes.string,
        prefix: PropTypes.string,
        description: PropTypes.string,
        valdiToDate: PropTypes.string,
        isActive: PropTypes.bool,
    }),
};

export default React.memo(ClientsApiKeyForm);
