import React from 'react';
import { useSnackbar } from 'notistack';

function useNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // notifications API
    return React.useMemo(
        () => ({
            show: enqueueSnackbar,
            showSuccess: makeVariant(enqueueSnackbar, 'success'),
            showInfo: makeVariant(enqueueSnackbar, 'info'),
            showWarning: makeVariant(enqueueSnackbar, 'warning'),
            showError: makeVariant(enqueueSnackbar, 'error'),

            hide: closeSnackbar,
        }),
        [enqueueSnackbar, closeSnackbar],
    );
}

function makeVariant(enqueueSnackbar, variant) {
    return function(msg, options) {
        enqueueSnackbar(msg, { ...options, variant });
    };
}

export { useNotifications };
export default useNotifications;
