import React from 'react';
import DataTable, {
    CellBoolean,
    columnTypes,
    ActionIcon,
    DeleteIcon,
    CellWithTooltip,
} from '../../../components/DataTable';
import { getUsers } from '../../api/users';
import UserFormCreate from './UserFormCreate';
import UserFormEdit from './UserFormEdit';
import UserDeleteConfirm from './UserDeleteConfirm';
import { fetchClients } from '../../clients/reducer';
import { isAdmin, isDealer, isClient } from '../../auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';

const columns = [
    {
        ...columnTypes.role,
        accessor: 'email',
        Header: 'El. paštas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'login',
        Header: 'Vart. vardas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'firstName',
        Header: 'Vardas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'lastName',
        Header: 'Pavardė',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'role',
        Header: 'Rolė',
        disableFilters: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'userApplications',
        Header: 'Aplikacijos',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientName',
        Header: 'Klientas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        ...columnTypes.bool,
        accessor: 'isActive',
        Header: 'Aktyvus',
        Cell: (props) => (
            <CellBoolean
                value={props.cell.value}
                titleTrue={'Aktyvus vartotojas'}
                titleFalse={'Neaktyvus vartotojas'}
            />
        ),
    },
    {
        ...columnTypes.date,
        accessor: 'lastLogin',
        Header: 'Pask. prisijungimas',
    },
    {
        ...columnTypes.actions,
        Cell: (props) => { 
            const { firstName, lastName } = props.row.values;
            const title = `Redaguoti "${firstName} ${lastName}" duomenis`;
            return [
                <ActionIcon title={title} onClick={(e) => props.openEditView(e, props.row)} />,
                <DeleteIcon title={title} onClick={(e) => props.openDeleteView(e, props.row)} />
            ]
        },
    },
];

function UsersTable() {
    // request clients on mount
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchClients());
    }, [dispatch]);

    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);
    const actions = React.useMemo(() => {
        if (isUserAdmin || isUserDealer || isUserClient) {
            return [
                {
                    icon: AddBoxOutlined,
                    title: 'Sukurti naują vartotoją',
                    onClick: (e, table) => table.openCreateView(),
                },
            ];
        }
    }, [isUserAdmin, isUserDealer, isUserClient]);

    return (
        <DataTable
            title="Sistemos vartotojai"
            columns={columns}
            loadData={getUsers}
            createView={UserFormCreate}
            editView={UserFormEdit}
            deleteView={UserDeleteConfirm}
            actions={actions}
        />
    );
}

export default UsersTable;
