import React from 'react';
import { getClients } from '../../api/clients';
import DataTable, {
    CellBoolean,
    columnTypes,
    NumberFilter,
    ActionIcon,
    CellWithTooltip,
} from '../../../components/DataTable';
import ClientsForm from './ClientsForm';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import { useSelector } from 'react-redux';
import { isAdmin, isDealer } from '../../auth/selectors';

const columns = [
    {
        ...columnTypes.role,
        accessor: 'name',
        Header: 'Klientas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'code',
        Header: 'Įmonės kodas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'email',
        Header: 'El. paštas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'dealersName',
        Header: 'Atstovas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientConnectionsCount',
        Header: 'Jungtys',
        align: 'right',
        Filter: NumberFilter,
        maxWidth: 120,
    },
    {
        accessor: 'clientDbCount',
        Header: 'Įmonės (DB)',
        align: 'right',
        Filter: NumberFilter,
        maxWidth: 150,
    },
    {
        ...columnTypes.bool,
        accessor: 'isActive',
        Header: 'Aktyvus',
        Cell: (props) => (
            <CellBoolean
                value={props.cell.value}
                titleTrue="Aktyvus klientas"
                titleFalse="Neaktyvus klientas"
            />
        ),
    },
    {
        ...columnTypes.actions,
        Cell: (props) => {
            const { name } = props.row.values;
            const title = `Redaguoti "${name}" duomenis`;
            return <ActionIcon title={title} onClick={(e) => props.openEditView(e, props.row)} />;
        },
    },
];

function ClientsTable() {
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const actions = React.useMemo(() => {
        if (isUserAdmin || isUserDealer) {
            return [
                {
                    icon: AddBoxOutlined,
                    title: 'Sukurti naują klientą',
                    onClick: (e, table) => table.openCreateView(),
                },
            ];
        }
    }, [isUserAdmin, isUserDealer]);

    return (
        <DataTable
            title="Klientai"
            columns={columns}
            loadData={getClients}
            createView={ClientsForm}
            editView={ClientsForm}
            actions={actions}
        />
    );
}

export default ClientsTable;
