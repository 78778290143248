import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar as MuiToolbar, Typography, Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Search from './Search';
import { Refresh } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getRefresh } from '../../../modules/auth/selectors';
import { refreshData } from '../../../modules/auth/reducer';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    blocks: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        flex: '0 0 auto',
    },
    spacer: {
        flex: '1 1 20%',
    },
    search: {
        flex: '1 1 auto',
        maxWidth: 400,
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    buttons: {
        justifyContent: 'flex-start',
        padding: '12px 0',
    },
}));

const Toolbar = React.memo(function Toolbar(props) {
    const classes = useToolbarStyles();
    const { title, onSearchAll, table } = props;
    const dispatch = useDispatch();
    const refresh = useSelector(getRefresh);

    const handleRefresh = () => {
        dispatch(refreshData(refresh + 1));
    };

    return (
        <MuiToolbar className={classes.root}>
            <div className={classes.blocks}>
                <div className={classes.top}>
                    <div className={classes.title}>
                        {title && <Typography variant="h6">{title}</Typography>}
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.search}>
                        <Search onSearchAll={onSearchAll} />
                    </div>
                </div>
                <div>
                    <DialogActions className={classes.buttons}>
                        <Button
                            size="small"
                            variant="contained"
                            color="default"
                            startIcon={<Refresh />}
                            onClick={handleRefresh}
                        >
                            Atnaujinti
                        </Button>

                        {table.actions && table.actions.length > 0
                            ? table.actions.map((action, idx) => {
                                  return (
                                      <Button
                                          key={idx}
                                          size="small"
                                          variant="contained"
                                          color="default"
                                          startIcon={
                                              <action.icon color="primary" {...action.iconProps} />
                                          }
                                          onClick={(e) => action.onClick(e, table)}
                                      >
                                          {action.title}
                                      </Button>
                                  );
                              })
                            : null}
                    </DialogActions>
                </div>
            </div>
        </MuiToolbar>
    );
});

Toolbar.propTypes = {
    title: PropTypes.string,
    onSearchAll: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
};

export default Toolbar;
