import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '../../../components/form';
import { useFormState } from 'react-final-form';
import { isDealer, getClientId, ROLE_DEALER, ROLE_DEALER_READONLY } from '../../auth/selectors';
import { required } from '../../../utils/validate';
import { useSelector } from 'react-redux';

function UserClientSelect({ clientsList, ...rest }) {
    const { values } = useFormState();
    const role = values.role;
    const isUserDealer = useSelector(isDealer);
    const userClientId = useSelector(getClientId);
    const clientsOptions =
        isUserDealer && (role === ROLE_DEALER || role === ROLE_DEALER_READONLY)
            ? clientsList.filter(value => value.value === userClientId)
            : clientsList;

    return (
        <Autocomplete
            name="clientId"
            label="Klientas"
            items={clientsOptions}
            validate={required}
            required
            {...rest}
        />
    );
}

UserClientSelect.propTypes = {
    clientsList: PropTypes.array.isRequired,
};

export default React.memo(UserClientSelect);
