import React from 'react';
import Layout from '../layout';
import ProceduresTable from './components/ProceduresTable';

function Procedures() {
    return (
        <Layout>
            <ProceduresTable />
        </Layout>
    );
}

export default Procedures;
