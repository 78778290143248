import React from 'react';
import HeaderSelectAll from './components/HeaderSelectAll';
import CellSelectRow from './components/CellSelectRow';
import CellDate from './components/CellDate';
import CellBoolean from './components/CellBoolean';

const columnTypes = {
    selectRow: {
        id: 'selection',
        Header: HeaderSelectAll,
        Cell: props => <CellSelectRow {...props.row.getToggleRowSelectedProps()} />,
        padding: 'checkbox',
    },
    role: {
        component: 'th',
        scope: 'row',
    },
    actions: {
        id: 'actions',
        Header: 'Veiksmai',
        align: 'right',
        maxWidth: 100,
    },
    date: {
        Cell: props => <CellDate value={props.cell.value} />,
        disableFilters: true,
    },
    bool: {
        Cell: props => <CellBoolean value={props.cell.value} />,
        disableFilters: true,
        align: 'center',
        maxWidth: 150,
    },
};

export { columnTypes };
export default columnTypes;
