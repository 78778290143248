import React from 'react';
import Layout from '../layout';
import { Typography } from '@material-ui/core';
import UserProfileForm from './components/UserProfileForm';

function Profile() {
    return (
        <Layout>
            <Typography variant="h6" gutterBottom>
                Vartotojo profilis
            </Typography>
            <UserProfileForm />
        </Layout>
    );
}

export default Profile;
