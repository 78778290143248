import React from 'react';
import DataTable, {
    columnTypes,
    ActionIcon,
    CellBoolean,
    CellWithTooltip,
    SelectColumnFilter,
} from '../../../components/DataTable';
import { getProcedures, exportProcedures } from '../../api/procedures';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ProcedureForm from './ProcedureForm';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../auth/selectors';

const columns = [
    {
        ...columnTypes.role,
        accessor: 'name',
        Header: 'Pavadinimas',
        width: '25%',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'mysqlCode',
        Header: 'SQL kodas',
        Cell: (props) => <CellBoolean value={!!props.row.original.mysqlCode} />,
        disableSortBy: true,
        maxWidth: 100,
    },
    {
        accessor: 'sybaseCode',
        Header: 'Sybase kodas',
        Cell: (props) => <CellBoolean value={!!props.row.original.sybaseCode} />,
        disableSortBy: true,
        maxWidth: 100,
    },
    {
        Header: 'Ar sutampa Sybase ir SQL kodai',
        Cell: (props) => (
            <CellBoolean
                value={props.row.original.sybaseCode === props.row.original.mysqlCode}
                titleTrue="Sutampa"
                titleFalse="Nesutampa"
                codes={{
                    sybaseCode: props.row.original.sybaseCode,
                    mysqlCode: props.row.original.mysqlCode,
                }}
            />
        ),
        disableSortBy: true,
        maxWidth: 100,
    },
    {
        accessor: 'clientName',
        Header: 'Klientas',
        Filter: SelectColumnFilter,
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'clientDbName',
        Header: 'DB',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        ...columnTypes.date,
        accessor: 'updatedAt',
        Header: 'Koreguota',
    },
    {
        ...columnTypes.bool,
        accessor: 'isActive',
        Header: 'Aktyvi',
        Cell: (props) => (
            <CellBoolean
                value={props.cell.value}
                titleTrue="Aktyvi procedūra"
                titleFalse="Neaktyvi procedūra"
            />
        ),
    },
    {
        ...columnTypes.actions,
        width: '10%',
        Cell: (props) => {
            const { name } = props.row.values;
            const title = `Redaguoti "${name}" procedūrą`;
            return <ActionIcon title={title} onClick={(e) => props.openEditView(e, props.row)} />;
        },
    },
];

function ProceduresTable() {
    const isUserAdmin = useSelector(isAdmin);
    const actions = React.useMemo(() => {
        if (isUserAdmin) {
            return [
                {
                    icon: AddBoxOutlined,
                    title: 'Sukurti naują procedūrą',
                    onClick: (e, table) => table.openCreateView(),
                },
                {
                    icon: GetAppOutlinedIcon,
                    title: 'Eksportuoti procedūras',
                    onClick: (e, table) => table.exportData(),
                },
            ];
        }
    }, [isUserAdmin]);

    return (
        <DataTable
            title="Procedūros"
            columns={columns}
            loadData={getProcedures}
            exportData={exportProcedures}
            actions={actions}
            createView={ProcedureForm}
            editView={ProcedureForm}
        />
    );
}

export default ProceduresTable;
