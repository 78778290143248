import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '../../../components/form';
import { useFormState } from 'react-final-form';

function ClientDbsSelect({ clientDbsOptions, ...rest }) {
    const { values } = useFormState();

    return (
        <Autocomplete
            name="clientDbId"
            label="Duomenų bazė"
            items={clientDbsOptions}
            disabled={!!values.id}
            {...rest}
        />
    );
}

ClientDbsSelect.propTypes = {
    clientDbsOptions: PropTypes.array.isRequired,
};

export default React.memo(ClientDbsSelect);
