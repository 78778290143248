import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './rootReducer';
import { isDevEnv } from '../../utils/env';
import { loadState, saveState } from './persistStore';
import throttle from 'lodash.throttle';

const isDev = isDevEnv();

// setup redux middleware
const middleware = getDefaultMiddleware();
if (isDev) {
    const { logger } = require(`redux-logger`);
    middleware.push(logger);
}

// load store state from localStorage
const preloadedState = loadState();

// create configured redux store instance
const store = configureStore({
    reducer,
    middleware,
    preloadedState,
    devTools: isDev,
});

// save store state to the localStorage with 100 ms delay
store.subscribe(
    throttle(() => saveState(store.getState()), 100, {
        leading: false,
        trailing: true,
    }),
);

export default store;
