import React from 'react';
import { getClientDbs } from '../../api/clientdbs';
import DataTable, {
    CellBoolean,
    columnTypes,
    ActionIcon,
    CellWithTooltip,
} from '../../../components/DataTable';
import ClientDbsForm from './ClientDbsForm';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import { useSelector } from 'react-redux';
import { isReadOnly, isAdmin } from '../../auth/selectors';
import { useState } from '../../../hooks';

const columnsTable = [
    {
        ...columnTypes.role,
        accessor: 'clientName',
        Header: 'Klientas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'name',
        Header: 'DB vardas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'companyName',
        Header: 'DB įmonė',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'description',
        Header: 'Web adresas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'type',
        Header: 'SQL tipas',
        width: '7%',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'apiKeys',
        Header: `API prefix'ai`,
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'gatewayVersion',
        Header: `Gateway versija`,
        isSearchable: true,
        width: '7%',
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
    {
        accessor: 'licenseCountMscan',
        Header: `MSCAN licencijos`,
        width: '7%',
        disableFilters: true,
        Cell: (props) => {
            return (
                <CellWithTooltip
                    value={`${props.cell.row.original.usersCountMscan}/${props.cell.row.original.licenseCountMscan}`}
                />
            );
        },
    },
    {
        accessor: 'licenseCountMgama',
        Header: `MGAMA licencijos`,
        width: '7%',
        disableFilters: true,
        Cell: (props) => {
            return (
                <CellWithTooltip
                    value={`${props.cell.row.original.usersCountMgama}/${props.cell.row.original.licenseCountMgama}`}
                />
            );
        },
    },
    {
        ...columnTypes.bool,
        accessor: 'isActive',
        Header: 'Aktyvus',
        width: '5%',
        Cell: (props) => (
            <CellBoolean
                value={props.cell.value}
                titleTrue={'Aktyvi DB'}
                titleFalse={'Neaktyvi DB'}
            />
        ),
    },
    {
        ...columnTypes.bool,
        accessor: 'isSpUpdated',
        Header: 'SP statusas',
        width: '5%',
        Cell: (props) => (
            <CellBoolean
                value={props.cell.value}
                titleTrue={'Visos SP užkeltos'}
                titleFalse={'Ne visos SP užkeltos'}
            />
        ),
    },
];

const columnsAdmin = [
    {
        accessor: 'healthStatus',
        Header: 'DB statusas',
        isSearchable: true,
        Cell: (props) => {
            return <CellWithTooltip value={props.cell.value} />;
        },
    },
];

const columnsAction = [
    {
        ...columnTypes.actions,
        width: '10%',
        Cell: (props) => {
            return (
                <ActionIcon title={`Redaguoti`} onClick={(e) => props.openEditView(e, props.row)} />
            );
        },
    },
];

function ClientDbsTable() {
    const isUserReadonly = useSelector(isReadOnly);
    const isUserAdmin = useSelector(isAdmin);
    const [columns, setColumns] = useState([...columnsTable, ...columnsAction]);

    const actions = React.useMemo(() => {
        if (!isUserReadonly) {
            return [
                {
                    icon: AddBoxOutlined,
                    title: 'Sukurti naują DB',
                    onClick: (e, table) => table.openCreateView(),
                },
            ];
        }
    }, [isUserReadonly]);

    React.useEffect(() => {
        if (isUserAdmin) {
            setColumns([...columnsTable, ...columnsAdmin, ...columnsAction]);
        }
    }, [isUserAdmin]);

    return (
        <DataTable
            title="Įmonės (Duomenų bazės)"
            columns={columns}
            loadData={getClientDbs}
            createView={ClientDbsForm}
            editView={ClientDbsForm}
            actions={actions}
        />
    );
}

export default ClientDbsTable;
