export const ERROR_NAME_RESPONSE_STATUS = 'ResponseStatusError';
export const ERROR_NAME_REQUEST_TIMEOUT = 'RequestTimeoutError';
export const ERROR_NAME_NETWORK = 'NetworkError';

/**
 * Error class for a response outside the 2xx range
 */
export class ResponseStatusError extends Error {
    constructor(msg) {
        super(msg);
        this.name = ERROR_NAME_RESPONSE_STATUS;
    }
}

export class RequestTimeoutError extends Error {
    constructor(msg) {
        super(msg);
        this.name = ERROR_NAME_REQUEST_TIMEOUT;
    }
}

/**
 * Error class for a network error (i.e. when device is offline or permissions issues)
 */
export class NetworkError extends Error {
    constructor(msg) {
        super(msg);
        this.name = ERROR_NAME_NETWORK;
    }
}
