import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormView = (props) => {
    const { isOpen, onClose, children, title, maxWidth, fullScreen = false, ...restProps } = props;
    const formProps = { onClose, ...restProps };
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullScreen={fullScreen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{React.cloneElement(children, formProps)}</DialogContent>
        </Dialog>
    );
};

FormView.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
};

export default React.memo(FormView);
