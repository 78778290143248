import React from 'react';
import useState from './useState';

export function useShallowState(initialState) {
    const [state, setState] = useState(initialState);
    if (typeof state !== 'object') {
        throw new Error('State must be an object');
    }

    const shallowMergeSetState = React.useCallback(
        newState => {
            const newStateData = typeof newState === 'function' ? newState(state) : newState;
            return setState({ ...state, ...newStateData });
        },
        [state, setState],
    );

    return React.useMemo(() => [state, shallowMergeSetState], [state, shallowMergeSetState]);
}

export default useShallowState;
