import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    input: {
        borderRadius: 3,
        border: '1px solid #ced4da',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        width: 'auto',
        padding: '4px 8px !important',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    inputRoot: {
        padding: '0px !important',
    },
}));

export function SelectColumnFilter({ column: { setFilter } }) {
    const classes = useStyles();

    const options = React.useMemo(
        () => [
            { id: 'visi', value: '(netuščia)' },
            { id: 'tusti', value: '(tuščia)' },
        ],
        [],
    );

    //workaround - material-ui v4 Autocomplete doesn't search as you type
    //you must to hit enter
    //workaround - two onChange functions - form TextField and for autocomplete
    const handleChange = (e, value) => {
        if (value && value.id && value.id === 'tusti') {
            setFilter({ operation: '=', value: null });
        } else if (value && value.id && value.id === 'visi') {
            setFilter({ operation: '!=', value: null });
        } else if (!value) {
            setFilter(value || undefined);
        }
    };

    const handleChangeTextField = (e) => {
        if (e.target.value !== '(tuščia)' && e.target.value !== '(netuščia)') {
            setFilter(e.target.value || undefined);
        }
    };

    return (
        <Autocomplete
            freeSolo
            autoSelect
            onChange={handleChange}
            options={options}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" onChange={handleChangeTextField} />
            )}
            getOptionLabel={(option) => option.value || option}
            classes={{
                input: classes.input,
                inputRoot: classes.inputRoot,
            }}
        />
    );
}

export default SelectColumnFilter;
