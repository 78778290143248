import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import {
    useShallowState,
    useState,
    useDidUpdateEffect,
    useDebounce,
    useLocalStorage,
    usePreviousState,
} from 'hooks';
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from 'react-table';
import { makeStyles, TablePagination, LinearProgress } from '@material-ui/core';
import Toolbar from './components/Toolbar';
import DefaultFilter from './filters/DefaultFilter';
import { formatQuery } from '../../modules/api/utils/helpers';
import { useSelector } from 'react-redux';
import { getRefresh } from '../../modules/auth/selectors';

const useStyles = makeStyles({
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: 'progress',
        backgroundColor: 'transparent',
    },
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

const debug = process.env.NODE_ENV === 'development';

const DIALOG_CREATE = 1;
const DIALOG_EDIT = 2;
const DIALOG_DELETE = 3;

function DataTableServer(props) {
    // debug && console.log('DataTableServer rendered');

    const {
        getRowID,
        columns: userColumns,
        data: userData,
        loadData: userLoadData,
        exportData: userExportData,
        pageSizeOptions,
        title,
        createView: CreateView,
        editView: EditView,
        deleteView: DeleteView,
        ...otherProps
    } = props;

    // data
    const dataIndexes = React.useRef({});
    const dataCache = React.useRef({});
    const clearCache = React.useCallback(() => {
        dataCache.current = {};
        dataIndexes.current = {};
    }, []);
    const [data, setData] = useState(() =>
        userData.map((item, index) => {
            const id = getRowID(item);
            dataCache.current[id] = item;
            dataIndexes.current[index] = id;
            return item;
        }),
    );
    const [queryExport, setQueryExport] = useState({});
    const [itemCount, setItemCount] = useState(data.length);
    const [isLoading, setIsLoading] = useState(false);
    const isLoadingDebounced = useDebounce(isLoading, 200);
    const [searchAll, setSearchAll] = useState('');
    const refresh = useSelector(getRefresh);
    const prevRefresh = usePreviousState(refresh);

    const [formViewData, setFormViewData] = useShallowState({
        isOpen: false,
        row: null,
        type: DIALOG_CREATE,
    });
    const closeFormView = React.useCallback(() => setFormViewData({ isOpen: false }), [
        setFormViewData,
    ]);

    // Row creation
    const openCreateView = React.useCallback(
        (e) => {
            setFormViewData({ isOpen: true, type: DIALOG_CREATE, row: null });
        },
        [setFormViewData],
    );
    const onCreate = React.useCallback(
        (newValues) => {
            clearCache();
            setData((data) => [...data, newValues]);
        },
        [clearCache, setData],
    );

    // Row editing
    const openEditView = React.useCallback(
        (e, row) => {
            setFormViewData({ isOpen: true, type: DIALOG_EDIT, row });
        },
        [setFormViewData],
    );

    const onEdit = React.useCallback(
        (newValues) =>
            setData((currentData) => {
                const data = [...currentData];
                const id = getRowID(newValues);
                const rowIndex = formViewData.row.index;
                const updatedRow = {
                    ...currentData[rowIndex],
                    ...newValues,
                };
                data[rowIndex] = updatedRow;
                dataCache.current[id] = updatedRow;
                return data;
            }),
        [setData, formViewData, getRowID],
    );

    // Row deleting
    const openDeleteView = React.useCallback(
        (e, row) => {
            setFormViewData({ isOpen: true, type: DIALOG_DELETE, row });
        },
        [setFormViewData],
    );

    const onDelete = React.useCallback(
        (newValues) =>
            setData((currentData) => {
                const data = [...currentData];
                const userIdToRemove = newValues.id;
                const filteredData = data.filter((row) => row.id !== userIdToRemove);
                return filteredData;
            }),
        [setData],
    );

    //Exporting data
    const exportData = React.useCallback(
        (e) => {
            setIsLoading(true);
            // request items load
            userExportData(queryExport)
                .then(() => {
                    setIsLoading(false);
                })
                .catch((reason) => {
                    debug && console.error(reason);
                    setIsLoading(false);
                });
        },
        [userExportData, queryExport],
    );

    const [initPageSize, setInitPageSize] = useLocalStorage('pageSize', pageSizeOptions[0] || 10);

    const tableInstance = useTable(
        {
            initialState: {
                pageIndex: 0,
                pageSize: initPageSize,
            },
            columns: userColumns,
            data,
            pageCount: Math.ceil(itemCount / (pageSizeOptions[0] || 10)),
            openCreateView,
            openEditView,
            openDeleteView,
            exportData,
            ...otherProps,
        },
        useFilters,
        //useGroupBy, // TODO enable when needed and issues with infinite rendering fixed (useAsyncDebounce)
        useSortBy,
        usePagination,
        useRowSelect,
    );
    const {
        state: { sortBy, groupBy, filters, pageIndex, pageSize },
        columns,
        gotoPage,
        setPageSize,
        toggleAllRowsSelected,
        page: rows,
        prepareRow,
        getTableProps,
        headerGroups,
    } = tableInstance;

    // pagination
    const onPageChange = React.useCallback((e, pageIndex) => gotoPage(pageIndex), [gotoPage]);
    const onPageSizeChange = React.useCallback(
        (e) => {
            let pageSize = +e.target.value;
            setPageSize(pageSize);
            setInitPageSize(pageSize);
        },
        [setPageSize, setInitPageSize],
    );

    const loadDataRef = React.useRef();
    const loadData = React.useCallback(
        () => {
            const startIndex = pageIndex * pageSize;
            let stopIndex = startIndex + pageSize - 1;
            if (itemCount && itemCount - 1 < stopIndex) {
                stopIndex = itemCount - 1;
            }

            const cachedData = getCachedData(
                startIndex,
                stopIndex,
                dataCache.current,
                dataIndexes.current,
            );
            if (cachedData && refresh === prevRefresh) {
                setData(cachedData);
                debug &&
                    console.log(
                        `All items for range ${startIndex}:${stopIndex} were loaded. Taking from cache`,
                    );
                return;
            }

            // We use a ref to disregard any outdated requests
            const id = Date.now();
            loadDataRef.current = id;
            setIsLoading(true);

            let searchAllFilter = [];
            if (searchAll) {
                searchAllFilter = columns
                    .filter((col) => col.isVisible && col.isSearchable)
                    .map((column) => ({
                        id: column.id,
                        value: searchAll,
                    }));
            }

            // prepare query in API format
            const query = formatQuery({
                page: pageIndex,
                pageSize,
                searchAll: searchAllFilter,
                filters,
                groupBy,
                sortBy,
            });

            setQueryExport(query);

            // request items load
            userLoadData(query)
                .then(({ data, itemCount }) => {
                    // if this is an outdated request, disregard the results
                    if (loadDataRef.current !== id) {
                        debug && console.log('Outdated request disregarded');
                        return;
                    }

                    if (Array.isArray(data)) {
                        setData(() =>
                            data.map((item, idx) => {
                                let index = startIndex + idx;
                                let id = getRowID(item);
                                dataCache.current[id] = item;
                                dataIndexes.current[index] = id;
                                return item;
                            }),
                        );
                        setItemCount(itemCount);
                    } else {
                        debug && console.error('Received invalid data');
                    }

                    setIsLoading(false);
                })
                .catch((reason) => {
                    debug && console.error(reason);
                    setIsLoading(false);
                });
        }, // eslint-disable-next-line
        [
            pageIndex,
            pageSize,
            itemCount,
            sortBy,
            groupBy,
            filters,
            searchAll,
            userLoadData,
            columns,
            getRowID,
            refresh,
        ],
    );

    // clear data cache if sorting, filters or grouping change
    useDidUpdateEffect(() => {
        clearCache();
        debug && console.log('Clearing data cache because sorting, filters or grouping changed');
    }, [sortBy, filters, searchAll, groupBy]);

    // Fetch new data when sorting, filters, grouping, pageSize, or pageIndex change (detected via fn reference change)
    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const containerRef = React.useRef();
    useDidUpdateEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        toggleAllRowsSelected(false);
    }, [pageIndex]);

    const classes = useStyles();
    return (
        <div ref={containerRef}>
            <Toolbar title={title} onSearchAll={setSearchAll} table={tableInstance} />

            <DataTable
                isLoading={isLoading}
                columns={columns}
                rows={rows}
                pageSize={pageSize}
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
            />

            <TablePagination
                labelRowsPerPage="Eilučių puslapyje:"
                labelDisplayedRows={paginationDisplayedRows}
                rowsPerPageOptions={pageSizeOptions}
                component="div"
                count={itemCount}
                rowsPerPage={pageSize}
                page={pageIndex}
                backIconButtonProps={backIconButtonProps}
                nextIconButtonProps={nextIconButtonProps}
                onChangePage={onPageChange}
                onChangeRowsPerPage={onPageSizeChange}
            />
            {isLoading && <div className={classes.backdrop} title="Kraunama..." />}
            {isLoadingDebounced && <LinearProgress className={classes.progress} variant="query" />}

            {CreateView && formViewData.isOpen && formViewData.type === DIALOG_CREATE ? (
                <CreateView
                    isOpen={true}
                    onClose={closeFormView}
                    onSuccess={onCreate}
                    onError={() => {}}
                />
            ) : null}

            {EditView && formViewData.isOpen && formViewData.type === DIALOG_EDIT ? (
                <EditView
                    isOpen={true}
                    onClose={closeFormView}
                    values={formViewData.row.original}
                    onSuccess={onEdit}
                    onError={() => {}}
                />
            ) : null}

            {DeleteView && formViewData.isOpen && formViewData.type === DIALOG_DELETE ? (
                <DeleteView
                    isOpen={true}
                    onClose={closeFormView}
                    values={formViewData.row.original}
                    onSuccess={onDelete}
                    onError={() => {}}
                />
            ) : null}
        </div>
    );
}

const backIconButtonProps = { 'aria-label': 'Ankstesnis puslapis' };
const nextIconButtonProps = { 'aria-label': 'Kitas puslapis' };

function paginationDisplayedRows({ from, to, count }) {
    return `Rodoma: ${from}-${to} iš ${count}`;
}

function getCachedData(startIndex, stopIndex, cache, indexes) {
    const data = [];
    for (let i = startIndex; i <= stopIndex; i++) {
        const id = indexes[i];
        if (id == null) {
            return null;
        }
        const item = cache[id];
        if (item == null) {
            return null;
        }
        data.push(item);
    }
    return data;
}

DataTableServer.defaultProps = {
    getRowID: (row) => row.id,
    data: [],
    pageSizeOptions: [10, 20, 30, 40, 50],
    defaultColumn: {
        Filter: DefaultFilter,
        width: undefined, // remove default 150px width
    },
    manualPagination: true,
    manualFilters: true,
    manualSortBy: true,
    manualGroupBy: true,
    disableGroupBy: true,
};

DataTableServer.propTypes = {
    createView: PropTypes.elementType,
    editView: PropTypes.elementType,
    getRowID: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            id: PropTypes.string,
            columns: PropTypes.array,
            Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
            Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),

            // column filter props
            disableFilters: PropTypes.bool,
            filter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            Filter: PropTypes.func,

            // column sorting props
            disableSortBy: PropTypes.bool,
            sortDescFirst: PropTypes.bool,
            sortInverted: PropTypes.bool,
            sortType: PropTypes.func,
        }),
    ).isRequired,
    defaultColumn: PropTypes.object,
    data: PropTypes.array.isRequired,
    loadData: PropTypes.func.isRequired,
    removeData: PropTypes.func,
    addData: PropTypes.func,
    title: PropTypes.string,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,

    // table wise filter props
    disableFilters: PropTypes.bool,
    manualFilters: PropTypes.bool,
    filterTypes: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.string])),

    // table wise sorting props
    orderByFn: PropTypes.func,
    sortTypes: PropTypes.object,
    manualSortBy: PropTypes.bool,
    defaultCanSort: PropTypes.bool,
    disableSortBy: PropTypes.bool,
    disableSortRemove: PropTypes.bool,
    disableMultiSort: PropTypes.bool,
    disableMultiRemove: PropTypes.bool,
    autoResetSortBy: PropTypes.bool,
};

//DataTableServer.whyDidYouRender = true;

export default React.memo(DataTableServer);
