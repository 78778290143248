import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isReadOnly } from '../../modules/auth/selectors';
import { useSelector } from 'react-redux';

function AlertDialog(props) {
    const {
        title,
        children,
        description,
        open,
        onClose,
        onConfirmation,
        readOnly: readOnlyProp,
    } = props;
    const isUserReadOnly = useSelector(isReadOnly);
    const readOnly = readOnlyProp === undefined ? isUserReadOnly : readOnlyProp;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={false}
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

            <DialogContent>
                {description && (
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                )}

                {children}
            </DialogContent>

            <DialogActions>
                {!readOnly && (
                    <Button variant="contained" type="button" onClick={onConfirmation}>
                        Patvirtinti
                    </Button>
                )}

                <Button variant="contained" type="button" onClick={onClose} autoFocus>
                    Uždaryti
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.node,
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    onConfirmation: PropTypes.func.isRequired,
};

export default React.memo(AlertDialog);
