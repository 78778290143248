import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Tabs, Tab } from '@material-ui/core';
import { FormView } from '../../../components/DataTable';
import { Checkbox, FormButtons, Loading, Select, TextField } from 'components/form';
import { Form } from 'react-final-form';
import { email, matchesField, minLength, required, validators } from '../../../utils/validate';
import { useSelector } from 'react-redux';
import { isAdmin, isDealer, ROLE_USER } from '../../auth/selectors';
import { getClients, isClientsFetching } from '../../clients/selectors';
import {
    getClientOptions,
    roleOptions,
    roleOptionsDealer,
    validatePswd,
    dbUniquinessValidation as validation,
    freeLicencesValidation,
} from './formUtils';
import { createUser } from '../../api/users';
import { useNotifications } from '../../notifications';
import UserClientSelect from './userClientSelect';
import { useState } from '../../../hooks';
import UserAppForm from './UserAppForm';
import arrayMutators from 'final-form-arrays';

const useStyles = makeStyles((theme) => ({
    noLabelControl: {
        marginTop: theme.spacing(2),
    },
    tabContainer: {
        border: '1px solid #e9e9e9',
        padding: theme.spacing(2),
    },
}));

const useTabItemStyles = makeStyles({
    root: {
        border: '1px solid #e9e9e9',
        '&:not(:first-of-type)': {
            marginLeft: -1,
        },
        background: '#f7f7f7',
        opacity: 1,
    },
    selected: {
        borderBottomWidth: 0,
        background: '#ffffff',
        '& $wrapper': {
            opacity: 1,
        },
    },
    wrapper: {
        opacity: 0.7,
    },
});

const formSubscription = {};

function UserFormCreate(props) {
    const { onSuccess, onError, onClose, ...formViewProps } = props;
    const classes = useStyles();
    const notifications = useNotifications();
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const clients = useSelector(getClients);
    const isLoading = useSelector(isClientsFetching);
    const clientsList = getClientOptions(clients);
    const clientId = 0;
    const tabClasses = useTabItemStyles();

    const [tab, setTab] = useState(0);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            email: '',
            login: '',
            firstName: '',
            lastName: '',
            role: ROLE_USER,
            clientId: '',
            isActive: false,
            password: '',
            repeatPassword: '',
            clientDbUserApplications: [],
        }),
        [],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (values) => {
            return createUser(values)
                .then((createdUserData) => {
                    notifications.showSuccess(`Vartotojas '${values.login}' sukurtas sėkmingai.`);
                    onSuccess(createdUserData);
                    onClose();
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [notifications, onError, onSuccess, onClose],
    );

    // if clients are still being loaded show form loading view
    if (isLoading) {
        return <Loading />;
    }

    return (
        <FormView title="Naujas vartotojas" onClose={onClose} maxWidth="md" {...formViewProps}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={formSubscription}
                validate={validators(validation, freeLicencesValidation)}
                mutators={{ ...arrayMutators }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid spacing={1} container>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="El. paštas"
                                    validate={validators(required, email)}
                                    autoComplete="email"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="login"
                                    label="Vartotojo vardas"
                                    validate={validators(required, minLength(2))}
                                    autoComplete="username"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="Vardas"
                                    validate={validators(required, minLength(2))}
                                    autoComplete="given-name"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Pavardė"
                                    validate={validators(required, minLength(2))}
                                    autoComplete="family-name"
                                    required
                                />
                            </Grid>

                            {(isUserAdmin || isUserDealer) && (
                                <Grid item xs={12}>
                                    <Select
                                        label="Rolė"
                                        name="role"
                                        items={isUserAdmin ? roleOptions : roleOptionsDealer}
                                        validate={required}
                                        required
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                                <UserClientSelect clientsList={clientsList} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Checkbox
                                    name="isActive"
                                    label="Aktyvus vartotojas"
                                    formControlLabelClasses={{ root: classes.noLabelControl }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="password"
                                    type="password"
                                    label="Slaptažodis"
                                    validate={validators(required, minLength(12), validatePswd)}
                                    autoComplete="new-password"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="repeatPassword"
                                    type="password"
                                    label="Pakartokite slaptažodį"
                                    validate={matchesField(
                                        'password',
                                        'Slaptažodis ir jo pakartojimas nesutampa',
                                    )}
                                    autoComplete="new-password"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                                    <Tab label="Darbas su aplikacijomis" classes={tabClasses} />
                                </Tabs>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: tab === 0 ? 'block' : 'none' }}
                                >
                                    <div className={classes.tabContainer}>
                                        <UserAppForm
                                            fieldName="clientDbUserApplications"
                                            clientId={clientId}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <FormButtons onCancel={onClose} />
                    </form>
                )}
            />
        </FormView>
    );
}

UserFormCreate.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default React.memo(UserFormCreate);
