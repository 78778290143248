import { client } from './utils';
import { uri, getResponseData, defaultQuery, formatListResponse } from './utils/helpers';

export const getApiKey = (id) => client.get(uri`/account/apikey/${id}`).then(getResponseData);

export const createApiKey = (data) => client.post('/account/apikey', data).then(getResponseData);

export const updateApiKey = (id, data) =>
    client.put(uri`/account/apikey/${id}`, data).then(getResponseData);

export const deleteApiKey = (id) => client.delete(uri`/account/apikey/${id}`).then(getResponseData);

export const generateApiKey = () => client.get('/account/apikey/generate').then(getResponseData);

export const activateApiKey = (id, isActive) =>
    client.post(uri`/account/apikey/${id}/activate?isActive=${isActive}`).then(getResponseData);

export const getApiKeys = (query = defaultQuery) =>
    client.post('/account/apikey/query', query).then(formatListResponse);
