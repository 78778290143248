import { combineReducers } from 'redux';
import { default as authReducer } from '../../modules/auth/reducer';
import { default as authModuleName } from '../../modules/auth/selectors';
import { default as clientsReducer } from '../../modules/clients/reducer';
import { default as clientsModuleName } from '../../modules/clients/selectors';

// here we could potentially check for redux module name collisions..

export default combineReducers({
    [authModuleName]: authReducer,
    [clientsModuleName]: clientsReducer,
});
