import { client } from './utils';
import { formatListResponse } from './utils/helpers';

// // query = defaultQuery
// export const getMethodList = (query = defaultQuery) => {
//     // const query1 = {
//     //     page: 0,
//     //     pageSize: Number.MAX_SAFE_INTEGER,
//     //     sort: [
//     //         {
//     //             field: 'name',
//     //             direction: 'DESC',
//     //         },
//     //     ],
//     // };
//     client.post('/account/method/query', query).then(formatListResponse);
// };

export const getMethodList = () => {
    const query = {
        page: 0,
        pageSize: Number.MAX_SAFE_INTEGER,
        sort: [
            {
                field: 'name',
                direction: 'ASC',
            },
        ],
    };
    return client.post('/account/method/query', query).then(formatListResponse);
};
