import { Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { Checkbox, FormButtons, Loading, Select, Switch, TextField } from 'components/form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import actionLogout from 'utils/actionLogout';
import { FormView } from '../../../components/DataTable';
import { useState } from '../../../hooks';
import {
    email,
    matchesField,
    maybeValidate,
    minLength,
    required,
    validators,
} from '../../../utils/validate';
import { getUser, updateUser } from '../../api/users';
import { getUserId, isAdmin, isClient, isDealer, isSysAdmin, ROLE_CLIENT } from '../../auth/selectors';
import { getClients, isClientsFetching } from '../../clients/selectors';
import { useNotifications } from '../../notifications';
import {
    formatSubmitValues,
    freeLicencesValidation,
    getClientOptions,
    roleOptions,
    roleOptionsDealer,
    validatePswd,
    dbUniquinessValidation as validation,
} from './formUtils';
import UserAppForm from './UserAppForm';
import UserClientSelect from './userClientSelect';

const useStyles = makeStyles((theme) => ({
    noLabelControl: {
        marginTop: theme.spacing(2),
    },
    tabContainer: {
        border: '1px solid #e9e9e9',
        padding: theme.spacing(2),
    },
}));

const useTabItemStyles = makeStyles({
    root: {
        border: '1px solid #e9e9e9',
        '&:not(:first-of-type)': {
            marginLeft: -1,
        },
        background: '#f7f7f7',
        opacity: 1,
    },
    selected: {
        borderBottomWidth: 0,
        background: '#ffffff',
        '& $wrapper': {
            opacity: 1,
        },
    },
    wrapper: {
        opacity: 0.7,
    },
});

const formSubscription = { values: true };

function UserFormEdit(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const classes = useStyles();
    const userId = values.id;

    const currentUserId = useSelector(getUserId);
    const notifications = useNotifications();
    const isUserAdmin = useSelector(isAdmin);
    const isUserSysAdmin = useSelector(isSysAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);
    const clients = useSelector(getClients);
    const isLoading = useSelector(isClientsFetching);
    const [userData, setUserData] = useState(values);

    const tabClasses = useTabItemStyles();

    const [tab, setTab] = useState(0);

    React.useEffect(() => {
        if (!userId) {
            return;
        }

        getUser(userId).then((data) => {
            const appInfo = data.clientDbUserApplications;
            if (appInfo && Array.isArray(appInfo)) {
                appInfo.forEach((item) => {
                    item.licenseCount = 0;
                    item.usersCount = 0;
                });
            }
            setUserData(data);
        });
    }, [userId]);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            email: values.email || '',
            login: values.login || '',
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            role: values.role || ROLE_CLIENT,
            clientId: values.client ? values.client.id : '',
            isActive: !!values.isActive,
            oldPassword: '',
            password: '',
            repeatPassword: '',
            showPasswordFields: false,
            clientDbUserApplications: userData.clientDbUserApplications || [],
        }),
        [values, userData],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        (values) => {
            //a deep copy is made so that the values of from are not changed
            const formValues = JSON.parse(JSON.stringify(values));
            const userData = formatSubmitValues(formValues);

            //when generating new user 'isNew' flag was added.
            if (userData.clientDbUserApplications.length > 0) {
                userData.clientDbUserApplications.forEach((user) => {
                    user.userId = userId;
                    delete user.isNew;
                    delete user.licenseCount;
                    delete user.usersCount;
                });
            }

            return updateUser(userId, userData)
                .then((updatedUserData) => {
                    notifications.showSuccess(`Vartotojo '${values.login}' duomenys atnaujinti.`);
                    onSuccess(updatedUserData);
                    onClose();
                    if (String(userId) === String(currentUserId) && userData.password) {
                        actionLogout();
                    }
                })
                .catch((reason) => {
                    const msg = (reason.response && reason.response.data.message) || reason.message;
                    notifications.showError(msg);
                    onError(reason);
                });
        },
        [userId, notifications, onSuccess, onClose, currentUserId, onError],
    );

    // if clients are still being loaded show form loading view
    if (isLoading) {
        return <Loading />;
    }

    return (
        <FormView title="Vartotojo duomenys" onClose={onClose} maxWidth="md" {...formViewProps}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={formSubscription}
                validate={validators(validation, freeLicencesValidation)}
                mutators={{ ...arrayMutators }}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid spacing={1} container>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="El. paštas"
                                    validate={validators(required, email)}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="login"
                                    label="Vartotojo vardas"
                                    validate={validators(required, minLength(2))}
                                    disabled
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="Vardas"
                                    validate={validators(required, minLength(2))}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Pavardė"
                                    validate={validators(required, minLength(2))}
                                    required
                                />
                            </Grid>

                            {(isUserSysAdmin || isUserDealer) && (
                                <Grid item xs={12}>
                                    <Select
                                        label="Rolė"
                                        name="role"
                                        items={isUserDealer ? roleOptionsDealer : roleOptions}
                                        validate={required}
                                        required
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                                <UserClientSelect
                                    clientsList={getClientOptions(clients)}
                                    disabled={!(isUserAdmin || isUserDealer || isUserClient)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Checkbox
                                    name="isActive"
                                    label="Aktyvus vartotojas"
                                    formControlLabelClasses={{
                                        root: classes.noLabelControl,
                                    }}
                                />
                            </Grid>

                            {(isUserAdmin || isUserDealer) && (
                                <Grid item xs={12}>
                                    <Switch
                                        name="showPasswordFields"
                                        label="Keisti slaptažodį?"
                                        color="secondary"
                                    />
                                </Grid>
                            )}

                            <Grid
                                xs={12}
                                item
                                style={{
                                    display: values.showPasswordFields ? 'block' : 'none',
                                }}
                            >
                                <Grid spacing={1} container>
                                    {String(userId) === String(currentUserId) && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="oldPassword"
                                                    type="password"
                                                    label="Senas slaptažodis"
                                                    validate={validators(
                                                        maybeValidate(
                                                            required,
                                                            () => values.showPasswordFields,
                                                        ),
                                                    )}
                                                    autoComplete="current-password"
                                                    required={values.showPasswordFields}
                                                    key={values.showPasswordFields ? 1 : 0}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} />
                                        </>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="password"
                                            type="password"
                                            label="Slaptažodis"
                                            validate={validators(
                                                maybeValidate(
                                                    required,
                                                    () => values.showPasswordFields,
                                                ),
                                                minLength(12),
                                                validatePswd,
                                            )}
                                            autoComplete="new-password"
                                            required={values.showPasswordFields}
                                            key={values.showPasswordFields ? 1 : 0}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="repeatPassword"
                                            type="password"
                                            label="Pakartokite slaptažodį"
                                            validate={matchesField(
                                                'password',
                                                'Slaptažodis ir jo pakartojimas nesutampa',
                                            )}
                                            autoComplete="new-password"
                                            required={values.showPasswordFields}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                                    <Tab label="Darbas su aplikacijomis" classes={tabClasses} />
                                </Tabs>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: tab === 0 ? 'block' : 'none' }}
                                >
                                    <div className={classes.tabContainer}>
                                        <UserAppForm fieldName="clientDbUserApplications" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <FormButtons onCancel={onClose} />
                    </form>
                )}
            />
        </FormView>
    );
}

UserFormEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    values: PropTypes.shape({
        email: PropTypes.string,
        login: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        clientId: PropTypes.number,
        role: PropTypes.string,
        isActive: PropTypes.bool,
    }),
};

export default UserFormEdit;
