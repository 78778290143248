import { client } from './utils';
import { defaultQuery, formatListResponse } from './utils/helpers';

export const getTelemetry = (query = defaultQuery) => {
    // const query = {
    //     pageSize: Number.MAX_SAFE_INTEGER,
    //     sort: [
    //         {
    //             field: 'month',
    //             direction: 'DESC',
    //         },
    //     ],
    // };

    return client.post('/telemetry/statistics/monthly-by-api-key', query).then(formatListResponse);
};
