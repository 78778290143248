import React from 'react';
import PropTypes from 'prop-types';

function Hidden(props) {
    const { isHidden, children } = props;
    if (isHidden) {
        return <div style={{ visibility: 'hidden' }}>{children}</div>;
    }

    return children;
}

Hidden.propTypes = {
    isHidden: PropTypes.bool,
    children: PropTypes.node,
};

export default Hidden;
