import PropTypes from 'prop-types';
import React from 'react';
import { isDevEnv } from '../utils/env';

const ErrorFallback = props => {
    const { error, errorInfo } = props;
    if (isDevEnv()) {
        console.error(error); // eslint-disable-line no-console

        return (
            <div>
                <h1>Something went wrong</h1>
                <pre>{errorInfo.componentStack}</pre>
            </div>
        );
    }

    // do not render in production by default
    return null;
};

ErrorFallback.propTypes = {
    error: PropTypes.instanceOf(Error),
    errorInfo: PropTypes.shape({
        componentStack: PropTypes.string.isRequired,
    }),
};

export default ErrorFallback;
