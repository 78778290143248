import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute(props) {
    const { component: Component, isAllowed, redirectTo, ...componentProps } = props;

    return (
        <Route
            render={routeProps =>
                isAllowed ? (
                    <Component {...routeProps} {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType])
        .isRequired,
    isAllowed: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string.isRequired,
};

export default PrivateRoute;
