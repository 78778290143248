import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getIn } from 'final-form';

const WhenFieldChanges = ({ field, set, to, shouldChange }) => {
    const { values } = useFormState();
    const form = useForm();

    return (
        <OnChange name={field}>
            {() => {
                if (shouldChange) {
                    if (shouldChange(getIn(values, field))) {
                        form.change(set, to);
                    }
                } else {
                    form.change(set, to);
                }
            }}
        </OnChange>
    );
};

WhenFieldChanges.propTypes = {
    field: PropTypes.string.isRequired,
    set: PropTypes.string.isRequired,
    to: PropTypes.any.isRequired,
    shouldChange: PropTypes.func,
};

export default React.memo(WhenFieldChanges);
