import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#0b3f30' },
        secondary: { main: '#00b373' },
    },
    typography: { useNextVariants: true },
    overrides: {
        MuiTableCell: {
            paddingNone: {
                padding: '6px 0',
            },
            sizeSmall: {
                padding: '2px 24px 2px 16px',
            },
        },
    },
});

function AppTheme(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </MuiThemeProvider>
    );
}

AppTheme.propTypes = {
    children: PropTypes.node,
};

export default AppTheme;
