import { client } from './utils';
import { defaultQuery, formatListResponse, getResponseData, uri } from './utils/helpers';

export const getUser = (id) => client.get(uri`/account/user/${id}`).then(getResponseData);

export const getUsers = (query = defaultQuery) =>
    client.post('/account/user/query', query).then(formatListResponse);

export const createUser = (data) => client.post('/account/user', data).then(getResponseData);

export const updateUser = (id, data) =>
    client.put(uri`/account/user/${id}`, data).then(getResponseData);

export const deleteUser = (id) =>
    client.delete(uri`/account/user/${id}`).then(getResponseData);

export const forgotPassword = (email, app) =>
    client.post(`/account/user/forgot-password?email=${email}&app=${app}`);

export const resetPassword = (data) =>
    client.post('/account/user/reset-password', data).then(getResponseData);

export const validateResetPasswordToken = async (token) => {
    const result = await client.get('/account/user/validate-reset-token?token=' + token);
    return result?.data?.valid === true;
}