import React from 'react';
import { useDebounce, useDidUpdateEffect, useState } from 'hooks';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

const searchInputProps = {
    startAdornment: (
        <InputAdornment position="start">
            <SearchIcon fontSize="small" />
        </InputAdornment>
    ),
};

const Search = React.memo(function Search(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const searchHandle = React.useCallback(e => setSearchTerm(e.target.value), [setSearchTerm]);
    const debounceSearchTerm = useDebounce(searchTerm, 500);
    useDidUpdateEffect(() => props.onSearchAll(debounceSearchTerm), [debounceSearchTerm]);

    return (
        <TextField
            name="searchAll"
            type="search"
            onChange={searchHandle}
            placeholder="Paieška"
            InputProps={searchInputProps}
            autoComplete="off"
            fullWidth
        />
    );
});

Search.propTypes = {
    onSearchAll: PropTypes.func.isRequired,
};

export default Search;
