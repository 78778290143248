import PropTypes from 'prop-types';
import React from 'react';
// import logoImage from './logo.png';
import logoImage from './logo_admin_zalia-juoda.png';

function Logo(props) {
    return <img src={logoImage} itemProp="logo" {...props} />; // eslint-disable-line jsx-a11y/alt-text
}

Logo.defaultProps = {
    alt: 'Rivilė',
};

Logo.propTypes = {
    alt: PropTypes.string,
};

export default Logo;
