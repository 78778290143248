import React from 'react';
import useState from './useState';

export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    React.useEffect(
        () => {
            // Update debounced value after delay
            const timeoutId = setTimeout(() => setDebouncedValue(value), delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => clearTimeout(timeoutId);
        },
        [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export default useDebounce;
