import { Paper, Typography } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { parse } from 'qs';
import React from 'react';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { SubmitButton, TextField } from '../../../components/form';
import Logo from '../../../components/Logo';
import { useState } from '../../../hooks';
import { email, required, validators } from '../../../utils/validate';
import { forgotPassword } from '../../api/users';
import { NetworkError } from '../../api/utils/errors';
import useLoginStyles from './useLoginStyles';

const initialValues = { email: '' };
const subscription = { submitError: true, submitting: true };

function ForgetPasswordForm() {
    const classes = useLoginStyles();
    const [showMessage, setShowMessage] = useState(false);
    const location = useLocation();
    const appParam = parse(location.search, { ignoreQueryPrefix: true }).app;
    const app = appParam ? appParam : 'admin';
    const [enteredEmail, setEnteredEmail] = useState('');

    const redirectError = location.state?.error;

    const onSubmit = React.useCallback(
        (values) => {
            setEnteredEmail(values.email);
            return forgotPassword(values.email, app)
                .then(() => setShowMessage(true))
                .catch((error) => {
                    const msg =
                        error instanceof NetworkError
                            ? 'Nenumatyta klaida. Pabandykite dar kartą.'
                            : error;
                    return { [FORM_ERROR]: msg };
                });
        },
        [app],
    );

    return (
        <div className={classes.main}>
            <Paper className={classes.paper}>
                <Logo width="110" />
                {showMessage ? (
                    <Typography align="center" className={classes.message} color="primary">
                        {`Laiškas išsiųstas adresu ${enteredEmail}. Paspauskite ant nuorodos esančios laiške.`}
                        <br />
                        <br />
                        {
                            'Jeigu negavote laiško, pasitikrinkite, ar teisingai nurodėte el. pašto adresą.'
                        }
                    </Typography>
                ) : (
                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        subscription={subscription}
                    >
                        {({ handleSubmit, submitError, submitting }) => {
                            let error = null;

                            if (redirectError && !submitting) {
                                error = redirectError;
                            }

                            if (submitError) {
                                error = submitError;
                            }

                            return (
                                <form className={classes.form} onSubmit={handleSubmit} method="post">
                                    {(error) && (
                                        <Typography color="error" paragraph>
                                            {error}
                                        </Typography>
                                    )}
                                    <Typography color="primary" paragraph>
                                        Įveskite savo el. pašto adresą:
                                    </Typography>

                                    <TextField
                                        type="text"
                                        name="email"
                                        label="El. pašto adresas"
                                        validate={validators(required, email)}
                                        autoComplete="username"
                                        required
                                        fullWidth
                                        autoFocus
                                        readOnly={false}
                                    />
                                    <SubmitButton className={classes.submit} fullWidth readOnly={false}>
                                        {submitting ? 'Siunčiama...' : 'Siųsti'}
                                    </SubmitButton>
                                </form>
                            )
                        }}
                    </Form>
                )}
            </Paper>
        </div>
    );
}

export default ForgetPasswordForm;
