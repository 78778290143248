import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow as MuiTableRow } from '@material-ui/core';
import { getCellProps } from '../utils';

function TableRow(props) {
    const { visibleColumnsCount, row, ...other } = props;

    // Render empty row
    if (!row) {
        return (
            <MuiTableRow>
                <TableCell colSpan={visibleColumnsCount} />
            </MuiTableRow>
        );
    }

    return (
        <MuiTableRow {...other}>
            {row.cells.map(cell => {
                const cellProps = cell.getCellProps(getCellProps(cell.column));
                return <TableCell {...cellProps}>{cell.render('Cell')}</TableCell>;
            })}
        </MuiTableRow>
    );
}

TableRow.propTypes = {
    row: PropTypes.object,
    visibleColumnsCount: PropTypes.number.isRequired,
};

export default React.memo(TableRow);
