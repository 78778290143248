import cn from 'classnames';
import styles from './dataTable.module.css';

export function getHeaderProps(column) {
    const {
        width,
        minWidth,
        align,
        padding,
        style: headerStyle,
        className: headerClassName,
        headerProps,
    } = column;
    const className = cn(styles.cellHeader, headerClassName);
    const style = {
        width,
        minWidth,
        ...headerStyle,
    };
    return { className, style, align, padding, ...headerProps };
}

export function getCellProps(column) {
    const { align, padding, style, className: cellClassName, component, scope, cellProps } = column;
    const className = cn(styles.cell, cellClassName);
    return {
        align,
        padding,
        style,
        className,
        component,
        scope,
        ...cellProps,
    };
}
