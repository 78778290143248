import React from 'react';

export function useDidUpdateEffect(effect, deps, effectHook = React.useEffect) {
    const didMountRef = React.useRef(false);

    effectHook(() => {
        if (didMountRef.current) {
            effect();
        } else {
            didMountRef.current = true;
        }
    }, deps);
}

export default useDidUpdateEffect;
