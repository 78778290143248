import React from 'react';
import Layout from '../layout';
import UsersTable from './components/UsersTable';

function Users() {
    return (
        <Layout>
            <UsersTable />
        </Layout>
    );
}

export default Users;
