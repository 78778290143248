import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { queryKeySerializer } from '../hooks/useQuery';
import AppRoutes from './AppRoutes';
import AppTheme from './AppTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/lt';
import dayjs from 'dayjs';
import store from './store';
import Notifications from '../modules/notifications';
import { isDevEnv } from '../utils/env';

// expose API functions to dev environment via window.api
if (isDevEnv()) {
    import('../modules/api').then(module => {
        window.api = module.default;
    });
}

dayjs.locale('lt');

const queryConfig = {
    queryKeySerializerFn: queryKeySerializer,
    staleTime: 500, // avoids refetch on form dialog unmount
    cacheTime: 500,
};

function App() {
    return (
        <React.StrictMode>
            <ReduxProvider store={store}>
                <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                    <AppTheme>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <Notifications>
                                <ReactQueryConfigProvider config={queryConfig}>
                                    <AppRoutes />
                                </ReactQueryConfigProvider>
                            </Notifications>
                        </MuiPickersUtilsProvider>
                    </AppTheme>
                </BrowserRouter>
            </ReduxProvider>
        </React.StrictMode>
    );
}

export default App;
